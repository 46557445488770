import { profileTypes } from '../types';

const initialState = {
  item: {},
  isRequesting: true,
  isError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case courseTypes.GET_COURSE__REQUEST: return {
    //   ...state,
    //   isRequesting: true
    // }
    // case courseTypes.GET_COURSE__SUCCESS: return {
    //   ...state,
    //   isRequesting: false,
    //   item: action.payload.item
    // }
    case profileTypes.GET_PROFILE__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case profileTypes.GET_PROFILE__SUCCESS: return {
      ...state,
      item: action.payload.user,
      isRequesting: false,
    };
    default:
      return state;
  }
};
