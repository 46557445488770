import * as React from 'react';
import { useEffect } from 'react';

const ActualPolling = ({ component, pollingAction, duration }) => {
  useEffect(() => {
    const timer = setInterval(pollingAction, duration);

    pollingAction();

    return () => clearInterval(timer);
  }, []);

  return component;
};

export default (Component) => ({
  enablePolling, pollingAction, duration, ...rest
}) => {
  const component = <Component {...rest} />;

  if (!enablePolling) {
    return component;
  }

  return <ActualPolling duration={duration} pollingAction={pollingAction} component={component} />;
};
