import * as authActions from './auth';
import * as slidesActions from './slides';
import * as runActions from './run';
import * as linksActions from './links';
import * as editorActions from './editor';
import * as statsActions from './stats';
import * as sessionActions from './session';
import * as coursesActions from './courses';
import * as manufacturersActions from './manufacturers';
import * as saveSlidesActions from './saveSlides.js';
import * as profileActions from './profile';
import * as ticketsActions from './tickets';
import { getRootResource, get, post } from '../api';

import { authTypes, linksTypes } from '../types';

const getRoot = () => (dispatch) => {
  dispatch((() => ({ type: authTypes.GET_ROOT_RESOURCE__REQUEST }))());
  if (localStorage.getItem('invite_id') !== null) {
    const _invite = localStorage.getItem('invite_id');
    post(`${process.env.BASE_API_URL}/v1/authenticated-course-invite-claim-requests`, {
      courseInvite: `/v1/authenticated-course-invite-claim-requests/${_invite}`,
    }).then(() => {
      localStorage.removeItem('invite_id');
    }).catch((err) => {
      dispatch((() => ({
        type: linksTypes.GET_INVITE__FAILURE,
      }))());
    });
  }

  get(`${process.env.BASE_API_URL}/v1`).then((data) => {
    dispatch((() => ({
      type: authTypes.GET_ROOT_RESOURCE__SUCCESS,
      payload: {
        resources: ((obj) => data.resourceListings.map((res) => obj[res.type] = `${process.env.BASE_API_URL}${res.resource}`) && {
          ...obj,
          completitions: `${process.env.BASE_API_URL}/v1/course-slide-completions`,
          accesses: `${process.env.BASE_API_URL}/v1/course-accesses`,
        })({}),
      },
    }))());
    sessionStorage.is_anon
      ? dispatch((() => ({ type: authTypes.NO_USER }))())
      : dispatch(getCurrentUser());
  }).catch((e) => {
    dispatch((() => ({
      type: authTypes.GET_ROOT_RESOURCE__FAILURE,
    }))());
  });
};

const getCurrentUser = () => (dispatch, getState) => {
  dispatch((() => ({ type: authTypes.GET_CURRENT_USER__PROCESS }))());
  const currentUserLink = `${getState().meta.resources.users}/${localStorage.getItem('user_id')}`;
  get(currentUserLink).then((user) => {
    if (user.status === 404) {
      dispatch((() => ({ type: authTypes.GET_CURRENT_USER__FAILURE }))());
    } else {
      get(`${process.env.BASE_API_URL}${user.courseAccesses}`).then((_accesses) => {
        get(`${process.env.BASE_API_URL}${user.managedMedicalInstitutions}`).then((_medicalInstitutions) => {
          dispatch((() => ({
            type: authTypes.GET_CURRENT_USER__SUCCESS,
            payload: { user: { ...user, courses: _accesses, managedMedicalInstitutions: _medicalInstitutions } },
          }))());
        });
      });
    }
  });
};
export {
  ticketsActions, slidesActions, statsActions, saveSlidesActions, coursesActions, authActions, runActions, getRoot, sessionActions, editorActions, linksActions, manufacturersActions, profileActions,
};
