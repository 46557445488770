import React from 'react';
import TokenError from './TokenError';

function InviteError() {
  return (
    <TokenError text="Данная ссылка повреждена, либо инвайт уже был использован" />
  );
}

export default InviteError;
