import { slidesTypes, editorTypes } from '../types';

const initialState = {
  item: { type: null },
  modified: {},
  isRequesting: false,
  isRequestingSave: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case slidesTypes.EDIT_SLIDE: return {
      ...state,
      modified: action.payload.slide,
    };
    case slidesTypes.GET_SLIDE__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case slidesTypes.GET_SLIDE__SUCCESS: return {
      ...state,
      isRequesting: false,
      item: { ...action.payload.slide },
    };
    case slidesTypes.CREATE_SLIDE__SUCCESS: return {
      ...state,
      item: { ...action.payload.slide },
    };
    case slidesTypes.SAVE_SLIDE__REQUEST: return {
      ...state,
      isRequestingSave: true,
    };
    case slidesTypes.SAVE_SLIDE__SUCCESS: return {
      ...state,
      isRequestingSave: false,
    };
    case slidesTypes.UPDATE_SLIDE__SUCCESS: return {
      ...state,
      isRequestingSave: false,
    };
    case editorTypes.ADD_NEW: return {
      ...state,
      item: action.payload.slide,
    };
    default: return state;
  }
};
