import { timersTypes } from '../types';

const initialState = {
  total: 0,
  current: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case timersTypes.CALC_TOTAL_TIME: return {
      ...state,
      total: action.payload.total,
    };
    case timersTypes.DROP_TIMER: return {
      ...state,
      total: 0,
      current: 0,
    };
    default: return state;
  }
};
