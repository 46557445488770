import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActions } from '../../actions';
import Profile from './Profile';
import { Loader } from '../../component';

class ProfileContainer extends Component {
    componentDidMount = () => {
      const {
        profileActions: { getProfile },
      } = this.props;
      getProfile();
    };

    render() {
      return this.props.profile.isRequesting ? <Loader /> : <Profile {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});
const mapDispatchToProps = (dispatch) => ({
  profileActions: bindActionCreators(profileActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileContainer);
