import React from 'react';
import { withRouter } from 'react-router';
import OpenMenu from '@material-ui/icons/ChevronRight';
import {
  IconButton, Avatar, Typography, Button,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as Logo } from '../../icons/svg/pharmPlaceLogo.svg';
import './Header.scss';

@withRouter
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserMenuVisible: false,
    };
  }

  toggleUserMenu = (event) => {
    event.stopPropagation();
    this.setState((state) => ({ isUserMenuVisible: !state.isUserMenuVisible }));
  }

  componentDidUpdate = () => {
    // if (this.state.isUserMenuVisible) {
    //   document.body.onclick = this.toggleUserMenu
    // } else {
    //   document.body.onclick = null
    // }
    // TODO: DID THIS ^
  }

  handleUserMenuClick = (event) => event.stopPropagation()

  handleLogoutClick = (event) => {
    event.stopPropagation();
    this.props.onLogoutClick();
  }

  render() {
    const { user, withoutSidebar, history } = this.props;
    const userInitials = user.fullName.split(' ').map((word) => word[0]).join('');

    return (
      <div className="header">
        {
          !withoutSidebar
          && (
          <IconButton
            className="menuButton"
            color="inherit"
            aria-label="Open drawer"
            onClick={() => this.props.onSidebarClick()}
          >
            <div className={`icon ${this.props.isSidebarOpened ? 'rotate' : ''}`}>
              <OpenMenu />
            </div>
          </IconButton>
          )
        }
        <Logo className="logo" />
        <div className="userActions">
          <div className="desktop">
            <IconButton>
              <HelpIcon />
            </IconButton>
            <div className="user">
              <Avatar className="avatar">{ userInitials }</Avatar>
              <div className="menu" />
            </div>
          </div>
          <div className="mobile">
            <IconButton
              aria-haspopup="true"
              onClick={this.toggleUserMenu}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
          <div onClick={this.handleUserMenuClick} className={`userMenu${this.state.isUserMenuVisible ? ' visible' : ''}`}>
            <Typography>{ user.fullName }</Typography>
            <Typography className="email">{ user.email }</Typography>
            <Button onClick={() => { history.push('/profile'); }}>Профиль</Button>
            <Button onClick={this.handleLogoutClick}>Выйти</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
