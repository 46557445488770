import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { coursesActions } from '../actions';

class UserCoursesContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { coursesActions: { getCourseForAnonUser, getCoursesForUser } } = this.props;
    if (sessionStorage.is_anon) {
      getCourseForAnonUser();
    } else {
      getCoursesForUser(this.props.filter);
    }
  }

  render() {
    return this.props.children({ courses: this.props.courses });
  }
}

const mapDispatchToProps = (dispatch) => ({
  coursesActions: bindActionCreators(coursesActions, dispatch),
});
const mapStateToProps = (state) => ({
  courses: state.courses,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCoursesContainer);
