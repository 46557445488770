import React, { Component } from 'react';
import {
  PlayCircleFilled,
  FormatShapes,
  Assessment,
  Description,
} from '@material-ui/icons';
import { withRouter } from 'react-router';
import { WithAdmin } from '../../containers';
import './Sidebar.scss';
import { connect } from 'react-redux';
import { createCourse } from '../../actions/slides';
import { SupportIcon } from '../../static/imgs';

@withRouter
class SideBar extends Component {
  handleNavClick = (path) => this.props.history.push(path);

  handleCreateCourse = () => this.props.createCourse(this.props.history);

  routes = [
    {
      name: 'Курсы',
      icon: <PlayCircleFilled />,
      path: '/courses',
      onClick: this.handleNavClick,
    },
    {
      name: 'Техподдержка',
      icon: <SupportIcon />,
      path: '/support',
      onClick: this.handleNavClick,
    },
    /* {
            name: 'Тест',
            icon: <Description />,
            path: '/run'
          } */
  ];

  adminRoutes = [
    {
      name: 'Конструктор',
      icon: <FormatShapes />,
      path: '/create/info',
      onClick: this.handleCreateCourse,
    },
    {
      name: 'Результаты',
      icon: <Assessment />,
      path: '/course-results',
      onClick: this.handleNavClick,
    },
  ];

  render() {
    return (
      <aside className={this.props.opened ? 'opened' : null}>
        {this.routes.map((r, index) => (
          <div
            key={index}
            className="route"
            onClick={() => r.onClick(r.path)}
          >
            {r.icon}
            <div onClick={() => r.onClick(r.path)} className="routes">
              {r.name}
            </div>
          </div>
        ))}
        <WithAdmin>
          {this.adminRoutes.map((r, index) => (
            <div
              key={index}
              className="route"
              onClick={() => r.onClick(r.path)}
            >
              {r.icon}
              <div className="routes">{r.name}</div>
            </div>
          ))}
        </WithAdmin>
      </aside>
    );
  }
}

export default connect(null, { createCourse })(SideBar);
