const requestSMSUrl = `${process.env.BASE_API_URL}/v1/request-sms/user-login`;
const generateTokenUrl = `${process.env.BASE_API_URL}/v1/access-token-creation-requests`;

export const requestSms = ({ tel }) => new Promise((resolve, reject) => {
  fetch(requestSMSUrl, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // mode: 'no-cors',
    body: JSON.stringify({ phone: tel }),
  }).then((data) => {
    data.status === 204 ? resolve({ status: 200 }) : reject(data);
  });
});

export const generateToken = ({ tel, code }) => new Promise((resolve, reject) => {
  fetch(generateTokenUrl, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // mode: 'no-cors',
    method: 'POST',
    body: JSON.stringify({ phone: tel, smsConfirmationCode: code }),
  }).then((data) => {
    if (!data.ok) {
      reject(new Error());
    } else {
      resolve(data.json());
    }
  });
});
