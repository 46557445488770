import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Courses } from '../../pages/mobile';
import {coursesActions, slidesActions} from '../../actions';
import { Loader } from '../../component';

class CoursesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount = () => {
  //   const { slidesActions: { getCourses } } = this.props;
  //   getCourses();
  // }
  componentDidMount = () => {
    const { coursesActions: { getCourseForAnonUser, getCoursesForUser } } = this.props;
    if (sessionStorage.is_anon) {
      getCourseForAnonUser();
    } else {
      getCoursesForUser(this.props.filter);
    }
  }
  render() {
    return this.props.courses.isRequesting ? <Loader /> : <Courses {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  coursesActions: bindActionCreators(coursesActions, dispatch),
});
const mapStateToProps = (state) => ({
  courses: state.courses,
  meta: state.meta,
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesContainer);
