import React, { Component, Fragment } from 'react';
// import { withRouter } from "react-router";
import { Button } from '@material-ui/core';
import Fullscreen from 'react-full-screen';
import { Info, Question, Loader } from '../../component';
import { slideType2name } from '../../utils/translate';

import './Run.scss';

class Run extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      isSeeAll: false,
      isFull: false,
    };
  }

  componentDidMount = () => {
    // this.props.runActions.startTimer()
  }

  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  }

  handleAnswer = ({ answers, isSeeAll }) => this.setState({ answers, isSeeAll })

  handleNextClick = (finish) => {
    const { runActions: { sendSlide }, run: { current, course__new: { accessLink, slides, session } } } = this.props;
    if (slides.items[current].type !== 'test') {
      sendSlide({
        usedSeconds: 5, courseAccess: accessLink, slide: slides.items[current].self, type: slides.items[current].type, session: session.self,
      }, Boolean(finish));
    } else {
      sendSlide({
        usedSeconds: 5, courseAccess: accessLink, slide: slides.items[current].self, type: slides.items[current].type, results: this.state.answers.map((answer) => ({ ...answer, answers: { items: answer.answers } })), session: session.self,
      }, Boolean(finish));
    }
  }

  handleNextQuestionClick = ({ isSeeAll }) => this.setState({ isSeeAll })

  handlePrevClick = () => {
    const { runActions: { prevSlide } } = this.props;
    prevSlide();
  }

  render() {
    const { run: { isRequestingSendSlide, course__new, current } } = this.props;
    const { answers, isSeeAll } = this.state;
    const slides = course__new.slides.items;
    const isTest = slides[current].type === 'test';

    return (
      <>

      </>
    );
  }
}

export default Run;
