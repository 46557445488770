import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { slidesActions, runActions, sessionActions } from '../actions';
import Run from '../pages/run/Run';

import './Run.scss';
import '../pages/Courses.scss';

const styles = () => ({
  linearColorPrimary: {
    backgroundColor: '#ADADAD',
  },
  linearBarColorPrimary: {
    backgroundColor: '#339388',
  },
});

const mapStateToProps = (state) => ({
  slides: state.slides,
  run: state.run,
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  runActions: bindActionCreators(runActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Run));
