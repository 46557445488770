import React, { Component } from 'react';
import { Slider } from '@material-ui/lab';

import './Tuning.scss';

class Pdf extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="slideContent">
        <div className="slidesSidebar">
          <p>Время на блок</p>
          <Slider classes={{ container: 'slider' }} onChange={this.handleChange} value={5} />
        </div>
        <div className="mainField" />
      </div>
    );
  }
}

export default Pdf;
