import {
  constructorTypes,
  coursesTypes,
  slidesTypes,
  editorTypes,
} from '../types';
import {
  get, post, postFile, patch, deleteAPI,
} from '../api';
import { getRoot } from '.';
import {
  serializeSlide__patch,
  serializeQuestion__patch,
  serializeAnswer__patch,
  serializeAnswer__post,
  serializeQuestion__post,
  serializeSlide__post,
} from '../utils/serializers';
import { history } from '..';

// REFACTOR: split this actions by types (editor, meta, blah blah)

import {
  formatDataCourse,
  formatQuestion,
  formatSlidePhoto,
  formatSlideTest,
  formatSlideVideo,
} from '../utils/formating';
import { setQueryString } from '../utils/api';

export const getSlide = (id) => (dispatch, getState) => {
  dispatch((() => ({ type: slidesTypes.GET_SLIDE__REQUEST }))());
  get(`${process.env.BASE_API_URL}/v1/course-slides/${id}`)
    .then((slide) => dispatch((() => ({
      type: slidesTypes.GET_SLIDE__SUCCESS,
      payload: { slide },
    }))()));
};

export const editSlide = (slide) => (dispatch, getState) => dispatch((() => ({
  type: slidesTypes.EDIT_SLIDE,
  payload: {
    slide,
    slides: getState().slides.items.map((_slide) => (_slide.id === slide.id ? slide : _slide)),
  },
}))());

const getPathname = (id) => {
  const pathname = window.location.pathname.split('/');
  pathname[pathname.length - 1] = id;

  return pathname.join('/');
};

const getSlidesPathname = () => {
  const pathname = window.location.pathname.split('/');
  if (pathname[pathname.length - 1] !== 'slides') pathname.length -= 1;

  return pathname.join('/');
};

export const saveAllSlides = () => (dispatch, getState) => {
  dispatch((() => ({ type: slidesTypes.SAVE_ALL_SLIDES__REQUEST }))());
  const slides = getState().slides2save.items;
};

export const getSlideFromModel = (id) => (dispatch, getState) => {
  dispatch((() => ({ type: 'vsce' }))());
  const slides = getState().slides2save.items;
  const slide = slides.filter((slide) => slide.id === id)[0];
};

export const saveSlide = (slide) => (dispatch, getState) => {
  const courseId = getState().editor.course.self;
  dispatch((() => ({ type: slidesTypes.SAVE_SLIDE__REQUEST }))());
  if (slide.type === 'image_gallery' || slide.type === 'test') {
    if (slide.type === 'image_gallery') {
      const _photos = Array.from(slide.photos);
      if (_photos.filter((photo) => !photo.image).length === 0) {
        if (slide.isNew) {
          post(`${process.env.BASE_API_URL}/v1/course-slides`, serializeSlide__post({ ...slide, courseId })).then((__slide) => {
            history.push(`${getPathname(__slide.id)}`);
            dispatch((() => ({ type: slidesTypes.SAVE_SLIDE__SUCCESS, payload: { oldId: slide.id, slide: __slide } }))());
          });
        } else {
          patch(`${process.env.BASE_API_URL}${slide.self}`, serializeSlide__patch(slide)).then((res) => {
            dispatch((() => ({ type: slidesTypes.UPDATE_SLIDE__SUCCESS }))());
          });
        }
      } else if (slide.isNew) {
        Promise.all(_photos.map((photo) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: photo, type: 'course_slide_image_gallery_item' }))).then((_static) => {
          post(`${process.env.BASE_API_URL}/v1/course-slides`, serializeSlide__post({ ...slide, courseId, _static })).then((__slide) => {
            Promise.all(_static.map((file) => post(`${process.env.BASE_API_URL}/v1/course-image-gallery-items`, {
              description: '', image: file.self, slide: __slide.self, title: 'Галерея',
            }))).then((data) => {
              history.push(`${getPathname(__slide.id)}`);
              dispatch((() => ({ type: slidesTypes.SAVE_SLIDE__SUCCESS, payload: { oldId: slide.id, slide: __slide } }))());
            });
          });
        });
      } else {
        const newPhotos = _photos.filter((photo) => !photo.image);
        Promise.all(newPhotos.map((photo) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: photo, type: 'course_slide_image_gallery_item' }))).then((_static) => {
          patch(`${process.env.BASE_API_URL}${slide.self}`, serializeSlide__patch(slide)).then((__slide) => {
            Promise.all([..._static].map((file) => post(`${process.env.BASE_API_URL}/v1/course-image-gallery-items`, {
              description: '', image: file.self, slide: slide.self, title: 'Галерея',
            }))).then((data) => {
              dispatch((() => ({ type: slidesTypes.UPDATE_SLIDE__SUCCESS }))());
              dispatch(getSlide(slide.id));
            });
          });
        });
      }
    } else if (slide.isNew) {
      const answers = ((_answers) => slide.questions.filter((_question) => !_question.isDelete).map((__question, index) => _answers.push(__question.answers.items.map((__answer) => ({ ...__answer, index })))) && _answers.flat())([]);
      const pairs = ((_pairs) => slide.questions.filter((_question) => !_question.isDelete && _question.type === 'matching').map((__question, index) => _pairs.push(__question.matchingPairs.items.map((__pair) => ({ ...__pair, index })))) && _pairs.flat())([]);
      post(`${process.env.BASE_API_URL}/v1/course-slides`, serializeSlide__post({ ...slide, course: courseId })).then((__slide) => {
        Promise.all(slide.questions.filter((_question) => !_question.isDelete).map((question) => post(`${process.env.BASE_API_URL}/v1/course-test-questions`, serializeQuestion__post({ ...question, slide: __slide })))).then((questions) => Promise.all(answers.map((answer, index) => (answer.image ? postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: answer.image, type: 'answer_image' }).then((res) => post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, question: questions[answer.index], image: res }))) : post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, question: questions[answer.index] })))))).then((res) => {
          history.push(`${getPathname(__slide.id)}`);
          dispatch((() => ({
            type: slidesTypes.SAVE_SLIDE__SUCCESS,
            payload: { oldId: slide.id, slide: __slide },
          }))());
        });
      });
    } else {
      const pairs = ((_pairs) => slide.questions.filter((_question) => !_question.isDelete && _question.type === 'matching').map((__question, index) => _pairs.push(__question.matchingPairs.items.map((__pair) => ({ ...__pair, index })))) && _pairs.flat())([]);
      patch(`${process.env.BASE_API_URL}${slide.self}`, serializeSlide__patch(slide)).then((res) => {
        const newQuestions = slide.questions.filter((_question) => _question.isNew && !_question.isDelete).map((question) => ({ ...question, slide }));
        const deleteQuestions = slide.questions.filter((_question) => _question.isDelete && !_question.isNew).map((question) => ({ ...question, slide }));
        const newAnswers = ((_answers) => newQuestions.map((__question, index) => _answers.push(__question.answers.items.map((__answer) => ({ ...__answer, index })))) && _answers.flat())([]);
        const newPairs = ((_answers) => newQuestions.map((__question, index) => _answers.push(__question.matchingPairs.items.map((__answer) => ({ ...__answer, index })))) && _answers.flat())([]);
        Promise.all(deleteQuestions.map((question) => deleteAPI(`${process.env.BASE_API_URL}${question.self}`)))
          .then(() => Promise.all(newQuestions.map((question) => post(`${process.env.BASE_API_URL}/v1/course-test-questions`, serializeQuestion__post(question))))).then((created) => {
            if (newAnswers.length > 0) {
              Promise.all(newAnswers.map((answer) => (answer.image ? postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: answer.image, type: 'answer_image' }).then((res) => post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, question: created[answer.index], image: res }))) : post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, question: created[answer.index] }))))).then(() => Promise.all(newPairs.map((pair) => post(`${process.env.BASE_API_URL}/v1/matching-pairs`, {
                leftOption: { text: pair.leftOption },
                rightOption: { text: pair.rightOption },
                question: created[pair.index].self,
              })))).then(() => Promise.all(slide.questions.filter((question) => !question.isNew && !question.isDelete).map((question) => patch(`${process.env.BASE_API_URL}${question.self}`, serializeQuestion__patch(question)))));
            } else {
              Promise.all(newPairs.map((pair) => post(`${process.env.BASE_API_URL}/v1/matching-pairs`, {
                leftOption: { text: pair.leftOption },
                rightOption: { text: pair.rightOption },
                question: created[pair.index].self,
              }))).then((res) => Promise.all(slide.questions.filter((question) => !question.isNew && !question.isDelete).map((question) => patch(`${process.env.BASE_API_URL}${question.self}`, serializeQuestion__patch(question)))));
            }

            return created;
          })
          .then((questions) => {
            const answers = ((_answers) => slide.questions.filter((_question) => !_question.isDelete).map((question) => _answers.push(question.answers && question.answers.items.filter((__answer) => !!__answer).map((answer) => ({ ...answer, question })))) && _answers.flat())([]);
            const pairs = ((_pairs) => slide.questions.filter((_question) => !_question.isDelete && !_question.isNew && _question.type === 'matching').map((__question, index) => _pairs.push(__question.matchingPairs.items.map((__pair) => ({ ...__pair, __question })))) && _pairs.flat())([]).filter((pair) => pair.isNew);

            if (pairs.length === 0) {
              return Promise.all(answers.filter((answer) => answer && !answer.isNew).map((answer) => patch(`${process.env.BASE_API_URL}${answer.self}`, serializeAnswer__patch(answer))))
                .then(() => Promise.all(answers.filter((answer) => answer && answer.isNew && answer.question.self).map((answer) => (answer.image ? postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: answer.image, type: 'answer_image' }).then((res) => post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, image: res }))) : post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer }))))))
                .then((res) => {
                  dispatch((() => ({ type: slidesTypes.UPDATE_SLIDE__SUCCESS }))());
                  dispatch(getSlide(slide.id));
                });
            }

            return Promise.all(pairs.map((pair) => post(`${process.env.BASE_API_URL}/v1/matching-pairs`, {
              leftOption: { text: pair.leftOption },
              rightOption: { text: pair.rightOption },
              question: pair.__question.self,
            })))
              .then(() => Promise.all(answers.filter((answer) => answer && !answer.isNew).map((answer) => patch(`${process.env.BASE_API_URL}${answer.self}`, serializeAnswer__patch(answer))))
                .then(() => Promise.all(answers.filter((answer) => answer && answer.isNew && answer.question.self).map((answer) => (answer.image ? postFile(`${process.env.BASE_API_URL}/v1/static-files`, { file: answer.image, type: 'answer_image' }).then((res) => post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer, image: res }))) : post(`${process.env.BASE_API_URL}/v1/course-test-answers`, serializeAnswer__post({ ...answer }))))))
                .then(() => {
                  dispatch((() => ({ type: slidesTypes.UPDATE_SLIDE__SUCCESS }))());
                  dispatch(getSlide(slide.id));
                }));
          });
      });
    }
  } else if (slide.isNew) {
    post(`${process.env.BASE_API_URL}/v1/course-slides`, serializeSlide__post({ ...slide, course: courseId })).then((res) => {
      history.push(`${getPathname(res.id)}`);
      dispatch((() => ({ type: slidesTypes.SAVE_SLIDE__SUCCESS, payload: { oldId: slide.id, slide: res } }))());
    });// loader
  } else {
    patch(`${process.env.BASE_API_URL}${slide.self}`, serializeSlide__patch(slide)).then((res) => {
      dispatch((() => ({ type: slidesTypes.UPDATE_SLIDE__SUCCESS }))());
    });
  }
};

export const getCourses = (filter) => (dispatch, getState) => {
  dispatch((() => ({ type: coursesTypes.GET_COURSES__REQUEST }))());
  const { resources } = getState().meta;
  const query = setQueryString(filter);
  get(`${resources.courses}${query}` || `${process.env.BASE_API_URL}/v1/courses${query}`).then((data) => {
    const coursesWithAccess = data.items.map((item) => ({
      ...item,
      accessLink: sessionStorage.is_anon
        ? localStorage.access
        : getState().meta.user.courses.items.filter((accessedCourse) => accessedCourse.course === item.self)[0]
          ? getState().meta.user.courses.items.filter(
            (accessedCourse) => accessedCourse.course === item.self,
          )[0].self
          : false,
    }));
    Promise.all(coursesWithAccess.map((course) => (course.accessLink ? get(`${process.env.BASE_API_URL}${course.accessLink}`) : false))).then((accesses) => {
      const withAccesses = coursesWithAccess.map((course, index) => ({
        ...course,
        access: accesses[index],
        isFinished: accesses[index] && accesses[index].courseResults && accesses[index].courseResults.type === 'successful',
      }));
      dispatch(
        (() => ({
          type: coursesTypes.GET_COURSES__SUCCESS,
          payload: withAccesses,
        }))(),
      );
    });
  });
};

export const addSlide = (type) => (dispatch, getState) => {
  const _slide = {
    type: type.toLowerCase(),
    isNew: true,
    title: '',
    questions: {
      items: [],
    },
    id: `new${Array.from({ length: 5 }, () => Math.round(Math.random())).join('')}`,
  };
  if (type.toLowerCase() === 'image_gallery') {
    _slide.items = { items: [] };
  }
  if (type.toLowerCase() === 'poll') {
    _slide.questions = [];
  }
  dispatch(
    (() => ({
      type: editorTypes.ADD_NEW,
      payload: {
        slides: [
          ...getState().slides.items,
          _slide,
        ],
        slide: _slide,
      },
    }))(),
  );
  const pathname = window.location.pathname.split('/');
  if (pathname[pathname.length - 1] === 'slides') {
    history.push(`${window.location.pathname}/${_slide.id}`);
  } else {
    pathname[pathname.length - 1] = _slide.id;
    const newPathname = pathname.join('/');
    history.push(`${newPathname}`);
  }
};

export const getPharms = (filter, options = {}) => (dispatch, getState) => {
  dispatch({ type: slidesTypes.GET_PHARMS__REQUEST });
  const pharms = getState().meta.resources['medical-institutions'];
  get(filter === '' ? pharms : `${pharms}?title=${filter}`, options.fetchOptions).then((data) => dispatch({
    type: slidesTypes.GET_PHARMS__SUCCESS,
    payload: { items: data.items },
  })).catch((e) => {
    dispatch({ type: slidesTypes.GET_PHARMS__FAILURE });
  });
};

export const reorderSlides = (reordered, course) => (dispatch, getState) => {
  dispatch((() => ({ type: slidesTypes.REORDER_SLIDES__REQUEST }))());
  const prev = getState().slides.items;
  const modified = reordered.map((id) => prev.filter((item) => item.id === id)[0]);
  console.log(reordered);
  console.log(modified);
  post(`${process.env.BASE_API_URL}/v1/course-slide-reorder-requests`, { course, slides: { items: reordered.map((id) => `/v1/course-slides/${id}`) } })
    .then(() => dispatch((() => ({ type: slidesTypes.REORDER_SLIDES__SUCCESS, payload: { items: modified } }))()));
};

export const deleteSlide = (slide) => (dispatch, getState) => {
  dispatch((() => ({ type: slidesTypes.DELETE_SLIDE }))());
  const prev = getState().slides.items;
  const modified = prev.filter((item) => item.id !== slide.id);
  if (!slide.isNew) {
    deleteAPI(`${process.env.BASE_API_URL}/v1/course-slides/${slide.id}`)
      .then(() => {
        history.push(getSlidesPathname());
        dispatch((() => ({ type: slidesTypes.DELETE_SLIDE_SUCCESS, payload: { items: modified } }))());
      });
  } else {
    history.push(getSlidesPathname());
    dispatch((() => ({ type: slidesTypes.DELETE_SLIDE_SUCCESS, payload: { items: modified } }))());
  }
};

export const createCourse = (history) => (dispatch) => new Promise((resolve) => {
  history.push('/create/info');
  resolve(true);
}).then(() => {
  dispatch((() => ({ type: coursesTypes.CREATE_COURSE__META }))());
  dispatch((() => ({ type: slidesTypes.CLEAR_SLIDES }))());
});

export const createSlide = (data) => (dispatch, getState) => {
  dispatch((() => ({ type: slidesTypes.CREATE_SLIDE__SUCCESS }))());
};

export const updateSlide = (slide, index) => (dispatch, getState) => {
  dispatch(
    (() => ({
      type: slidesTypes.UPDATE_SLIDE,
      payload: getState().slides.items.map(
        (_slide, _index) => (index === _index ? slide : _slide),
      ),
    }))(),
  );
};

export const editCourse = (courseId, history) => (dispatch, getState) => {
  history.push(`edit/${courseId}/info`);
  dispatch((() => ({
    type: editorTypes.EDIT_COURSE,
    // payload: { ...convertCourseData(getState().courses.items.find(course => course.id === courseId)) }
  }))());
};

export const createAccess = (data) => (dispatch) => {
  dispatch((() => ({ type: editorTypes.CREATE_ACCESS__REQUEST }))());
  console.log(data);
  Promise.all(
    data.users.map((user) => post(`${process.env.BASE_API_URL}/v1/course-accesses`, {
      course: data.courseId,
      user,
      maximumNumberOfAttempts: data.attempts,
    })),
  ).then((data) => {
    dispatch(
      (() => ({
        type: editorTypes.CREATE_ACCESS__SUCCESS,
        payload: data,
      }))(),
    );
    dispatch(getRoot());
  });
};

export const getUsers = () => (dispatch, getState) => {
  dispatch((() => ({ type: editorTypes.GET_USERS__REQUEST }))());
  const { users } = getState().meta.resources;
  get(users).then((users) => dispatch(
    (() => ({
      type: editorTypes.GET_USERS__SUCCESS,
      payload: { users: users.items },
    }))(),
  ));
};

export const updateCourseInfo = (newInfo) => (dispatch) => {
  dispatch(
    (() => ({
      type: constructorTypes.UPDATE_COURSE_INFO,
      payload: newInfo,
    }))(),
  );
};

export const openCourse = (course) => ({
  type: slidesTypes.OPEN_COURSE,
  payload: {
    course,
  },
});
