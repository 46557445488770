import React, { Component, Fragment } from 'react';
import {
  Select,
  Typography,
  Button,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Loader } from '../../../component';
import * as linkTypes from '../../../constants/linkTypes';
import {
  formatInviteStatus,
  getInviteStatus,
} from '../../../utils';
import { ReactComponent as Clear } from '../../../static/imgs/delete.svg';
import DeleteLinkPrompt from '../../../component/prompt/DeleteLinkPrompt';
import Alert from '../../../component/alert/Alert';

const ChoosePharmPrompt = () => (
  <div className="no_content">
    <Typography>Выберете аптеку из списка</Typography>
  </div>
);

const PharmHasNoLinksPrompt = () => (
  <div className="no_content">
    <Typography>
      Для этой аптеки пока нет ссылок.
      {' '}
      <br />
      Вы можете сделать это в меню вышепше
    </Typography>
  </div>
);

class LinksList extends Component {
  handleDeleteInviteClick = (inv, current) => {
    const { openDeleteLinkPrompt, openAlert } = this.props;
    const alertTitle = 'Внимание!';
    const alertText = 'Невозможно удалить ссылку. Данная ссылка уже была использована';
    const inviteId = inv.id;
    // const { getInvitations, current } = this.props;

    if (inv.isDeletable) {
      openDeleteLinkPrompt(inviteId, current);
    } else {
      openAlert(alertTitle, alertText);
    }
    // getInvitations({ link: current.courseInvites });
  };

  render() {
    const {
      closeDeleteLinkPrompt,
      copiedLink,
      current,
      linkCount,
      linkType,
      handleCopyLinkClick,
      handleCreateLinkClick,
      handleLinkCountChange,
      handleLinkTypeChange,
      isOpenDeleteLinkPrompt,
      isOpenAlert,
      inviteId,
      invitations,
    } = { ...this.props };

    return (
      <div className="links_wrapper">
        {current === null ? (
          <ChoosePharmPrompt />
        ) : (
          <>
            <div className="link-create__wrapper">
              <div className="pharm_info">
                <Typography className="name-header">
                  {current.title}
                </Typography>
                <Typography className="address">
                  {current.address.short}
                </Typography>
                <Typography className="count">
                  {`(${0} пройдено, ${
                    invitations.items.length
                  } выслано)`}
                </Typography>
              </div>
              <div className="link-create__controls">
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="outlined-age-simple"
                  >
                    Тип ссылки
                  </InputLabel>
                  <Select
                    value={linkType}
                    onChange={handleLinkTypeChange}
                    input={(
                      <OutlinedInput
                        labelWidth={88}
                        name="age"
                        id="outlined-age-simple"
                      />
                          )}
                  >
                    <MenuItem value={linkTypes.ANON}>Анонимная</MenuItem>
                    <MenuItem value={linkTypes.WITH_REG}>
                      С регистрацией
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  onChange={handleLinkCountChange}
                  label="Количество ссылок"
                  value={linkCount}
                />
                <Button
                  variant="contained"
                  className="button"
                  onClick={handleCreateLinkClick}
                >
                  Создать ссылки
                </Button>
              </div>
            </div>
            <div className="links">
              {invitations.isRequesting ? (
                <Loader />
              ) : invitations.items.length === 0 ? (
                <PharmHasNoLinksPrompt />
              ) : (
                invitations.items.map((inv, index) => (
                  <div className="invite" key={index}>
                    <div
                      className="block link"
                      onClick={() => handleCopyLinkClick(inv, index)}
                    >
                      <div className="linkIcon" />
                      <div className="withIcon">
                        <Typography>{inv.id.cut(6)}</Typography>
                        <div
                          className={`copyIcon${
                            copiedLink === index ? ' copied' : ''
                          }`}
                        />
                      </div>
                    </div>
                    <Typography className="block title">
                      {inv.title || 'Добавить временное описание'}
                    </Typography>
                    <Typography
                      className={`block status ${getInviteStatus(inv)}`}
                    >
                      {formatInviteStatus(getInviteStatus(inv))}
                    </Typography>
                    <Typography className="block date">
                      {`Создана ${new Date(
                        inv.createdAt,
                      ).toLocaleDateString('ru-RU', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}`}
                    </Typography>
                    <div onClick={() => this.handleDeleteInviteClick(inv)} className="link__delete-icon">
                      <Clear />
                    </div>
                  </div>

                ))
              )}
            </div>
          </>
        )}
        {isOpenDeleteLinkPrompt
          && (
          <DeleteLinkPrompt
            closeModal={closeDeleteLinkPrompt}
            current={current}
            inviteId={inviteId}
          />
          )}
        {isOpenAlert && <Alert />}
      </div>
    );
  }
}

export default LinksList;
