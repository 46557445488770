import React from 'react';
import './Results.scss';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { courses } = this.props;

    return (
      <div className="results">
        <div className="results_container">
          {courses.map((item) => (
            <Card className="results_card" key={item.id}>
              <CardContent className="results_content">
                <div className="results_cover">
                  <img src={`${item.cover.url}`} alt={`${item.cover.url}`} />
                </div>
                <div className="results_info">
                  <div className="results_info_block">
                    <h5>Курс:</h5>
                    <h6>{item.title}</h6>
                  </div>
                </div>
              </CardContent>
              <CardActions className="results_actions">
                <Link to={`/course-results/${item.id}`}>
                  <Button variant="outlined" color="primary">
                    статистика
                  </Button>
                </Link>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

export default Results;
