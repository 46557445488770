import { slidesTypes, editorTypes } from '../types';

const initialState = {
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case slidesTypes.EDIT_SLIDE: return {
      ...state,
      items: action.payload.slides,
    };
    default:
      return state;
  }
};
