import React from 'react';
import { connect } from 'react-redux';
import Modal from '../modal/Modal';
import DeleteButton from '../button/DeleteButton';
import { deleteCourse } from '../../actions/editor';

import './style.scss';
import { Loader } from '../index';

function DeleteCoursePrompt({
  closeModal,
  courseId,
  deleteCourse,
  isRequesting,
}) {
  return (
    <Modal closeModal={closeModal}>
      <div className="prompt-delete">
        <div className="notification">
          <div className="notification__title">Внимание!</div>
          <div className="notification__text">
            <p>Курс будет удален безвозвратно!</p>
            <p>
              Также будут удалены все созданные доступы и ссылки для прохождения
              курса.
            </p>
          </div>
        </div>
        {isRequesting ? (
          <Loader small />
        ) : (
          <div className="prompt__control">
            <button
              className="prompt__btn prompt__btn_cncl"
              onClick={closeModal}
            >
              Отменить
            </button>
            <DeleteButton
              text="Удалить курс"
              onClick={() => deleteCourse(courseId)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default connect(
  (state) => ({
    isRequesting: state.editor.isRequestingDelete,
  }),
  { deleteCourse },
)(DeleteCoursePrompt);
