import { slidesTypes, editorTypes } from '../types';

const initialState = {
  items: [],
  isRequesting: false,
  isRequestingReorder: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case editorTypes.ADD_NEW: return {
      ...state,
      items: action.payload.slides,
    };
    case slidesTypes.DELETE_SLIDE:
      return {
        ...state,
        isRequesting: true,
        isRequestingReorder: true,
      };
    case editorTypes.GET_COURSE__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    // case slidesTypes.EDIT_SLIDE: return {
    //   ...state,
    //   items: action.payload.slides,
    // }
    case slidesTypes.DELETE_SLIDE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isRequestingReorder: false,
        items: action.payload.items,
      };
    case editorTypes.GET_COURSE__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.course.slides.items,
    };
    case slidesTypes.REORDER_SLIDES__REQUEST: return {
      ...state,
      isRequestingReorder: true,
    };
    case slidesTypes.REORDER_SLIDES__SUCCESS: return {
      ...state,
      isRequestingReorder: false,
      items: action.payload.items,
    };
    case slidesTypes.CREATE_SLIDE__SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.payload.oldId
          ? { ...action.payload.slide }
          : item)),
      };
    case slidesTypes.CLEAR_SLIDES:
      return {
        ...state,
        items: initialState.items,
      };
    // case slidesTypes.GET_SLIDE__SUCCESS: return {
    //   ...state,
    //   items: state.items.map(item =>
    //     item.id === action.payload.slide.id
    //     ? ({ ...action.payload.slide })
    //     : item)
    // }
    default:
      return state;
  }
};
