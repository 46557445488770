import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { slidesActions, coursesActions } from '../actions';
import { Courses } from '../pages';

@withRouter
class CoursesContainer extends Component {
  render() {
    return <Courses {...this.props} />;
  }
}
const mapStateToProps = (state) => ({
  courses: state.courses,
  meta: state.meta,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  coursesActions: bindActionCreators(coursesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursesContainer);
