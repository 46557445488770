import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { slidesActions, editorActions, saveSlidesActions } from '../actions';
import { Editor } from '../pages';
import { Loader } from '../component';
import * as promptActions from '../component/prompt/actions';

class EditorContainer extends Component {
  componentDidMount = () => {
    const { match: { path, params: { courseId } }, editorActions: { getCourse } } = this.props;
    if (courseId) {
      getCourse(courseId);
    }
  }

  render() {
    const { editor, match: { params } } = this.props;

    return editor.isRequesting
      ? <Loader />
      : <Editor {...this.props} isNew={!params.hasOwnProperty('courseId')} id={params.courseId} />;
  }
}

const mapStateToProps = (state) => ({
  editor: state.editor,
  slides: state.slides.items,
  isOpenDeleteCoursePrompt: state.prompt.isOpenDeleteCourse,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  editorActions: bindActionCreators(editorActions, dispatch),
  saveSlidesActions: bindActionCreators(saveSlidesActions, dispatch),
  promptActions: bindActionCreators(promptActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditorContainer);
