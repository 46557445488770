import React from 'react';
import Modal from '@material-ui/core/Modal';
import './style.scss';

const ModalMaterial = ({ open, onClose, children }) => (
  <Modal open={open} onClose={onClose} disableAutoFocus>
    <div className="modal-material">
      {children}
    </div>
  </Modal>
);

export default ModalMaterial;
