import React, { Component, Fragment } from 'react';
import {
  Typography,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  TextField,
  Switch,
} from '@material-ui/core';
import { Loader } from '../../component';
import '../CourseUsers.scss';
import List from '../../component/list'; // TODO: fix

class User extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      self, fullName, email, checked, onSelect,
    } = this.props;

    return (
      <div className="audienceListItem">
        <Checkbox
          classes={{ root: 'primaryColor' }}
          onChange={() => onSelect(self)}
          checked={checked}
        />
        <div className="userItems userTitle">{fullName}</div>
        <div className="userItems userTitle">{email}</div>
      </div>
    );
  }
}

class Audience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.editor.course.accesses.items.map((access) => access.user).filter((item) => !!item),
      city: '',
      specialization: '',
      switchFilter: false,
    };
  }

  handleChangeCity = (e) => {
    this.setState({ city: e.target.value.toLowerCase() });
  };

  handleChangeSpecialization = (e) => {
    this.setState({ specialization: e.target.value.toLowerCase() });
  };

  switchHandleFilter = (e) => {
    !!e;
  };

  handleUserSelect = (self) => {
    const { selected } = this.state;
    if (selected.indexOf(self) < 0) {
      this.setState({ selected: [...selected, self] });
    } else {
      this.setState({ selected: selected.filter((_self) => _self !== self) });
    }
  };

  handleAccessCourse = () => {
    const {
      slidesActions: { createAccess },
      editor: {
        course: { self, recommendedNumberOfAttempts, accesses },
      },
    } = this.props;
    const { selected } = this.state;
    createAccess({
      courseId: self,
      users: selected.filter((user) => !accesses.items.find((access) => user === access.user)),
      attempts: recommendedNumberOfAttempts,
    });
  };

  render() {
    const { users } = this.props;

    return (
      <div className="users">
        <div className="container">
          <div className="filterMenu">
            <TextField
              id="standard-select-currency"
              margin="normal"
              variant="outlined"
              select
              label="По региону"
              name="region"
              value="Регион"
              disabled
              InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
            />
            <FormControl>
              <InputLabel
                htmlFor="component-simple"
                classes={{ focused: 'focusedTextColor' }}
              >
                Город
              </InputLabel>
              <Input
                name="cityFilter"
                classes={{ focused: 'focusedBottomBorder' }}
                onChange={(e) => this.handleChangeCity(e, true)}
              />
            </FormControl>
            <Button>
              <span className="plusSpan">+</span>
              {' '}
              Критерий
            </Button>
            <TextField
              id="standard-select-currency"
              margin="normal"
              variant="outlined"
              select
              label="По специализации"
              name="specialization"
              value="Специализация"
              disabled
              InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
            />
            <FormControl>
              <InputLabel
                htmlFor="component-simple"
                classes={{ focused: 'focusedTextColor' }}
              >
                Специальность
              </InputLabel>
              <Input
                name="specializationFilter"
                classes={{ focused: 'focusedBottomBorder' }}
                onChange={(e) => this.handleChangeSpecialization(e, true)}
              />
            </FormControl>
          </div>
          <div className="audienceMenu">
            <Typography className="audience-header">Пользователи</Typography>
            <div className="audienceList">
              {users.items
              // .filter(item =>
              //   item.city.toLowerCase().indexOf(this.state.city) > -1 &&
              //   item.specialization.toLowerCase().indexOf(this.state.specialization) > -1)
                .map((user) => (
                  <User
                    key={user.id}
                    {...user}
                    onSelect={this.handleUserSelect}
                    checked={this.state.selected.indexOf(user.self) >= 0}
                  />
                ))}
              <List
                keys={['fullName', 'email']}
                headers={['Имя', 'Email']}
                // onCheck={this.state.selected.indexOf(user.self) >= 0}
                // onItemClick={this.handleTicketClick}
                items={users.items.map((user) => ({
                  fullName: user.fullName,
                  email: user.email,
                }))}
              />
            </div>
            <div className="actionFooter">
              <div className="switchRow">
                {/* <Switch
                  classes={{switchBase: 'fixHeight', checked: 'switchCheckedColor', bar: 'switchCheckedColor bar'}}
                  onChange={(_, e) => this.switchHandleFilter(e)}
                />
              <p>Только выбранные</p> */}
              </div>
              <Button className="applyCourse" onClick={this.handleAccessCourse}>
                Назначить курс
              </Button>
            </div>
          </div>
          {/*

          <DataProvider data={['pharms']}>
          {
            (data) => {
              console.log(data)
              return users.map((user, index) => <User onSelect={this.handleUserSelect} user={user} key={index} />)
            }
            }
            </DataProvider>

        */}
        </div>
      </div>
    );
  }
}

export default Audience;
