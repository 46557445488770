import { connect } from 'react-redux';
import LinksList from '../pages/editor/CourseLinkAccess/LinksList';
import {
  closeDeleteLinkPrompt,
  openDeleteLinkPrompt,
} from '../component/prompt/actions';
import { openAlert } from '../component/alert/actions';
import {getInvitations} from '../actions/editor';

const mapStateToProps = (state) => ({
  isOpenDeleteLinkPrompt: state.prompt.isOpenDeleteLink,
  isOpenAlert: state.alert.isOpen,
  invitations: state.invitations,
  inviteId: state.prompt.inviteId,
})

const mapDispatchToProps = {
  closeDeleteLinkPrompt,
  getInvitations,
  openAlert,
  openDeleteLinkPrompt,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinksList);
