import * as authApi from './auth';
import { history } from '..';

const getToken = () => localStorage.getItem('bearer_token')
  || sessionStorage.getItem('bearer_token');

const generateParams = (data, form, options = {}) => {
  const TOKEN = getToken();
  if (options.withoutToken) {
    return {
      headers: {
        Accept: 'application/json',
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(data),
    };
  } if (data) {
    return {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${TOKEN}`,
      },
      mode: 'cors',
      method: 'POST',
      body: form ? data : JSON.stringify(data),
    };
  }

  return {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${TOKEN}`,
    },
    cache: 'no-cache',
    method: 'GET',
    mode: 'cors',
    ...options,
  };
};

const generateParams__patch = (data) => ({
  headers: {
    // Accept: "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
  mode: 'cors',
  method: 'PATCH',
  body: JSON.stringify(data),
});

const generateParams__delete = () => ({
  headers: {
    // Accept: "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
  mode: 'cors',
  method: 'DELETE',
});

export async function fetchWithResponse(func, ...args) {
  const response = await func(...args);
  if (response.ok) {
    return await response.json();
  }
  const res = await response.json();
  switch (res.type) {
    case 'access_denied':
    case 'unknown_access_token':
    case 'invalid_authorization_header':
      accessTokenIsOverAction();
      break;
    case 'account_banned':
      userBlockedAction();
      break;
    default:
      throw res;
  }
}

export async function fetchWithoutResponse(func, ...args) {
  const response = await func(...args);
  if (response.ok) {
    return await response;
  }
  const res = await response.json();
  switch (res.type) {
    case 'account_banned':
      userBlockedAction();
      break;
  }
  throw res;
}

const get = (resource, options) => fetchWithResponse(fetch, resource, generateParams(null, false, options));

const getWithoutToken = (resource, options) => fetchWithResponse(fetch, resource, {
  headers: {
    Accept: 'application/json',
  },
  cache: 'no-cache',
  method: 'GET',
  mode: 'cors',
});

const post = (resource, data, withoutToken) => fetchWithResponse(
  fetch,
  resource,
  generateParams(data, false, {
    withoutToken,
  }),
);

const postNoContent = (resource, data, withoutToken) => fetchWithoutResponse(
  fetch,
  resource,
  generateParams(data, false, { withoutToken }),
);

const patch = (resource, data) => fetchWithoutResponse(fetch, resource, generateParams__patch(data));

const postFile = (resource, data) => {
  const form = new FormData();
  Object.keys(data).map((key) => form.append(key, data[key]));

  return fetchWithResponse(fetch, resource, generateParams(form, true));
};

const deleteAPI = (resource, data) => fetchWithoutResponse(fetch, resource, generateParams__delete());

const accessTokenIsOverAction = () => {
  window.localStorage.clear();
  history.push('/');
  window.location.reload();
};

const userBlockedAction = () => {
  window.localStorage.clear();
  history.push('/error');
};

export {
  authApi,
  post,
  get,
  postFile,
  postNoContent,
  patch,
  deleteAPI,
  getWithoutToken,
};
