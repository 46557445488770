import React, { Component, Fragment } from 'react';
import { Typography, Button, Icon } from '@material-ui/core';
import { conViewModes as modes } from '../../constants';
import { Course } from '../../component';
import { WithAdmin } from '../../containers';
import './Courses.scss';
import { diff2name, theme2name } from '../../utils/translate';

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: modes.EDIT,
    };
  }

  createCourse = () => {
    this.props.slidesActions.createCourse({ history: this.props.history });
  };

  handleCourseClick = (id) => {
    const {
      slidesActions: { openCourse },
      courses: { items },
    } = this.props;
    this.props.history.push(`/run/${id}`);
    openCourse(items[items.map((item) => item.id).indexOf(id)]);
  };

  handleEditCourseClick = (id) => this.props.slidesActions.editCourse(id, this.props.history);

  render() {
    const { courses } = this.props;

    console.log(courses.itemsForUser)

    return (
      <div className="courses__mobile">
        <div className="course-info-header">
          <Typography>
            Ваши курсы (
            {courses.itemsForUser.length}
            )
          </Typography>
        </div>
        <div className="container">
          {courses.itemsForUser.map((_course, index) => (
            <Course
              withInfo
              allCourse={_course}
              user={this.props.meta.user}
              key={index}
              certificate={_course.certificate_background}
              isFinished={
                  _course.access.status === 'used_with_success'
                }
              access={_course.access}
              title={_course.title}
              theme={theme2name(_course.theme)}
              difficulty={diff2name(_course.difficulty)}
              status={_course.status}
              rewardPoints={_course.rewardPoints}
              recommendedNumberOfAttempts={
                  _course.recommendedNumberOfAttempts
                }
              picture={_course.cover.url}
              description={_course.description}
              count={_course.slidesAmount}
              self={_course.self}
              onClick={() => this.handleCourseClick(
                _course.access.self.split('/').pop(),
              )}
              startButton
              certificate={_course.certificate}
            />
          ))}
        </div>
      </div>
    );
  }
}

export { Courses, Course };
