import React, { Component } from 'react';

class Audio extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="pictureTest">
        <h5>Тест "Какой ты врач?"</h5>
        <div className="picture__wrapper">
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
        </div>
        <div className="picture__gallery">
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
          <img src="https://vistanews.ru/uploads/posts/2018-07/1532804702_ded-ulybaetsya-foto.jpg" />
        </div>
        <div className="text__wrapper">
          <span className="body1">
            Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
            nostrud ullamco nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in velit esse cillum
            dolore eu fugiat nulla pariatur.
          </span>
        </div>
      </div>
    );
  }
}

export default Audio;
