export const serializeSlide__patch = (slide) => {
  console.log(slide);
  switch (slide.type) {
    case 'youtube_video':
      return {
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        youTubeVideo: slide.youTubeVideo,
        canBeRewound: +slide.canBeRewound,
      };
    case 'text':
      return {
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        text: slide.text,
      };
    case 'image_gallery':
      return {
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        // photos: { items: [] } TODO: дать Петру пиздюлей
      };
    case 'test':
      return {
        description: slide.description,
        maximumAmountOfRetries: slide.maximumAmountOfRetries,
        minimumResult: slide.minimumResult,
        isQuestionShuffleEnabled: new Number(slide.isQuestionShuffleEnabled),
        questionsToSolve: slide.questionsToSolve,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
      };
    case 'poll':
      return {
        title: slide.title,
        questions: slide.questions,
        timeToComplete: slide.timeToComplete,
      };
  }
  // return slide
};

export const serializeSlide__post = (slide) => {
  switch (slide.type) {
    case 'youtube_video':
      return {
        type: slide.type,
        course: slide.course,
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        youTubeVideo: slide.youTubeVideo,
        canBeRewound: +slide.canBeRewound,
      };
    case 'text':
      return {
        type: slide.type,
        course: slide.course,
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        text: slide.text,
      };
    case 'image_gallery':
      return {
        course: slide.course,
        description: slide.description,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
        type: 'image_gallery',
        // photos: { items: slide._static.map(file => file.self) }
      };
    case 'test':
      return {
        course: slide.course,
        type: slide.type,
        description: slide.description,
        maximumAmountOfRetries: slide.maximumAmountOfRetries,
        isQuestionShuffleEnabled: new Number(slide.isQuestionShuffleEnabled),
        minimumResult: slide.minimumResult,
        questionsToSolve: slide.questionsToSolve,
        timeToComplete: slide.timeToComplete,
        title: slide.title,
      };
    case 'poll':
      return {
        course: slide.course,
        type: slide.type,
        title: slide.title,
        questions: slide.questions,
        timeToComplete: slide.timeToComplete,
      };
  }
  // return slide
};

export const serializeQuestion__patch = (question) => ({
  text: question.text,
});

export const serializeAnswer__patch = (answer) => {
  const _answer = {
    isCorrect: new Number(answer.isCorrect),
    text: answer.text,
  };
  if (answer.image) {
    _answer.image = answer.image.self;
  }

  return _answer;
};

export const serializeQuestion__post = (question) => ({
  description: question.description,
  text: question.text,
  type: question.__type,
  slide: question.slide.self,
});
export const serializeAnswer__post = (answer, question) => {
  const _answer = {
    isCorrect: new Number(answer.isCorrect),
    text: answer.text,
    question: question.self,
  };
  if (answer.image) {
    _answer.image = answer.image.self;
  }

  return _answer;
};

export const serializeReorderPhoto = (slide, photos) => ({
  gallery: slide.self,
  galleryItems: {
    items: photos.map(({ self }) => self),
  },
});

export const serializeReorderQuestions = (slide, questions) => ({
  test: slide.self,
  questions: {
    items: questions.map(({ self }) => self),
  },
});
