import React, { Component } from 'react';
import {
  Photo,
  Test,
  Text,
  Video,
  Dialog,
} from './slides';
import './Question.scss';

class Question extends Component {
  selectSlidePreviewByType = (_current) => {
    const {
      run,
      handleChangeSlideInfo,
      onAnswer,
      onClick,
      onNextQuestionClick,
    } = this.props;
    const {
      currentSession,
      course__new: {
        slides: { items },
      },
    } = run;
    const currentSlide = items.find(({ self }) => self === currentSession.slide) || {};
    switch (currentSlide.type) {
      case 'youtube_video':
        return <Video slide={currentSlide} />;
      case 'test':
        return (
          <div className="test__wrapper">
            <Test
              onAnswer={onAnswer}
              onNextQuestionClick={onNextQuestionClick}
              slide={currentSlide}
            />
          </div>
        );
      case 'image_gallery':
        return (
          <div className="backgroundColor__wrapper">
            <Photo
              onFullscreenClick={onClick}
              handleChangeSlideInfo={handleChangeSlideInfo}
              slide={currentSlide}
            />
          </div>
        );
      case 'text':
        return (
          <div className="text__wrapper">
            <Text {...this.props} slide={currentSlide} />
          </div>
        );
      case 'poll':
        return (
          <div className="text__wrapper">
            <Dialog {...this.props} slide={currentSlide} />
          </div>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <div className="question__wrapper">
        <div className="question__content">
          {this.selectSlidePreviewByType(this.props.run.current)}
        </div>
      </div>
    );
  }
}

export default Question;
