export const CREATE_SESSION__REQUEST = 'SESSION_TYPE__CREATE_SESSION__REQUEST';
export const CREATE_SESSION__SUCCESS = 'SESSION_TYPE__CREATE_SESSION__SUCCESS';
export const CREATE_SESSION__FAILURE = 'SESSION_TYPE__CREATE_SESSION__FAILURE';

export const GET_COURSE_SESSION = 'GET_COURSE_SESSION';
export const GET_COURSE_SESSION_SUCCESS = 'GET_COURSE_SESSION_SUCCESS';
export const GET_COURSE_SESSION_FAILURE = 'GET_COURSE_SESSION_FAILURE';

export const GET_SLIDE_SESSION = 'GET_SLIDE_SESSION';
export const GET_SLIDE_SESSION_SUCCESS = 'GET_SLIDE_SESSION_SUCCESS';
export const GET_SLIDE_SESSION_FAILURE = 'GET_SLIDE_SESSION_FAILURE';

export const CREATE_SLIDE_SESSION = 'CREATE_SLIDE_SESSION';
export const CREATE_SLIDE_SESSION_SUCCESS = 'CREATE_SLIDE_SESSION_SUCCESS';
export const CREATE_SLIDE_SESSION_FAILURE = 'CREATE_SLIDE_SESSION_FAILURE';

export const CLOSE_SLIDE_SESSION = 'CLOSE_SLIDE_SESSION';
export const CLOSE_SLIDE_SESSION_SUCCESS = 'CLOSE_SLIDE_SESSION_SUCCESS';
export const CLOSE_SLIDE_SESSION_FAILURE = 'CLOSE_SLIDE_SESSION_FAILURE';

export const GET_ACCESS__REQUEST = 'SESSION_TYPE__GET_ACCESS__REQUEST';
export const GET_ACCESS__SUCCESS = 'SESSION_TYPE__GET_ACCESS__SUCCESS';
export const GET_ACCESS__FAILURE = 'SESSION_TYPE__GET_ACCESS__FAILURE';
