export const diff2name = (diff) => {
  switch (diff) {
    case 'easy':
      return 'Легкая';
    case 'medium':
      return 'Средняя';
    case 'hard':
      return 'Сложная';
  }
};
export const slideType2name = (type) => {
  switch (type) {
    case 'image_gallery': return 'Галерея';
    case 'youtube_video': return 'Видео';
    case 'test': return 'Тест';
    case 'text': return 'Текст';
    case 'poll': return 'Диалог';
  }
};
export const name2diff = (diff) => {
  switch (diff) {
    case 'Легкая':
      return 'easy';
    case 'Средняя':
      return 'medium';
    case 'Сложная':
      return 'hard';
  }
};
export const theme2name = (theme) => {
  switch (theme) {
    case 'general_medical':
      return 'Общие медицинские';
    case 'otc':
      return 'OTC';
    case 'rx':
      return 'RX';
    case 'mixed':
      return 'Смешанные';
  }
};
export const name2theme = (diff) => {
  switch (diff) {
    case 'Общие медицинские':
      return 'general_medical';
		// case "ЗАМЕНИТЬ":
		//   return "orc";
  }
};
export const mode2String = (mode) => {
  // TODO: replace by types
  switch (mode) {
    case 'EDIT':
      return 'Блоки';
    case 'TARGET':
      return 'Ссылки';
    case 'AUDIENCE':
      return 'Аудитория';
    case 'RUN':
      return 'Предварительный просмотр';
    case 'INFO':
      return 'Информация';
  }
};
export const type2name = (type) => {
  switch (type) {
    case 'text':
      return 'Текст';
    case 'test':
      return 'Тест';
    case 'pdf':
      return 'Pdf';
    case 'audio':
      return 'Аудио';
    case 'youtube_video':
      return 'Видео';
    case 'image_gallery':
      return 'Фото';
    case 'poll':
      return 'Диалог';
  }
};
