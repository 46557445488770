import { Radio } from '@material-ui/core';
import React from 'react';

const Radios = ({ ...props }) => (
  <div className="oneAnswer">
    <h6>
      {props.index}
      .
      {props.question.text}
    </h6>
    <span className="subtitle1">Правильный вариант ответа только один</span>
    <div className="answerOption">
      {props.question.answers.items.map((answer, key) => (
        <span key={key} className="body1">
          <Radio
            checked={props.answer.answers.indexOf(answer.self) !== -1}
            onClick={() => props.onAnswer(
              {
                question: props.question.self,
                answer: answer.self,
                type: 'select',
              },
              true,
            )}
            color="primary"
          />
          {answer.image ? (
            <img
              onClick={() => props.onImageClick(answer.image && answer.image.url)}
              className="answerPhoto"
              src={answer.image && answer.image.url}
            />
          ) : (
            answer.text
          )}
        </span>
      ))}
    </div>
  </div>
);

export default Radios;
