import React, { Component } from 'react';
import { RunTimer } from '../pages';
import {Loader} from '../component';

class RunTimerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeToCompleteCourse:  this.props.run.course__new.maximumTimeToComplete,
      timeToCompleteSlide:  this.props.timeToCompleteSlide,
      timeOfPreviousSlides: 0,
      timeFromSlideSession: 0,
      isComputed: false,
    };
  }

  currentSlideIndex = () => {
    const {currentSession, course__new: {slides}} = this.props.run
    return slides.items.findIndex(slide => slide.self === currentSession.slide)
  }

  timeOfPreviousSlides = (currentSlideIndex) => {
    const {slides} = this.props.run.course__new
    if (currentSlideIndex) {
      this.setState((state)=>({
        timeOfPreviousSlides:
          state.timeOfPreviousSlides + slides.items[currentSlideIndex - 1].timeToComplete,
      }))
      this.timeOfPreviousSlides(currentSlideIndex - 1)
    }
  }

  timeFromSlideSession = () => {
    const {timeToCompleteSlide, timeFromSlideSession} = this.state
    const {createdAt} = this.props.run.currentSession
    let slideSessionDuration = Math.floor((Date.now() - Date.parse(createdAt))/1000)
    let timer = setTimeout(
      () => {
        if (timeToCompleteSlide > slideSessionDuration) {
          this.setState(() => ({
            timeFromSlideSession: slideSessionDuration,
            isComputed: true
          }))
        this.timeFromSlideSession()
        } else {
          clearTimeout(timer)
          this.setState((state) => ({
            timeFromSlideSession: state.timeToCompleteSlide,
            isComputed: true
          }))
        }
      }, 1000
    )
  }

  componentDidMount = () => {
    this.timeFromSlideSession()
    const currentSlideIndex = this.currentSlideIndex()
    this.timeOfPreviousSlides(currentSlideIndex)
  }

  render() {
    const {
      timeToCompleteSlide,
      timeToCompleteCourse,
      timeOfPreviousSlides,
      timeFromSlideSession,
      isComputed
    } = this.state
    return(
      <>
        {
          isComputed
          ? <RunTimer
              remainingTimeToCompleteSlide={timeToCompleteSlide - timeFromSlideSession}
              remainingTimeToCompleteCourse={timeToCompleteCourse - timeOfPreviousSlides - timeFromSlideSession}
              timeToCompleteSlide={this.props.timeToCompleteSlide}
              timeToCompleteCourse={this.props.run.course__new.maximumTimeToComplete}
            />
          : <Loader/>
        }
      </>
    )
  }
}


export default RunTimerContainer;
