import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Result } from '../pages';
import { Loader } from '../component';
import { getStats } from '../actions/stats';

@withRouter
class ResultContainer extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getStats,
    } = this.props;
    getStats(id);
  }

  render() {
    const {
      isRequesting,
      stats: {
        data: { stats },
      },
      editor: {
        course: { availability },
      },
    } = this.props;
    console.log(this.props)
    console.log(availability)
    console.log(stats)

    return (
      <div>
        {isRequesting ? (
          <Loader />
        ) : (
          <Result
            courseTitle={this.props.course.title}
            availability={availability}
            stats={stats}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = {
  getStats,
};
const mapStateToProps = (state) => ({
  editor: state.editor,
  course: state.editor.course,
  stats: state.stats,
  isRequesting: state.editor.isRequesting || state.stats.isRequesting,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultContainer);
