import React, { Component } from 'react';
import {
  MenuItem,
  withStyles,
  InputLabel,
  Select,
  IconButton,
} from '@material-ui/core';
import {
  CLOSED,
  OPEN,
  statuses,
  translateStatus,
} from '../../constants/tickets';
import CloseIcon from '@material-ui/icons/Close';
import './Ticket.scss';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ModalMaterial from '../../component/modal/ModalMaterial';
import { roles } from '../../constants/roles';


const StyledSelect = withStyles({
  root: {
    fontSize: '16px',
    color: '#263238',
    width: '400px',
    zIndex: 9999999,
  },
  select: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
})(Select);

const StyledMenuItem = withStyles({
  root: {
    fontSize: '16px',
    color: '#263238',
  },
})(MenuItem);

const SelectLabel = withStyles({
  root: {
    fontSize: '16px',
    color: '#808080',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    marginRight: '20px',
  },
})(InputLabel);

class ModalTicketChangeStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      message: '',
    };
  }

  handleChangeStatus = ({ target: { value } }) => this.setState({ status: value });

  handleMessageChange = ({ target: { value } }) => this.setState({ message: value });

  onSubmit = () => {
    const { status, message } = this.state;
    const { onSubmitChangeStatus } = this.props;
    if (message.trim()) onSubmitChangeStatus(status, message.trim());
  }

  getAvailableStatuses = () => {
    const { user, author } = this.props;
    if (user.roles.find((role) => role === roles.ROLE_SUPPORT_AGENT)) return statuses;
    if (author.self === user.self) return [OPEN, CLOSED];

    return [];
  };

  render() {
    const { status, message } = this.state;
    const {
      closeModal,
      isRequesting,
      isOpenModal,
    } = this.props;
    const availableStatuses = this.getAvailableStatuses();

    return (
      <ModalMaterial open={isOpenModal} onClose={closeModal}>
        <div className="modal-status__title">
          <div>Изменить статус обращения</div>
          <IconButton
            className="close"
            aria-label="Delete"
            onClick={closeModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="modal-status__select">
          <SelectLabel htmlFor="outlined-age-simple">
            Выберите статус
          </SelectLabel>
          <StyledSelect
            value={status}
            onChange={this.handleChangeStatus}
            fullWidth
            input={(
              <OutlinedInput
                name="age"
                id="outlined-age-simple"
              />
            )}
          >
            {availableStatuses.map((_status) => (
              <StyledMenuItem key={_status} value={_status}>
                {translateStatus(_status)}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </div>
        <textarea
          value={message}
          onChange={this.handleMessageChange}
          placeholder="Опишите причину смены статуса"
          className="modal-status__message"
        />
        <div className="modal-status__group">
          <button
            className="modal-status__btn modal-status__btn_cancel"
            onClick={() => closeModal()}
          >
            Отменить
          </button>
          <button
            className={`modal-status__btn modal-status__btn_confirm ${
              isRequesting ? 'modal-status__btn_loading' : ''
            }`}
            onClick={this.onSubmit}
          >
            Сохранить
          </button>
        </div>
      </ModalMaterial>
    );
  }
}

export default ModalTicketChangeStatus;
