import React, { Component } from 'react';
import { connect } from 'react-redux';

class WithAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { roles } = this.props.meta.user;

    return roles && roles.indexOf('ROLE_MANAGER') !== -1 ? this.props.children : null;
  }
}

const mapStateToProps = (state) => ({
  meta: state.meta,
});

export default connect(mapStateToProps)(WithAdmin);
