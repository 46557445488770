import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ticketsActions } from '../actions';
import { Ticket } from '../pages';
import { Loader } from '../component';
import { getTicket } from '../actions/tickets';
import { POLLING_DURATION_SHORT } from '../constants/tickets';

class TicketContainer extends Component {
  componentDidMount = () => {
    const { ticketsActions: { getTicket }, match: { params: { ticketId } } } = this.props;
    getTicket(ticketId);
  }

  render() {
    const { ticket, match: { params: { ticketId } } } = this.props;

    return ticket.isRequesting
      ? <Loader />
      : (
        <Ticket
          {...this.props}
          id={ticketId}
          duration={POLLING_DURATION_SHORT}
          enablePolling={!sessionStorage.is_anon}
        />
      );
  }
}

const mapStateToProps = (state) => ({
  ticket: state.ticket,
  user: state.meta.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ticketsActions: bindActionCreators(ticketsActions, dispatch),
  pollingAction: () => dispatch(getTicket(ownProps.match.params.ticketId, { isHiddenLoader: true })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TicketContainer);
