export const PLANNING = 'planning';
export const READY = 'ready';
export const CANCELED = 'canceled';

export const IN_USE = 'in_use';
export const NOT_USED = 'not_used';
export const USED_WITH_FAILURE = 'used_with_failure';
export const USED_WITH_SUCCESS = 'used_with_success';
export const INVITE_NOT_ACTIVATED = 'invite_not_activated';

export const translateCourseStatisticStatuses = (status) => {
  switch (status) {
    case IN_USE:
      return 'В процессе';
    case NOT_USED:
      return 'Не начат';
    case USED_WITH_FAILURE:
      return 'Не пройден';
    case USED_WITH_SUCCESS:
      return 'Пройден';
    case INVITE_NOT_ACTIVATED:
      return 'Не активирован доступ';
    default:
      return '';
  }
};

export const availabilityStatuses = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  NOT_STARTED_YET: 'not_started_yet',
}
