import React, { Component } from 'react';
import { Slider } from '@material-ui/lab';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import uuid from 'uuid/v4';
import { formatQuestions, beautifySec } from '../../../utils';
import 'react-mde/lib/styles/css/react-mde-all.css';
import './Tuning.scss';
import * as Showdown from 'showdown';
import DeletedQuestion from '../../../component/test/DeletedQuestion';
import TextQuestion from '../../../component/test/TextQuestion';
import ImageQuestion from '../../../component/test/ImageQuestion';
import CollateQuestion from '../../../component/test/CollateQuestion';
import DndVerticalList from '../../../component/dnd/DndVerticalList';

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimumResult: 50,
      questionsGrouping: 1,
      questionsToSolve: 1,
      questions: [],
      title: '',
      isQuestionShuffleEnabled: true,
      timePerOne: 2,
      timeToComplete: 900,
      isValidQuestion: false,
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
    });
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.slide.item,
      questions: this.props.slide.item.questions
        ? formatQuestions(this.props.slide.item.questions.items)
        : [],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState && this.props.index === prevProps.index) {
      this.props.onEdit(this.state, this.props.index);
    }
  }

  validate = (question) => {
    const { title, description, questions } = this.state;

    const valid = (
      title !== ''
      && description !== ''
      && questions.length !== 0
      && question ? this.validateQuestion(question) : false
    );

    this.props.setValidSlide(valid);
  }

  validateQuestion = (question) => {
    switch (question.type) {
      case 'text':
        return (
          question.text !== ''
          && question.answers.items.length !== 0
          && question.answers.items.every(answer => answer.text !== '')
        );
      case 'image':
        return (
          question.text !== ''
          && question.answers.items.length !== 0
        );
      case 'matching':
        return (
          question.text !== ''
          && question.matchingPairs.items.every(pair => pair.leftOption !== '' && pair.rightOption !== '')
        );
      default:
        return false;
    }
  }

  handleSliderChange = (name, value) => {
    this.setState(() => ({ [name]: value }), () => this.props.setValidSlide(true));
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value }, this.validate);
  };

  handleChangeQuestion = (question, index) => this.setState((state) => ({
    questions: state.questions.map((_q, _index) => (_index === index ? question : _q)),
  }));

  handleDeleteQuestion = (indexQuestion) => {
    this.setState((state) => ({
      questions: state.questions.map((question, index) => (index === indexQuestion ? { ...question, isDelete: true } : question)),
    }));
  };

  handleRestoreQuestion = (indexQuestion) => {
    this.setState((state) => ({
      questions: state.questions.map((question, index) => (index === indexQuestion ? { ...question, isDelete: false } : question)),
    }));
  };

  addQuestion = (type) => {
    this.setState((state) => ({
      questions: [
        ...state.questions,
        {
          id: uuid(),
          title: '',
          type,
          text: '',
          __type: type === 'matching' ? 'matching' : 'select',
          mode: 'write',
          isNew: true,
          answers: { items: [] },
          pairs: { items: [] },
        },
      ],
    }));
  };

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState({
      [name]: checked,
    });
  };

  getQuestionByType = (question, indexQuestions) => {
    if (question.isDelete) {
      return (
        <DeletedQuestion
          question={question}
          handleRestoreQuestion={() => this.handleRestoreQuestion(indexQuestions)}
        />
      );
    }
    switch (question.type) {
      case 'text':
        return (
          <TextQuestion
            indexQuestions={indexQuestions}
            question={question}
            handleDeleteQuestion={this.handleDeleteQuestion}
            onChange={(question) => this.handleChangeQuestion(question, indexQuestions)}
            isInvalidInput={this.props.isInvalidInput}
            validate={this.validate}
          />
        );
      case 'image':
        return (
          <ImageQuestion
            indexQuestions={indexQuestions}
            question={question}
            handleDeleteQuestion={this.handleDeleteQuestion}
            onChange={(question) => this.handleChangeQuestion(question, indexQuestions)}
            isInvalidInput={this.props.isInvalidInput}
            validate={this.validate}
          />
        );
      case 'matching':
        return (
          <CollateQuestion
            indexQuestions={indexQuestions}
            question={question}
            handleDeleteQuestion={this.handleDeleteQuestion}
            onChange={(question) => this.handleChangeQuestion(question, indexQuestions)}
            isInvalidInput={this.props.isInvalidInput}
            validate={this.validate}
          />
        );
    }
  };

  handleChangeOrderQuestions = (items) => {
    this.setState(({ questions }) => ({
      questions: items.map(({ id }) => questions.find((question) => id === question.id)),
    }));
  };

  render() {
    const { description, title, questions } = this.state;
    const {isInvalidInput} = this.props;

    return (
      <div className="slideContent">
        <div className="slidesSidebar">
          <Typography id="slider-image">{`Проходной рубеж: ${this.state.minimumResult}%`}</Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="minimumResult"
            onChange={(_, value) => this.handleSliderChange('minimumResult', value)}
            value={this.state.minimumResult}
            step={1}
            min={1}
            max={100}
          />
          <Typography id="slider-image">
            {`Времени на блок: ${Math.trunc(
              this.state.timeToComplete / 3600,
            )} ч ${Math.trunc(
              this.state.timeToComplete / 60,
            ) % 60} мин ${beautifySec(
              this.state.timeToComplete / 60
              - Math.trunc(this.state.timeToComplete / 60),
            )} сек`}
          </Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="timeToComplete"
            onChange={(_, value) => this.handleSliderChange('timeToComplete', value)}
            value={this.state.timeToComplete}
            step={20}
            min={20}
            max={10800}
          />
          <Typography id="slider-image">{`Количество вопросов, которые будут показаны при прохождении: ${this.state.questionsToSolve}`}</Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="time"
            onChange={(_, value) => this.handleSliderChange('questionsToSolve', value)}
            value={this.state.questionsToSolve}
            step={1}
            min={1}
            max={40}
          />
          <FormControlLabel
            control={(
              <Checkbox
                name="isQuestionShuffleEnabled"
                onChange={this.handleCheckboxChange}
                checked={this.state.isQuestionShuffleEnabled}
              />
            )}
            label="Перемешивать вопросы при прохождении"
          />
        </div>
        <div className="mainField">
          <div className="testConstructor__wrapper">
            <div className="contentWrapper">
              <Typography id="slider-image">Название блока:</Typography>
              <TextField
                value={title}
                error={!title ? isInvalidInput : null}
                onChange={this.handleChange('title')}
                className="textaddAnswer full"
                placeholder="Заголовок"
              />
              <Typography id="slider-image">Текст:</Typography>
              <TextField
                value={description}
                error={!description ? isInvalidInput : null}
                multiline
                rowsMax="7"
                onChange={this.handleChange('description')}
                className="text full"
                placeholder="Описание"
              />
            </div>
            <DndVerticalList
              items={questions.map((question, indexQuestions) => ({
                id: question.id,
                component: this.getQuestionByType(question, indexQuestions),
              }))}
              onChange={this.handleChangeOrderQuestions}
            />
            <Button
              className={`addButton checkboxQuestion ${!questions.length ? isInvalidInput ? 'addButton_error' : '' : null}`}
              onClick={() => this.addQuestion('text')}
            />
            <Button
              style={{ marginLeft: '20px' }}
              className={`addButton addPhotoQuestion ${!questions.length ? isInvalidInput ? 'addButton_error' : '' : null}`}
              onClick={() => this.addQuestion('image')}
            />
            <Button
              style={{ marginLeft: '20px' }}
              className={`addButton addCollateQuestion ${!questions.length ? isInvalidInput ? 'addButton_error' : '' : null}`}
              onClick={() => this.addQuestion('matching')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Test;
