import React, { Component, Fragment } from 'react';
import { Button, Checkbox, Typography } from '@material-ui/core';
import { createPortal } from 'react-dom';
import { slideType2name } from '../../utils/translate';
import { Course } from '../Courses';
import { courseAccess } from '../../constants';

const rootEl = document.getElementById('root');

class CoockieAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieAlertState: '',
    };
  }

  disableCoockies = () => this.setState({ cookieAlertState: 'disabled' });

  render() {
    return (
      <div className={`cookieAlert__menu + ${this.state.cookieAlertState}`}>
        <Typography>Внимание! Сайт использует файлы cookie.</Typography>
        <button onClick={this.disableCoockies}>Ок</button>
      </div>
    );
  }
}

class StartRunPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTermOfUse: false,
      isMedicalAgent: false,
    };
  }

  handleStartCourseClick = () => this.props.sessionActions.createSession(
    this.props.run.course__new.access.self,
  );

  handleCheckboxChange = (field, value) => this.setState({ [field]: value });

  render() {
    const { timer, course__new } = this.props.run;
    const { slides: courseSlides, access } = course__new;
    const slides = courseSlides.items.length > 0 ? courseSlides.items : [];
    const count = courseSlides.items.length || '';
    const isCourseComplete = access.status === courseAccess.USED_WITH_FAILURE
      || access.status === courseAccess.USED_WITH_SUCCESS;

    return (
      <>
        {createPortal(<CoockieAlert />, rootEl)}
        <div className="stepBar">
          <div className="stepBarPoint current">ИНФОРМАЦИЯ</div>
          {slides.map((slide, index) => (
            <div key={index} className="stepBarPoint slideType">
              {slideType2name(slide.type)}
            </div>
          ))}
          <div className="stepBarPoint">РЕЗУЛЬТАТ</div>
          {/* <Button className="cancelButton" size="large" onClick={this.handleStartCourseClick}>ПРЕРВАТЬ ПРОХОЖДЕНИЕ</Button> */}
        </div>
        <div className="test__wrapper">
          <div className="course__wrapper">
            <Course
              runPage
              time={timer}
              {...course__new}
              access={false}
              picture={course__new.cover.url || ''}
              count={count}
            />
            <div className="content__wrapper">
              <div className="info__wrapper">
                {isCourseComplete ? (
                  <h2>Курс уже был пройден или закончилось число попыток</h2>
                ) : (
                  <>
                    <h2>Информация о прохождении курса</h2>
                    <p className="body1">{course__new.description}</p>
                    <p className="body1">
                      Прохождение курса состоит из следующих блоков:
                    </p>
                    <div className="slidesPreview">
                      {slides.map((slide, index) => (
                        <div key={index} className={`slide ${slide.type}`}>
                          <Typography>{slideType2name(slide.type)}</Typography>
                        </div>
                      ))}
                      {/* TODO: logic instead addButtons */}
                    </div>
                    <p className="body1">
                      За прохождение всех блоков вы получите
                      {' '}
                      {course__new.rewardPoints}
                      {' '}
                      балов
                    </p>
                    <div className="agreementRow">
                      <Checkbox
                        color="primary"
                        checked={this.state.isTermOfUse}
                        onChange={(e) => this.handleCheckboxChange(
                          'isTermOfUse',
                          e.target.checked,
                        )}
                      />
                      <Typography variant="body2">
                        Я соглашаюсь на обработку персональных данных в
                        соответствии с
                        {' '}
                        <a
                          target="_blank"
                          href="https://storage.yandexcloud.net/dpg-documents/terms-of-use.html"
                        >
                          Условиями использования сайта
                        </a>
                        ,
                        {' '}
                        <a
                          target="_blank"
                          href="https://storage.yandexcloud.net/dpg-documents/privacy-policy.html"
                        >
                          Политикой обработки персональных данных
                        </a>
                        {' '}
                        и на получение сообщений в процессе работы с сайтом.
                      </Typography>
                    </div>
                    <div className="agreementRow" style={{ marginTop: 0 }}>
                      <Checkbox
                        color="primary"
                        checked={this.state.isMedicalAgent}
                        onChange={(e) => this.handleCheckboxChange(
                          'isMedicalAgent',
                          e.target.checked,
                        )}
                      />
                      <Typography className="body1">
                        Начиная данный курс, вы подтверждаете, что являетесь
                        специалистом фармацевтической или медицинской отрасли
                      </Typography>
                    </div>
                    <div className="actionFooter">
                      <Button
                        disabled={
                          !(this.state.isMedicalAgent && this.state.isTermOfUse)
                        }
                        className="startButton"
                        size="large"
                        onClick={this.handleStartCourseClick}
                      >
                        Начать
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StartRunPage;
