import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Typography, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import { slidesActions, runActions, sessionActions } from '../../actions';
import { Course } from '../../pages';
import { Loader } from '../../component';
import { slideType2name } from '../../utils/translate';
import './Run.scss';
import '../../pages/Courses.scss';
import { courseSessionStatuses } from '../../pages/run/constants';
import RunSlides from '../../pages/run/RunSlides';

const styles = () => ({
  linearColorPrimary: {
    backgroundColor: '#ADADAD',
  },
  linearBarColorPrimary: {
    backgroundColor: '#339388',
  },
});


class StartCoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTermOfUse: false,
      isMedicalAgent: false,
    };
  }

  handleStartCourseClick = () => this.props.sessionActions.createSession(this.props.run.course__new.access.self)

  handleCheckboxChange = (field, value) => this.setState({ [field]: value });

  render() {
    const { timer, course__new } = this.props.run;
    const slides = course__new.slides.items.length > 0 ? course__new.slides.items : [];
    const count = course__new.slides.items.length || '';


    return (
      <>
        <div className="stepBar">
          <div className="stepBarPoint current">ИНФОРМАЦИЯ</div>
          {
            slides.map((slide, index) => <div key={index} className="stepBarPoint slideType">{ slideType2name(slide.type) }</div>)
          }
          <div className="stepBarPoint">РЕЗУЛЬТАТ</div>
        </div>
        <div className="test__wrapper">
          <div className="course__wrapper">
            <Course time={timer} {...course__new} access={false} picture={course__new.cover.url || ''} count={count} />
            <div className="content__wrapper">
              <div className="info__wrapper">
                <h2>Информация о прохождении курса</h2>
                <p className="body1">{course__new.description}</p>
                <p className="body1">Прохождение курса состоит из следующих блоков:</p>
                <div className="slidesPreview">
                  {slides.map((slide, index) => <div key={index} className={`slide ${slide.type}`}><Typography>{slideType2name(slide.type)}</Typography></div>)}
                </div>
                <p className="body1">
                  За прохождение всех блоков вы получите {course__new.rewardPoints} балов
                </p>
                <div className="agreementRow">
                  <Checkbox
                    color="primary"
                    checked={this.state.isTermOfUse}
                    onChange={(e) => this.handleCheckboxChange('isTermOfUse', e.target.checked)}
                  />
                  <Typography variant="body1">
                    Я соглашаюсь на обработку персональных данных в соответствии с
                    <a target="_blank" href="https://storage.yandexcloud.net/dpg-documents/terms-of-use.html">
                      &nbsp; Условиями использования сайта, &nbsp;
                    </a>
                    <a target="_blank" href="https://storage.yandexcloud.net/dpg-documents/privacy-policy.html">
                      Политикой обработки персональных данных &nbsp;
                    </a>
                    и на получение сообщений в процессе работы с сайтом.
                  </Typography>
                </div>
                <div className="agreementRow" style={{ marginTop: 0 }}>
                  <Checkbox
                    color="primary"
                    checked={this.state.isMedicalAgent}
                    onChange={(e) => this.handleCheckboxChange('isMedicalAgent', e.target.checked)}
                  />
                  <Typography className="body1">
                    Начиная данный курс, вы подтверждаете, что являетесь
                    специалистом фармацевтической или медицинской отрасли
                  </Typography>
                </div>
              </div>
              <div className="actionFooter">
                <Button
                  disabled={!(this.state.isMedicalAgent && this.state.isTermOfUse)}
                  className="startButton"
                  size="large"
                  onClick={this.handleStartCourseClick}
                >
                  Начать
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class FinishCoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      run: { course__new },
    } = this.props;
    const slides = course__new.slides.items;
    const remainingAttempts = course__new.access.remainingAttempts;
    const checkRun = Object.keys(course__new).length === 0;
    const count = course__new.slides.items.length || '';
    const isSuccess = course__new.session.status === courseSessionStatuses.SUCCESSFUL;

    return (
      <>
        <div className="stepBar">
          <div className="stepBarPoint">ИНФОРМАЦИЯ</div>
          {slides.map((slide, index) => <div key={index} className="stepBarPoint slideType">{ slideType2name(slide.type) }</div>)}
          <div className="stepBarPoint current">РЕЗУЛЬТАТ</div>
        </div>
        <div className="test__wrapper">
          <div className="course__wrapper">
            <div className="finished__wrapper">
              {
                checkRun ? <Redirect to="/course" />
                  : (
                    <Course
                      runPage
                      picture={course__new.cover.url}
                      {...course__new}
                      count={count}
                    />
                  )
              }
              <div className="content__wrapper">
                <div className="info__wrapper">
                  <div className={`mobileIcon ${isSuccess ? 'successIcon' : 'failedIcon'}`} />
                  <Typography>{isSuccess ? 'Курс успешно пройден!' : 'Курс не пройден'}</Typography>
                  <div className="statisticIcons mobileIcon">
                    {isSuccess && (
                    <div className="bonusIcon">
                      {course__new.rewardPoints}
                      {' '}
                      БОНУСОВ
                    </div>
                    )}
                  </div>
                </div>
                <div className="actionFooter">
                  {
                      sessionStorage.is_anon
                        ? isSuccess
                          ? null
                          : remainingAttempts === 1
                            ? <Button className="toPageButton" disabled>Закончились попытки</Button>
                            : <Button className="toPageButton" onClick={() => this.props.history.push('/courses')}>Пройти заново</Button>
                        : <Button className="toPageButton" onClick={() => this.props.history.push('/courses')}>К странице с курсами</Button>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class RunContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { sessionActions, match } = this.props;
    sessionActions.getAccess(match.params.id);
  }

  render() {
    const { run: { isRequesting, course__new } } = this.props;
    const { session } = course__new;
    const remainingAttempts = course__new.access && course__new.access.remainingAttempts;

    return isRequesting ? (
      <Loader />
    ) : session ? (
      session.status === courseSessionStatuses.UNFINISHED ? (
        <RunSlides {...this.props} />
      ) : (
        <FinishCoursePage {...this.props} />
      )
    ) : (
      remainingAttempts
        ? <StartCoursePage {...this.props} />
        : <p className='start-page__message__zero-attempt'>Попытки для прохождения курса закончились</p>

    );
  }
}

const mapStateToProps = (state) => ({
  slides: state.slides,
  run: state.run,
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  runActions: bindActionCreators(runActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(RunContainer));
