import React, { Component } from 'react';
import {
  Button, Checkbox, TextField, Typography,
} from '@material-ui/core';
import ReactMde from 'react-mde';

class TextQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      answers: { items: [] },
    };
  }

    componentDidMount = () => this.setState(() => ({ ...this.props.question }));

    handleChangeTitle = (text) => {
      this.setState(
        () => ({text}),
        () => {
          this.props.onChange(this.state),
          this.props.validate(this.state)
        }
      )
    };

    handleAddAnswer = () => this.setState((state) => ({
      answers: {
        items: [
          ...state.answers.items,
          { text: '', isNew: true, isCorrect: false },
        ],
      },
    }),
      this.props.validate(this.state)
    );

    handleChangeAnswer = (indexAnswers, field, value) => {
      this.setState(
        (state) => ({
          answers: {
            items: state.answers.items.map((answer, index) => (indexAnswers === index ? { ...answer, [field]: value } : answer)),
          },
        }),
        () => {
          this.props.onChange(this.state),
          this.props.validate(this.state)
        },
      );
    };

    handleDeleteAnswer = (indexAnswers) => this.setState(
      () => ({
        answers: {
          items: this.state.answers.items.filter(
            (_, index) => index !== indexAnswers,
          ),
        },
      }),
      () => {
        this.props.onChange(this.state),
        this.props.validate(this.state)
      }
    );

    render() {
      const { answers } = this.state;
      const { indexQuestions, question, isInvalidInput } = this.props;

      return (
        <div className="contentWrapper">
          <div
            key={`questions_${indexQuestions}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className="trashContainer"
              onClick={() => this.props.handleDeleteQuestion(indexQuestions)}
            />
            <Typography className="topMargin" id="slider-image">
              Вопрос
            </Typography>
            <ReactMde
              className={`testText ${!question.text ? isInvalidInput ? 'invalidValue' : '' : null}`}
              onChange={this.handleChangeTitle}
              value={question.text}
              generateMarkdownPreview={(markdown) => Promise.resolve(this.converter.makeHtml(markdown))}
              selectedTab={question.mode}
              placeholder="Текcт вопроса"
            />
            <Typography id="slider-image">Ответы</Typography>
            {this.state.answers.items.map((answer, indexAnswers) => (
              <div
                key={indexAnswers}
                className="question"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {answer.isNew && (
                <div
                  onClick={() => this.handleDeleteAnswer(indexAnswers)}
                  style={{ cursor: 'pointer' }}
                >
                  -
                </div>
                )}
                <TextField
                  className="text"
                  label="Ответ"
                  value={answer.text}
                  error={!answer.text ? isInvalidInput : null}
                  onChange={({ target: { value } }) => this.handleChangeAnswer(indexAnswers, 'text', value)}
                  fullWidth
                />
                <Checkbox
                  checked={answer.isCorrect}
                  onChange={({ target: { checked } }) => this.handleChangeAnswer(indexAnswers, 'isCorrect', checked)}
                />
              </div>
            ))}

            <Button
              className={!answers.items.length ? isInvalidInput ? 'button_error' : '' : null}
              onClick={this.handleAddAnswer}
            >
              + Добавить ответ
            </Button>
          </div>
        </div>
      );
    }
}

export default TextQuestion;
