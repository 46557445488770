import React, { Component } from 'react';
import { Loader } from '../../component';
import FinishRunPage from './FinishRunPage';
import RunSlides from './RunSlides';
import StartRunPage from './StartRunPage';
import { courseSessionStatuses } from './constants';

class Run extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { sessionActions, match } = this.props;
    // this.props.runActions.resetRun()
    // this.props.runActions.calculateTime()
    sessionActions.getAccess(match.params.id);
  };

  render() {
    const {
      run: { isRun, isRequesting, course__new },
    } = this.props;
    const { session } = course__new;

    return isRequesting ? (
      <Loader />
    ) : session ? (
      session.status === courseSessionStatuses.UNFINISHED ? (
        <RunSlides {...this.props} />
      ) : (
        <FinishRunPage {...this.props} />
      )
    ) : (
      <StartRunPage {...this.props} />
    );
  }
}

export default Run;
