import { authTypes, linksTypes, coursesTypes } from '../types';

const initialState = {
  isRequesting: true,
  isUserRequesting: true,
  isInvite: false,
  isInviteError: false,
  isUserError: false,
  inviteId: '',
  resources: {},
  user: { fullName: '', courses: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case coursesTypes.GET_ACCESSES_FOR_USER__SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          courses: { items: action.payload.accesses },
        },
      };
    case linksTypes.GET_INVITE__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case linksTypes.GET_INVITE__FAILURE:
      return {
        ...state,
        isInviteError: true,
        isRequesting: false,
      };
    case linksTypes.SET_INVITE_ID:
      return {
        ...state,
        isInvite: true,
        inviteId: action.payload.id,
      };
    case authTypes.GET_CURRENT_USER__PROCESS:
      return {
        ...state,
        isUserRequesting: true,
      };
    case authTypes.GET_CURRENT_USER__FAILURE:
      return {
        ...state,
        isUserRequesting: false,
        isRequesting: false,
        isUserError: true,
      };
    case authTypes.GET_CURRENT_USER__SUCCESS:
      return {
        ...state,
        isUserRequesting: false,
        isRequesting: false,
        user: action.payload.user,
      };
    case authTypes.NO_USER:
      return {
        ...state,
        isUserRequesting: false,
        isRequesting: false,
      };
    case authTypes.GET_ROOT_RESOURCE__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case authTypes.GET_ROOT_RESOURCE__SUCCESS:
      return {
        ...state,
        resources: action.payload.resources,
        isRequesting: false,
      };
    case authTypes.GET_ROOT_RESOURCE__FAILURE:
      return {
        ...state,
        isRequesting: false,
        isUserRequesting: false,
      };
    default:
      return state;
  }
};
