import { authTypes } from '../types';
import * as authSteps from '../constants/authSteps';

const initialState = {
  step: authSteps.SEND_PHONE,
  step_signup: authSteps.SIGN_UP_SEND_PHONE,
  user: {
    email: '',
    name: '',
  },
  isRequesting: false,
  isSuccessfullySignup: false,
  isError: false,
  isSignUp: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGN_UP_SUCCESS: return {
      ...state,
      isSignUp: true,
    };
    case authTypes.SEND_TEL_ON_SIGN_UP__REQUEST: return {
      ...state,
      isRequesting: true,
      isError: false,
    };
    case authTypes.SEND_TEL_ON_SIGN_UP__SUCCESS: return {
      ...state,
      isRequesting: false,
      step_signup: authSteps.SIGN_UP_SEND_CODE,
    };
    case authTypes.SEND_TEL_ON_SIGN_UP__FAILURE: return {
      ...state,
      isRequesting: false,
      isError: true,
      errorMessage: 'Пользователь с этим номером уже существует или введен некорректный номер. ' +
        'Залогиньтесь с помощью данного номера или используйте другой при регистрации',
    };
    case authTypes.SEND_CODE_ON_SIGN_UP__FAILURE: return {
      ...state,
      isRequesting: false,
      isError: true,
      errorMessage: 'Введен неверный код из СМС.',
    };
    case authTypes.SEND_EMAIL_ON_SIGN_UP__FAILURE: return {
      ...state,
      isRequesting: false,
      isError: true,
      errorMessage: 'Пользователь с этим email уже существует.',
    };
    case authTypes.DISABLE_ERROR: return {
      ...state,
      isRequesting: false,
      isError: false,
    };
    case authTypes.SEND_NUMBER__REQUEST:
      return {
        ...state,
        isError: false,
        isRequesting: true,
      };
    case authTypes.SEND_NUMBER__SUCCESS:
      return {
        ...state,
        step: authSteps.SEND_CODE,
        isError: false,
        isRequesting: false,
      };
    case authTypes.SEND_NUMBER__FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: 'Пользователя с этим номером не существует. Пройдите регистрацию или используйте другой номер',
        isRequesting: false,
      };
    case authTypes.SEND_CODE__REQUEST:
      return {
        ...state,
        isError: false,
        isRequesting: true,
      };
    case authTypes.SEND_CODE__SUCCESS:
      return {
        ...state,
        step: authSteps.SEND_CODE,
        isError: false,
        isRequesting: false,
      };
    case authTypes.SEND_CODE__FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: 'Введен неверный код из СМС.',
        isRequesting: false,
      };
    case authTypes.GET_USER__SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
          name: action.payload.user.fullName,
        },
      };
    case authTypes.SIGN_UP_SUCCESS: return {
      ...state,
      isSuccessfullySignup: true,
    };
    case authTypes.SIGN_UP_FAILURE: return {
      ...state,
      isError: true,
    };
    default:
      return state;
  }
};
