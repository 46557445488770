import React, { Component } from 'react';
import Question from '../../component/test/Question';
import { Loader } from '../../component';

class RunQuestion extends Component {
  componentDidMount() {
    const {
      sessionActions: { getSlideSession, createSlideSession },
      run: { course__new },
    } = this.props;
    const { session } = course__new;
    const { activeSlideSession } = session;
    if (activeSlideSession) {
      getSlideSession(activeSlideSession);
    } else {
      createSlideSession(session.self);
    }
  }

  render() {
    const {
      run: { isRequestingSlideSession },
    } = this.props;

    return isRequestingSlideSession ? <Loader /> : <Question {...this.props} />;
  }
}

export default RunQuestion;
