import React, { Component } from 'react';
import { Slider } from '@material-ui/lab';
import { Typography, TextField } from '@material-ui/core';
import { beautifySec } from '../../../utils';
import './Tuning.scss';
import DndHorizontalList from '../../../component/dnd/DndHorizontalList';
import uuid from 'uuid/v4';

class Photo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      timeToComplete: 180,
      timePerOne: 2,
      photos: props.slide.item.items.items,
    };
  }

  componentDidMount = () => this.setState({
    ...this.props.slide.item,
  });

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevState) {
      // console.log(JSON.stringify(this.state) == JSON.stringify(prevState));
      this.props.onEdit(
        { ...this.state, type: 'image_gallery' },
        this.props.index,
      );
    }
  }

  validate = () => {
    const { title, photos } = this.state;

    const valid = (
      title !== ''
      && photos.length !== 0
    );

    this.props.setValidSlide(valid);
  }

  handleSliderChange = (name, value) => this.setState(
    { [name]: value },
    () => {
      this.props.onEdit({...this.state, type: 'image_gallery'}, this.props.index,),
      this.validate();
    }
  );

  handleChange = (e, value) => {
    this.setState({[e.target.name]: e.target.value},
      () => {
        this.props.onEdit({...this.state, type: 'image_gallery'}, this.props.index,),
        this.validate();
      },
    );
  }

  handleDeletePhoto = (index) => this.setState(
    ({ photos }) => ({ photos: photos.filter((_, photoIndex) => index !== photoIndex) }),
    this.validate,
  );

  handleFileChange = ({ target: { files } }) => {
    this.setState(
      {
        photos:
          this.state.photos.length === 0
            ? Array.from(files).map((file) => ({ file, id: uuid() }))
            : [
              ...this.state.photos,
              ...Array.from(files).map((file) => ({ file, id: uuid() })),
            ],
      },
      () => {
        this.props.onEdit({...this.state, type: 'image_gallery'}, this.props.index),
        this.validate();
      },
    );
  };

  loadPreview = (photo) => (photo.image ? photo.image.url : URL.createObjectURL(photo.file));

  handleChangeOrderPhoto = (items) => {
    this.setState(({ photos }) => ({
      photos: items.map(({ id }) => photos.find((photo) => id === photo.id)),
    }));
  };

  render() {
    const {title, description} = this.state;
    const {
      slide: { item },
      isInvalidInput
    } = this.props;
    const photos = Array.from(this.state.photos);

    return (
      <div className="slideContent">
        <div className="slidesSidebar">
          <Typography id="slider-image">
            {`Времени на вопрос: ${Math.floor(
              this.state.timeToComplete / 60,
            )} мин ${beautifySec(
              this.state.timeToComplete / 60
              - Math.floor(this.state.timeToComplete / 60),
            )} сек`}
          </Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="timeToComplete"
            onChange={(_, value) => this.handleSliderChange('timeToComplete', value)}
            value={this.state.timeToComplete}
            step={20}
            min={20}
            max={3000}
          />
        </div>
        <div className="mainField">
          <div className="contentWrapper">
            <Typography id="slider-image">Название блока</Typography>
            <TextField
              value={title}
              error={!title ? isInvalidInput : null}
              name="title"
              onChange={this.handleChange}
              className="text"
            />
            <Typography id="slider-image">Описание блока</Typography>
            <TextField
              value={description}
              error={!description ? isInvalidInput : null}
              rowsMax="7"
              name="description"
              onChange={this.handleChange}
              className="text"
              multiline
            />
            <div className="photoInput__wrapper">
              <input
                onChange={this.handleFileChange}
                className="photoInput"
                type="file"
                accept="image/*"
                id="file"
                multiple
              />
              <label htmlFor="file" className={!photos.length ? isInvalidInput ? 'input_error' : '' : null}>+</label>
              <div className="photoInputPreview">
                <DndHorizontalList
                  onChange={this.handleChangeOrderPhoto}
                  items={photos.map((photo, index) => ({
                    id: photo.id,
                    component: (
                      <div className="previewContainer">
                        <div
                          className="delete"
                          onClick={() => this.handleDeletePhoto(index)}
                        />
                        <img key={index} src={this.loadPreview(photo)} />
                      </div>
                    ),
                  }))}
                />
              </div>
            </div>
            <div className="photoInfo">
              <Typography>Форматы: jpg, png, tif. Не более 30 mb</Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Photo;
