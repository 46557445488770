export const ADD_NEW = 'SLIDE_TYPE__ADD_NEW';
export const UPDATE_SLIDE = 'SLIDE_TYPE__UPDATE_SLIDE';
export const OPEN_COURSE = 'SLIDE_TYPE__OPEN_COURSE';
export const REORDER_SLIDES__REQUEST = 'RUN_TYPE__REORDER_SLIDES__REQUEST';
export const REORDER_SLIDES__SUCCESS = 'RUN_TYPE__REORDER_SLIDES__SUCCESS';
export const REORDER_SLIDES__FAILURE = 'RUN_TYPE__REORDER_SLIDES__FAILURE';
export const GET_PHARMS__REQUEST = 'RUN_TYPE__GET_PHARMS__REQUEST';
export const GET_PHARMS__SUCCESS = 'RUN_TYPE__GET_PHARMS__SUCCESS';
export const GET_PHARMS__FAILURE = 'RUN_TYPE__GET_PHARMS__FAILURE';
export const GET_SLIDE__REQUEST = 'SLIDE_TYPE__GET_SLIDE__REQUEST';
export const GET_SLIDE__SUCCESS = 'SLIDE_TYPE__GET_SLIDE__SUCCESS';
export const GET_SLIDE__FAILURE = 'SLIDE_TYPE__GET_SLIDE__FAILURE';
export const EDIT_SLIDE = 'SLIDE_TYPE__EDIT_SLIDE';
export const SAVE_SLIDE__REQUEST = 'SLIDE_TYPE__SAVE_SLIDE__REQUEST';
export const SAVE_SLIDE__SUCCESS = 'SLIDE_TYPE__SAVE_SLIDE__SUCCESS';
export const CREATE_SLIDE__SUCCESS = 'CREATE_SLIDE__SUCCESS';
export const SAVE_ALL_SLIDES__FAILURE = 'SLIDE_TYPE__SAVE_ALL_SLIDES__FAILURE';
export const SAVE_ALL_SLIDES__REQUEST = 'SLIDE_TYPE__SAVE_ALL_SLIDES__REQUEST';
export const SAVE_ALL_SLIDES__SUCCESS = 'SLIDE_TYPE__SAVE_ALL_SLIDES__SUCCESS';
export const SAVE_SLIDE__FAILURE = 'SLIDE_TYPE__SAVE_SLIDE__FAILURE';
export const UPDATE_SLIDE__SUCCESS = 'SLIDE_TYPE__UPDATE_SLIDE__SUCCESS';
export const DELETE_SLIDE = 'DELETE_SLIDE';
export const DELETE_SLIDE_SUCCESS = 'DELETE_SLIDE_SUCCESS';
export const CLEAR_SLIDES = 'CLEAR_SLIDES';
