import React, { Component } from 'react';
import { Slider } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import './Tuning.scss';
import { beautifySec } from '../../../utils';

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      timeToComplete: 180,
      timePerOne: 2,
      text: '',
      tab: 'write',
      isInvalid: false,
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
    });
  }

  handleTextChange = (text) => {
    this.setState(
      () => ({ text }),
      () => {
        this.props.onEdit({...this.state, type: 'text'}, this.props.index),
        this.validate();
      }
    );
  };

  handleTabChange = (tab) => {
    this.setState({ tab });
  };

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.slide.item,
    });
  }

  handleSliderChange = (name, value) => {
    this.setState(
      { [name]: value },
      () => {
        this.props.onEdit({...this.state, type: 'text'}, this.props.index),
        this.validate();
      }
    );
  }

  handleTitleChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value },
      () => {
        this.props.onEdit({...this.state, type: 'text'}, this.props.index),
        this.validate();
      }
    );
  }

  validate = () => {
    const { title, text } = this.state;

    const valid = (
      title !== ''
      && text !== ''
    );

    this.props.setValidSlide(valid);
  }

  render() {
    const {
      timeToComplete, title, text, tab,
    } = this.state;
    const {isInvalidInput} = this.props;

    return (
      <div className="slideContent">
        <div className="slidesSidebar">
          <Typography id="slider-image">
            {`Времени на вопрос: ${Math.floor(
              timeToComplete / 60,
            )} мин ${beautifySec(
              timeToComplete / 60
              - Math.floor(timeToComplete / 60),
            )} сек`}
          </Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="timeToComplete"
            onChange={(_, value) => this.handleSliderChange('timeToComplete', value)}
            value={timeToComplete}
            step={20}
            min={20}
            max={1000}
          />
        </div>
        <div className="mainField">
          <div className="contentWrapper">
            <Typography id="slider-image">Название блока</Typography>
            <TextField
              error={!title ? isInvalidInput : null}
              value={title}
              name="title"
              onChange={this.handleTitleChange}
              className="text full"
            />
            <Typography style={{ marginBottom: '10px' }} id="slider-image">Текст</Typography>
            <ReactMde
              onChange={this.handleTextChange}
              value={text}
              onTabChange={this.handleTabChange}
              generateMarkdownPreview={(markdown) => Promise.resolve(this.converter.makeHtml(markdown))}
              selectedTab={tab}
              className={!text ? isInvalidInput ? 'invalidValue' : '' : null}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Text;
