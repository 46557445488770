import { editorTypes } from '../types';

const initialState = {
  isRequesting: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case editorTypes.GET_USERS__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case editorTypes.GET_USERS__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.users,
    };
    default: return state;
  }
};
