import { statsTypes, editorTypes } from '../types';
import { getWithoutToken } from '../api';

export const getStats = (courseId) => (dispatch) => {
  dispatch({ type: statsTypes.GET_STATS__REQUEST });
  getWithoutToken(`${process.env.BASE_API_URL}/v1/courses/${courseId}`, true)
    .then((course) => {
      dispatch({
        type: editorTypes.GET_COURSE__SUCCESS,
        payload: { course },
      });
      course.courseStatisticsByAccessesJson
        ? getWithoutToken(course.courseStatisticsByAccessesJson.url).then((data) => {
          const courseEndDate = new Date(course.availability.till);
          const finalData = {
            stats: data.filter(
              (line) => (line.completedAt === null ? true : (new Date(line.completedAt) <= courseEndDate)),
            ),
          };
          dispatch({
            type: statsTypes.GET_STATS__SUCCESS,
            payload: finalData,
          });
        })
        : dispatch({
          type: statsTypes.GET_STATS__FAILURE,
        });
    });
};
