import { manufacturerTypes } from '../types';

const initialState = {
  isRequesting: false,
  items: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case manufacturerTypes.GET_MANUFACTURERS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case manufacturerTypes.GET_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        items: payload.data.items,
        isRequesting: false,
      };
    default:
      return state;
  }
};
