import React, { Component } from 'react';
import Fullscreen from 'react-full-screen';
import { Info, Loader } from '../../component';
import { slideType2name } from '../../utils/translate';

import './Run.scss';
import RunQuestion from './RunQuestion';
import { questionType } from '../../constants/questions';

class RunSlides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      isSeeAll: false,
      isFull: false,
      slideInfo: {},
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
        this.props.run.currentSession && !nextProps.run.currentSession
        || !this.props.run.currentSession && nextProps.run.currentSession
        || this.props.run.currentSession.slide !== nextProps.run.currentSession.slide
    ) {
     this.setState({
       answers: []
     })
    }
  }

  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  };

  handleAnswer = ({ answers, isSeeAll }) => {
    this.setState({ answers, isSeeAll });
  };

  handleNextClick = (finish) => {
    const {
      sessionActions: { closeSlideSession },
      run: { currentSession, course__new },
    } = this.props;
    const options = finish
      ? {
        isFinish: Boolean(finish),
        courseSession: course__new.session.self,
      }
      : {
        withCreateSlideSession: true,
        courseSession: course__new.session.self,
      };
    switch (currentSession.type) {
      case 'test':
        closeSlideSession(this.prepareDataTest(), options);
        break;
      case 'image_gallery':
        closeSlideSession(this.prepareDataImageGallery(), options);
        break;
      case 'poll':
        closeSlideSession(
          {
            slideSession: currentSession.self,
            type: currentSession.type,
            answers: this.state.answers,
          },
          options,
        );
        break;
      default:
        closeSlideSession(
          {
            slideSession: currentSession.self,
            type: currentSession.type,
          },
          options,
        );
    }
  };

  handleNextQuestionClick = ({ isSeeAll }) => this.setState({ isSeeAll });

  handleChangeSlideInfo = (slideInfo) => {
    this.setState({
      slideInfo,
    });
  };

  handlePrevClick = () => {
    const {
      runActions: { prevSlide },
    } = this.props;
    prevSlide();
  };

  prepareDataImageGallery() {
    const {
      run: { currentSession },
    } = this.props;
    const { slideInfo } = this.state;

    return {
      slideSession: currentSession.self,
      type: currentSession.type,
      ...slideInfo,
    };
  }

  prepareDataTest() {
    const {
      run: { currentSession },
    } = this.props;
    const { answers } = this.state;

    return {
      slideSession: currentSession.self,
      type: currentSession.type,
      results: answers.map(
        ({
          question, type, leftOptions, rightOptions, answers,
        }) => {
          const result = {
            question,
            type,
          };
          if (type === questionType.MATCHING) {
            Object.assign(result, {
              combinedPairs: leftOptions.map((leftOption, index) => ({
                leftOption: leftOption.self,
                rightOption: rightOptions[index].self,
              })),
            });
          }
          if (type === questionType.SELECT) Object.assign(result, { answers: { items: answers } });

          return result;
        },
      ),
    };
  }

  render() {
    const { run } = this.props;
    const { isRequestingSendSlide, course__new, currentSession } = run;
    const { items: slides } = course__new.slides;
    const { answers, isSeeAll } = this.state;
    const currentSlide = slides.find(({ self }) => self === currentSession.slide) || {};
    const isTest = currentSlide.type === 'test';

    return (
      <>
        <div className="stepBar">
          <div className="stepBarPoint">ИНФОРМАЦИЯ</div>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`stepBarPoint slideType ${
                index == this.props.run.current ? 'current' : ''
              }`}
            >
              {slideType2name(slide.type)}
            </div>
          ))}
          <div className="stepBarPoint">РЕЗУЛЬТАТ</div>
        </div>
        <div className="test__wrapper">
          <div className="component__wrapper">
            <div className="test__info">
              <Info {...this.props} />
            </div>
            <Fullscreen
              enabled={this.state.isFull}
              onChange={(isFull) => this.setState({ isFull })}
            >
              <div className="test__question">
                <RunQuestion
                  {...this.props}
                  answers={answers}
                  onClick={this.goFull}
                  onNextQuestionClick={this.handleNextQuestionClick}
                  onAnswer={this.handleAnswer}
                  handleChangeSlideInfo={this.handleChangeSlideInfo}
                />
                <div className="actionFooter">
                  {slides.findIndex(
                    ({ self }) => currentSlide.self === self,
                  )
                  !== slides.length - 1 ? (
                      isRequestingSendSlide ? (
                        <Loader small />
                      ) : (
                        <div
                          className={`button${
                            isTest && !(isSeeAll && answers.length !== 0)
                              ? ' disabled'
                              : ''
                          }`}
                          onClick={
                          isTest && !(isSeeAll && answers.length !== 0)
                            ? () => null
                            : () => this.handleNextClick(false)
                        }
                        >
                          ДАЛЕЕ
                        </div>
                      )
                    ) : isRequestingSendSlide ? (
                      <Loader small />
                    ) : (
                      <div
                        className={`button${
                          isTest && !(isSeeAll && answers.length !== 0)
                            ? ' disabled'
                            : ''
                        }`}
                        onClick={
                        isTest && !(isSeeAll && answers.length !== 0)
                          ? () => null
                          : () => this.handleNextClick(true)
                      }
                      >
                        ЗАКОНЧИТЬ КУРС
                      </div>
                    )}
                </div>
              </div>
            </Fullscreen>
          </div>
        </div>
      </>
    );
  }
}

export default RunSlides;
