import React from 'react';
import { ReactComponent as Delete } from '../../icons/svg/Basket.svg';
import './style.scss';

function DeleteButton({ text, onClick }) {
  return (
    <button className="delete__wrapper" onClick={onClick}>
      {text}
      <Delete className="delete__icon" />
    </button>
  );
}

export default DeleteButton;
