import { coursesTypes } from '../types';

const initialState = {
  isRequesting: false,
  isRequestingForUser: false,
  items: [],
  itemsForUser: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case coursesTypes.GET_COURSES__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case coursesTypes.GET_COURSES__SUCCESS:
      return {
        ...state,
        items: action.payload,
        isRequesting: false,
      };
    case coursesTypes.GET_COURSES_FOR_USER__REQUEST:
      return {
        ...state,
        isRequestingForUser: true,
      };
    case coursesTypes.GET_COURSES_FOR_USER__SUCCESS:
      return {
        ...state,
        itemsForUser: action.payload,
        isRequestingForUser: false,
      };
    default:
      return state;
  }
};
