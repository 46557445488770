import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, TextField } from '@material-ui/core';
import { manufacturersActions } from '../../actions';
import AutocompleteInput from './index';

function ManufacturerInput({
  icon,
  placeholder,
  value,
  handleChange,
  classNameSuggestion,
  classNameSuggestions,
  manufacturers: { isRequesting, items },
  manufacturersActions: { getManufacturers },
  variant,
}) {
  const handleChangeManufacturer = (value) => {
    getManufacturers({ title: value });
  };

  return (
    <AutocompleteInput
      variant={variant}
      icon={icon}
      name="manufacturer"
      placeholder={placeholder}
      value={value || ''}
      isRequesting={isRequesting}
      suggestions={items}
      suggestionsKey="title"
      onChange={handleChangeManufacturer}
      onSelect={(item) => handleChange(item)}
      classNameSuggestion={classNameSuggestion}
      classNameSuggestions={classNameSuggestions}
    />
  );
}

const mapStateToProps = (state) => ({
  manufacturers: state.manufacturers,
});

const mapDispatchToProps = (dispatch) => ({
  manufacturersActions: bindActionCreators(manufacturersActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManufacturerInput);
