import { constructorTypes, editorTypes, coursesTypes } from '../types';

const initialState = {
  isRequesting: false,
  isRequestingSuccess: false,
  isRequestingError: false,
  redirect: false,
  title: '',
  description: '',
  theme: 'general_medical',
  difficulty: '',
  availability: {
    from: '',
    till: '',
  },
  cover: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case editorTypes.EDIT_COURSE: return {
      ...state,
      // ...action.payload.course
    };
    case constructorTypes.UPDATE_COURSE_INFO:
      return { ...state, ...action.payload };
    case constructorTypes.RESET_COURSE_INFO:
      return initialState;
    case constructorTypes.POST_NEW_COURSE:
      return {
        ...state,
        isRequesting: true,
      };
    case coursesTypes.CREATE_COURSE__META: return {
      ...initialState,
    };
    case constructorTypes.POST_NEW_COURSE_SUCCESS:
      return {
        ...state,
        redirect: true,
        isRequesting: false,
        isRequestingSuccess: true,
      };
    case constructorTypes.POST_NEW_COURSE_FAIL:
      return {
        ...initialState,
        isRequesting: false,
        isRequestingError: true,
      };
    default:
      return state;
  }
};
