import React from 'react';
import { connect } from 'react-redux';
import Modal from '../modal/Modal';
import DeleteButton from '../button/DeleteButton';
import { deleteLink } from '../../actions/links';

import './style.scss';
import { Loader } from '../index';

function SaveSlidePrompt({
  closeModal,
  inviteId,
  deleteLink,
  isRequesting,
  current,
}) {
  return (
    <Modal closeModal={closeModal}>
      <div className="prompt-delete">
        <div className="notification">
          <div className="notification__title">Внимание!</div>
          <div className="notification__text">
            <p>Вы не сохранили введенные данные. Хотите сохранить?</p>
          </div>
        </div>
        {isRequesting ? (
          <Loader small />
        ) : (
          <div className="prompt__control">
            <button
              className="prompt__btn prompt__btn_cncl"
              onClick={closeModal}
            >
              Отменить
            </button>
            <DeleteButton
              text="Удалить ссылку"
              onClick={() => deleteLink(inviteId, current)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default connect(
  (state) => ({
    isRequesting: state.editor.isRequestingDelete,
  }),
  { deleteLink },
)(SaveSlidePrompt);
