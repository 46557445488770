import { slideTypes } from '../constants';
import { slidesTypes } from '../types';
import {
  post, postFile, patch, deleteAPI,
} from '../api';
import {
  serializeSlide__patch,
  serializeQuestion__patch,
  serializeAnswer__patch,
  serializeAnswer__post,
  serializeQuestion__post,
  serializeSlide__post,
  serializeReorderPhoto,
  serializeReorderQuestions,
} from '../utils/serializers';
import { history } from '..';
import { GET_SLIDE__SUCCESS, SAVE_SLIDE__REQUEST, SAVE_SLIDE__SUCCESS } from '../types/slides';
import { getSlide } from './slides';

const getPathname = (id) => {
  const pathname = window.location.pathname.split('/');
  pathname[pathname.length - 1] = id;

  return pathname.join('/');
};

const getSlidesPathname = () => {
  const pathname = window.location.pathname.split('/');
  if (pathname[pathname.length - 1] !== 'slides') pathname.length -= 1;

  return pathname.join('/');
};

export const saveSlide = (slide) => async (dispatch, getState) => {
  dispatch({ type: SAVE_SLIDE__REQUEST });
  const courseId = getState().editor.course.self;
  switch (slide.type) {
    case slideTypes.TEST:
      await dispatch(saveSlide__test(slide, courseId));
      break;
    case slideTypes.PHOTO:
      await dispatch(saveSlide__images(slide, courseId));
      break;
    default:
      await dispatch(saveSlide__default(slide, courseId));
  }
  dispatch({ type: SAVE_SLIDE__SUCCESS });
};

const saveSlide__default = (slide, courseId) => (dispatch) => {
  if (slide.isNew) {
    return postCourseSlide({ ...slide, course: courseId })
      .then((newSlide) => {
        history.push(`${getPathname(newSlide.id)}`);
        dispatch({
          type: slidesTypes.CREATE_SLIDE__SUCCESS,
          payload: {
            oldId: slide.id,
            slide: newSlide,
          },
        });
      });
  }

  return patch(`${process.env.BASE_API_URL}${slide.self}`, serializeSlide__patch(slide))
    .then((newSlide) => newSlide.json())
    .then((newSlide) => {
      dispatch({
        type: GET_SLIDE__SUCCESS,
        payload: {
          slide: newSlide,
        },
      });
    });
};

const saveSlide__images = (slide, courseId) => (dispatch) => {
  if (slide.isNew) {
    return dispatch(postSlidePhoto(slide, courseId));
  }

  return dispatch(patchSlidePhoto(slide, courseId));
};

const postSlidePhoto = (slide, courseId) => (dispatch) =>
  postCourseSlide({ ...slide, course: courseId })
    .then(async (newSlide) => {
      await savePhotosToImageGallery(newSlide, slide.photos);

      return newSlide;
    })
    .then((newSlide) => {
      history.push(`${getPathname(newSlide.id)}`);
      dispatch({
        type: slidesTypes.CREATE_SLIDE__SUCCESS,
        payload: {
          oldId: slide.id,
          slide: newSlide,
        },
      });
    });

const patchSlidePhoto = (slide, courseId) => (dispatch, getState) =>
  Promise.all(getState().slide.item.items.items.filter(
    ({ id }) => !slide.photos.find((photo) => photo.id === id),
  ).map((photo) => deleteCourseImageGallery(photo.id)))
    .then(() => savePhotosToImageGallery(slide, slide.photos))
    .then((newPhotos) => postReorderPhotos(serializeReorderPhoto(slide, newPhotos)))
    .then(() => patch(
      `${process.env.BASE_API_URL}${slide.self}`,
      serializeSlide__patch(slide),
    ))
    .then((newSlide) => newSlide.json())
    .then((newSlide) => {
      dispatch({
        type: GET_SLIDE__SUCCESS,
        payload: {
          slide: newSlide,
        },
      });
    });


const savePhotosToImageGallery = (slide, photos) => Promise.all(
  photos.map((photo) => {
    if (photo.image) return Promise.resolve(photo);

    return postStaticFile({
      file: photo.file,
      type: 'course_slide_image_gallery_item',
    });
  }),
).then(async (staticFiles) => {
  const imageGalleryPhotos = [];
  for (const file of staticFiles) {
    if (file.image) imageGalleryPhotos.push(file);
    else {
      const photo = await postCourseImageGallery({
        description: '',
        image: file.self,
        slide: slide.self,
        title: 'Галерея',
      })
      imageGalleryPhotos.push(photo);
    }
  }

  return imageGalleryPhotos;
})

const saveSlide__test = (slide, courseId) => (dispatch) => {
  if (slide.isNew) {
    return dispatch(saveSlide__test__post(slide, courseId));
  }

  return dispatch(saveSlide__test__patch(slide, courseId));
};

// test slides
const saveSlide__test__patch = (slide, courseId) => (dispatch) =>
  Promise.all(slide.questions.filter(
    ({ isDelete }) => isDelete,
  ).map((question) => deleteQuestion(question.id)))
    .then(() => dispatch(saveQuestions(slide, courseId, slide.questions)))
    .then(() => patch(
      `${process.env.BASE_API_URL}${slide.self}`,
      serializeSlide__patch(slide),
    ))
    .then((newSlide) => newSlide.json())
    .then((newSlide) => {
      dispatch({
        type: GET_SLIDE__SUCCESS,
        payload: {
          slide: newSlide,
        },
      });
    });


const saveSlide__test__post = (slide, courseId) => (dispatch) =>
  postCourseSlide({ ...slide, course: courseId })
    .then(async (newSlide) => {
      await dispatch(saveQuestions(newSlide, courseId, slide.questions));

      return newSlide;
    })
    .then((newSlide) => {
      history.push(`${getPathname(newSlide.id)}`);
      dispatch({
        type: slidesTypes.CREATE_SLIDE__SUCCESS,
        payload: {
          oldId: slide.id,
          slide: newSlide,
        },
      });
    });

const saveQuestions = (slide, courseId, questions) => (dispatch) => Promise.all(
  questions
    .filter(({ isDelete }) => !isDelete)
    .map((question) => (question.isNew
      ? dispatch(saveQuestion__post(question, slide, courseId))
      : dispatch(saveQuestion__patch(question, slide, courseId)))),
).then((savedQuestions) => postReorderQuestions(serializeReorderQuestions(slide, savedQuestions)));

// questions
const saveQuestion__post = (question, slide, courseId) => (
  dispatch,
  getState,
) => post(
  `${process.env.BASE_API_URL}/v1/course-test-questions`,
  serializeQuestion__post({ ...question, slide }),
).then(async (__question) => {
  await saveAnswers(slide, courseId, { ...__question, ...question });

  return __question;
});

const saveQuestion__patch = (question, slide, courseId) => (
  dispatch,
  getState,
) => patch(
  `${process.env.BASE_API_URL}${question.self}`,
  serializeQuestion__patch(question),
)
  .then(async () => {
    const currentQuestion = getState().slide.item.questions.items.find(
      ({ id }) => question.id === id,
    );
    if (question.type === 'matching') {
      const deletedAnswers = currentQuestion.matchingPairs.items.filter(
        ({ id }) => !question.matchingPairs.items.find((answer) => answer.id === id),
      );
      for (const answer of deletedAnswers) await deleteAnswerMatching(answer.id);
    } else {
      const deletedAnswers = currentQuestion.answers.items.filter(
        ({ id }) => !question.answers.items.find((answer) => answer.id === id),
      );
      for (const answer of deletedAnswers) await deleteAnswer(answer.id);
    }
  })
  .then(async (_) => {
    await saveAnswers(slide, courseId, question);

    return question;
  });

const saveAnswers = (slide, courseId, question) => {
  if (question.type === 'matching') {
    return Promise.all(
      question.matchingPairs.items.map((pair) => (pair.isNew
        ? saveAnswer__pair_post(pair, question, slide, courseId)
        : saveAnswer__pair_patch(pair, question, slide, courseId))),
    );
  } if (question.type === 'image') {
    return Promise.all(
      question.answers.items.map((answer) => (answer.isNew
        ? saveAnswer__image_post(answer, question, slide, courseId)
        : saveAnswer__image_patch(answer, question, slide, courseId))),
    );
  }

  return Promise.all(
    question.answers.items.map((answer) => (answer.isNew
      ? saveAnswer__text_post(answer, question, slide, courseId)
      : saveAnswer__text_patch(answer, question, slide, courseId))),
  );
};

// answers
const saveAnswer__text_post = (answer, question, slide, courseId) => post(
  `${process.env.BASE_API_URL}/v1/course-test-answers`,
  serializeAnswer__post({ ...answer }, question),
);

const saveAnswer__image_post = (answer, question, slide, courseId) => postStaticFile({ file: answer.image, type: 'answer_image' }).then((_image) => post(
  `${process.env.BASE_API_URL}/v1/course-test-answers`,
  serializeAnswer__post({ ...answer, image: _image }, question),
));

const saveAnswer__pair_post = (answer, question, slide, courseId) => post(`${process.env.BASE_API_URL}/v1/matching-pairs`, {
  leftOption: { text: answer.leftOption },
  rightOption: { text: answer.rightOption },
  question: question.self,
});

const saveAnswer__pair_patch = (answer, question, slide, courseId) => patch(`${process.env.BASE_API_URL}${answer.self}`, {
  leftOption: { text: answer.leftOption.text || answer.leftOption },
  rightOption: { text: answer.rightOption.text || answer.rightOption },
});

const saveAnswer__text_patch = (answer, question, slide, courseId) => patch(
  `${process.env.BASE_API_URL}${answer.self}`,
  serializeAnswer__patch({ ...answer }),
);

const saveAnswer__image_patch = (answer, question, slide, courseId) => patch(
  `${process.env.BASE_API_URL}${answer.self}`,
  serializeAnswer__patch({ ...answer }),
);

const postStaticFile = (data) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, data);

const postCourseSlide = (data) => post(
  `${process.env.BASE_API_URL}/v1/course-slides`,
  serializeSlide__post(data),
);

const postCourseImageGallery = (data) => post(`${process.env.BASE_API_URL}/v1/course-image-gallery-items`, data);

const postReorderPhotos = (data) => post(
  `${process.env.BASE_API_URL}/v1/course-image-gallery-item-reorder-requests`,
  data,
);

const postReorderQuestions = (data) => post(
  `${process.env.BASE_API_URL}/v1/course-test-question-reorder-requests`,
  data,
);

const deleteCourseImageGallery = (id) => deleteAPI(`${process.env.BASE_API_URL}/v1/course-image-gallery-items/${id}`);

const deleteQuestion = (id) => deleteAPI(`${process.env.BASE_API_URL}/v1/course-test-questions/${id}`);

const deleteAnswer = (id) => deleteAPI(`${process.env.BASE_API_URL}/v1/course-test-answers/${id}`);

const deleteAnswerMatching = (id) => deleteAPI(`${process.env.BASE_API_URL}/v1/matching-pairs/${id}`);
