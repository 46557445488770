import React, { useState } from 'react';
import './Result.scss';
import { Line } from 'react-chartjs-2';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MUIDataTable from 'mui-datatables';
import Card from '@material-ui/core/Card';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { toInputFormat } from '../constants/date';
import { courseStatuses, statisticConstants } from '../constants';
// import tz from "moment-timezone"

const countDataPerDay = (item) => (accumulator, currentValue) => {
  const currentDate = moment(currentValue.completedAt).format('DD-MM-YYYY');

  const now = new Date();
  const modyfied = moment(item, 'DD-MM-YYYY').format('MM-DD-YYYY');

  if (new Date(modyfied) <= now) {
    if (currentDate === item) {
      if (currentValue.status === courseStatuses.USED_WITH_FAILURE) {
        return {
          ...accumulator,
          negScore: accumulator.negScore + 1,
          date: item,
        };
      }

      if (currentValue.status === courseStatuses.USED_WITH_SUCCESS) {
        return {
          ...accumulator,
          posScore: accumulator.posScore + 1,
          date: item,
        };
      }
    }

    return {
      posScore: accumulator.posScore,
      negScore: accumulator.negScore,
    };
  }

  return {
    posScore: null,
    negScore: null,
  };
};

function getDates(startDate, stop) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(stop);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    currentDate = moment(currentDate, 'YYYY-MM-DD').add(1, 'days');
  }

  return dateArray;
}

const sameDatasets = {
  fill: true,
  stepSize: 1,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
}

function Result({
  courseTitle,
  availability,
  stats,
}) {
  const { till, from, status } = availability;
  const mapped = stats.map((stat) => ({
    ...stat,
    medicalInstitutionTitle: stat.medicalInstitutionTitle || '---',
    medicalInstitutionAddress: stat.medicalInstitutionAddress || '---',
    status: stat.status || '---',
    completedAt: stat.completedAt || null,
  }));

  const statsWithoutIgnored = mapped.filter(
    (item) => !item.shouldResultsBeIgnored,
  );
  const handleChange = ({ target: { value } }, func) => func(value);
  const [discreteType, handleChangeDiscreteType] = useState('days');
  const [startDate, handleChangeStartDate] = useState(moment(from).format('YYYY-MM-DD'));
  const [endDate, handleChangeEndDate] = useState(
    moment(status === courseStatuses.availabilityStatuses.FINISHED ? till : new Date())
      .format('YYYY-MM-DD'),
  );
  const getSlice = getDates(startDate, endDate);
  const chartDataset = getSlice.map((date) => statsWithoutIgnored.reduce(countDataPerDay(date), {
    posScore: 0,
    negScore: 0,
    date: null,
  }));
  const datasets = [
    {
      label: 'Пройдено',
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: chartDataset.map((item) => item.posScore),
      ...sameDatasets,
    },
    {
      label: 'Не пройдено',
      backgroundColor: 'rgba(192,40,53,1)',
      borderColor: 'rgb(192,91,97)',
      pointBorderColor: 'rgba(192,40,53,1)',
      pointBackgroundColor: '#fff',
      pointHoverBackgroundColor: 'rgb(192,40,53)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: chartDataset.map((item) => item.negScore),
      ...sameDatasets,
    },
  ];
  const data = {
    labels: getSlice.map((date) => date
      .split('-')
      .splice(0, 2)
      .join('-')),
    datasets,
  };
  const getCountResultsOfStatus = (searchingStatus) => statsWithoutIgnored.filter(
    (stat) => stat.status === searchingStatus,
  ).length;

  return (
    <div style={{ width: '90%' }}>
      <div style={{ margin: 20 }}>
        <h5>
          Курс: {courseTitle}
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Card style={{ margin: '20px', padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <h5>Общее: &#8194;</h5>
            <h5>{statsWithoutIgnored.length}</h5>
          </div>
        </Card>
        <Card style={{ margin: '20px', padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <h5>Прошли: &#8194;</h5>
            <h5>
              {getCountResultsOfStatus(courseStatuses.USED_WITH_SUCCESS)}
            </h5>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h5>Не прошли: &#8194; </h5>
            <h5>
              {getCountResultsOfStatus(courseStatuses.USED_WITH_FAILURE)}
            </h5>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h5>В процессе: &#8194; </h5>
            <h5>
              {getCountResultsOfStatus(courseStatuses.IN_USE)}
            </h5>
          </div>
        </Card>
        <Card style={{ margin: '20px', padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <h5>Не начинали: &#8194;</h5>
            <h5>{getCountResultsOfStatus(courseStatuses.NOT_USED)}</h5>
          </div>
        </Card>
      </div>
      <Paper style={{ padding: '20px', margin: '20px' }}>
        <TextField
          id="date"
          label="Начальная дата"
          type="date"
          onChange={(e) => handleChange(e, handleChangeStartDate)}
          value={startDate}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: toInputFormat(from),
            max: toInputFormat(till),
          }}
          style={{ margin: '0 20px' }}
        />
        <TextField
          id="date"
          label="Конечная дата"
          type="date"
          onChange={(e) => handleChange(e, handleChangeEndDate)}
          value={endDate}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: toInputFormat(from),
            max: toInputFormat(till),
          }}
          style={{ margin: '0 20px' }}
        />
        <FormControl disabled style={{ margin: '0 20px' }}>
          <InputLabel>Дискретность</InputLabel>
          <Select value={discreteType}>
            <MenuItem value="days">Дни</MenuItem>
            <MenuItem value="weeks">Месяцы</MenuItem>
            <MenuItem value="years">Годы</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Card style={{ margin: '20px' }}>
        <div style={{ padding: '20px' }}>
          <Line
            data={data}
            width="100%"
            height={500}
            options={statisticConstants.optionsGraph}
          />
        </div>
      </Card>

      <div style={{ margin: '20px' }}>
        <MUIDataTable
          title="Статистика прохождения"
          data={statsWithoutIgnored}
          columns={statisticConstants.columnsStat}
          options={statisticConstants.options}
        />
      </div>
    </div>
  );
}

export default Result;
