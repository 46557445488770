import React from 'react';
import TokenError from './TokenError';

function BlockUserError() {
  return (
    <TokenError text="Ваш аккаунт был заблокирован, обратитесь к администратору для дополнительной информации." />
  );
}

export default BlockUserError;
