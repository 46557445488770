import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { DebounceInput } from 'react-debounce-input';

import './style.scss';

class DebounceSearchInput extends Component {
    handleChange = ({ target: { value } }) => {
      this.props.onChange(value);
    }

    render() {
      const {
        icon, time, placeholder, onFocus, value,
      } = this.props;

      return (
        <div className="search">
          <div className="searchIcon">
            {icon || <SearchIcon />}
          </div>
          <DebounceInput
            value={value || ''}
            placeholder={placeholder || 'Поиск'}
            onChange={this.handleChange}
            debounceTimeout={time || 500}
            className="inputInput"
            onFocus={onFocus}
            type="search"
          />
        </div>
      );
    }
}

// const container = connect(state2props, dispatch2props)(SearchInput)

export default DebounceSearchInput;
