import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaQuery from 'react-responsive';
import * as Sentry from '@sentry/browser';
import { Typography, Button } from '@material-ui/core';
import { authActions, getRoot, getCurrentUser } from '../actions';
import { Root } from '../pages';
import { Root as Mobile } from '../pages/mobile';
import { Loader } from '../component';


import '../styles/root.scss';
import Alert from '../component/alert/Alert';
import InviteError from '../component/error/InviteError';
import { getTickets } from '../actions/tickets';
import { POLLING_DURATION_LONG } from '../constants/tickets';

class RootContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  componentDidMount = () => {
    this.props.getRoot();
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    const { meta } = this.props;
    const { eventId } = this.state;
    if (eventId) {
      return <Button onClick={() => Sentry.showReportDialog({ eventId })}>Report feedback</Button>;
    }

    return (
      <>
        {meta.isInviteError
          ? <InviteError />
          : meta.isRequesting || meta.isUserRequesting
            ? <Loader />
          // : <Root {...this.props} />
            : (
              <>
                <MediaQuery query="(min-device-width: 1024px)">
                  <Root {...this.props} duration={POLLING_DURATION_LONG} enablePolling={!sessionStorage.is_anon} />
                </MediaQuery>
                <MediaQuery query="(max-device-width: 1023px)"><Mobile {...this.props} /></MediaQuery>
              </>
            )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  meta: state.meta,
  user: state.meta.user,
});
const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
  getRoot: bindActionCreators(getRoot, dispatch),
  pollingAction: () => dispatch(getTickets({ isHiddenLoader: true })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RootContainer);
