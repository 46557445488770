import React from 'react';
import { connect } from 'react-redux';
import { Support } from '../pages';
import { Loader } from '../component';
import { createTicket, getTickets } from '../actions/tickets';

function SupportContainer(props) {
  const { isRequesting } = props;

  return isRequesting ? <Loader /> : <Support {...props} />;
}

const mapStateToProps = (state) => ({
  tickets: state.tickets,
  isRequesting: state.auth.isRequesting,
  meta: state.meta,
});
const mapDispatchToProps = {
  getTickets,
  createTicket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportContainer);
