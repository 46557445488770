import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import {
  Audio, Pdf, Photo, Test, Text, Video, Dialog,
} from './constructors';
import { Loader } from '../../component';
import SaveSlidePrompt from '../../component/prompt/SaveSlidePrompt';

@withRouter
class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidSlide: false,
      isInvalidInput: false,
    };
  }

  handleEdit = (slide) => {
    const { slidesActions: { editSlide } } = this.props;
    editSlide(slide);
  }

  handleSaveSlide = () => {
    const { saveSlidesActions: { saveSlide }, slide: { modified } } = this.props;
    saveSlide(modified);
  }

  handleSaveAll = () => {
    const { slidesActions: { saveAllSlides } } = this.props;
    saveAllSlides();
  }

  setValidSlide = (isValid) => this.setState(() => ({ isValidSlide: isValid }));

  openSaveSlidePrompt = () => {
    this.setState((state) => ({ isSaveSlidePromptOpen: !state.isSaveSlidePromptOpen }));
  }

  highlightInvalidInput = () => {
    this.setState(() => ({ isInvalidInput: true }));
    setTimeout(() => { this.setState(() => ({ isInvalidInput: false })); }, 1000);
  }

  renderSlide() {
    const { slide: { item: { type } }, questionActions } = this.props;

    switch (type) {
      case 'text': return (
        <Text
          {...this.props}
          isInvalidInput={this.state.isInvalidInput}
          setValidSlide={this.setValidSlide}
          openSaveSlidePrompt={this.openSaveSlidePrompt}
          onEdit={this.handleEdit}
        />
      );
      case 'test': return (
        <Test
          {...this.props}
          isInvalidInput={this.state.isInvalidInput}
          setValidSlide={this.setValidSlide}
          onEdit={this.handleEdit}
          questionActions={questionActions}
        />
      );
      case 'pdf': return <Pdf {...this.props} onEdit={this.handleEdit} />;
      case 'audio': return <Audio {...this.props} onEdit={this.handleEdit} />;
      case 'youtube_video': return (
        <Video
          {...this.props}
          isInvalidInput={this.state.isInvalidInput}
          setValidSlide={this.setValidSlide}
          onEdit={this.handleEdit}
        />
      );
      case 'image_gallery': return (
        <Photo
          {...this.props}
          isInvalidInput={this.state.isInvalidInput}
          setValidSlide={this.setValidSlide}
          onEdit={this.handleEdit}
        />
      );
      case 'poll': return (
        <Dialog
          {...this.props}
          isInvalidInput={this.state.isInvalidInput}
          setValidSlide={this.setValidSlide}
          onEdit={this.handleEdit}
        />
      );
      default: return null;
    }
  }

  handleSaveBlock = () => {
    if (this.state.isValidSlide) {
      this.handleSaveSlide();
    } else {
      this.highlightInvalidInput();
    }
  }

  render() {
    const { slide: { isRequesting, isRequestingSave } } = this.props;
    const { isSaveSlidePromptOpen } = this.state;

    return (
      <>
        { isRequesting || isRequestingSave ? <Loader /> : this.renderSlide() }
        {isSaveSlidePromptOpen && (
          <SaveSlidePrompt />
        )}
        <div className={`actionFooter ${isRequesting ? 'loading' : ''}`}>
          <Button onClick={this.handleSaveBlock}>
            Сохранить блок
          </Button>
        </div>
      </>
    );
  }
}

export default Slide;
