import { editorTypes } from '../types';

const initialState = {
  items: [],
  isRequesting: true,
  isError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case editorTypes.CREATE_LINKS__REQUEST:
    case editorTypes.GET_INVITATIONS__REQUEST: return {
      ...state,
      isRequesting: true,
      isError: false,
    };
    case editorTypes.GET_INVITATIONS__SUCCESS: return {
      isRequesting: false,
      isError: false,
      items: action.payload.items,
    };
    default: return state;
  }
};
