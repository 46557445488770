export const GET_TICKETS__REQUEST = 'TICKET_TYPE__GET_TICKETS__REQUEST';
export const GET_TICKETS__SUCCESS = 'TICKET_TYPE__GET_TICKETS__SUCCESS';
export const GET_TICKETS__FAILURE = 'TICKET_TYPE__GET_TICKETS__FAILURE';

export const GET_TICKET__REQUEST = 'TICKET_TYPE__GET_TICKET__REQUEST';
export const GET_TICKET__SUCCESS = 'TICKET_TYPE__GET_TICKET__SUCCESS';
export const GET_TICKET__FAILURE = 'TICKET_TYPE__GET_TICKET__FAILURE';

export const CREATE_TICKET__REQUEST = 'TICKET_TYPE__CREATE_TICKET__REQUEST';
export const CREATE_TICKET__SUCCESS = 'TICKET_TYPE__CREATE_TICKET__SUCCESS';
export const CREATE_TICKET__FAILURE = 'TICKET_TYPE__CREATE_TICKET__FAILURE';

export const CHANGE_TICKET_STATUS__REQUEST = 'TICKET_TYPE__CHANGE_TICKET_STATUS__REQUEST';
export const CHANGE_TICKET_STATUS__SUCCESS = 'TICKET_TYPE__CHANGE_TICKET_STATUS__SUCCESS';
export const CHANGE_TICKET_STATUS__FAILURE = 'TICKET_TYPE__CHANGE_TICKET_STATUS__FAILURE';

export const CREATE_TICKET_COMMENT = 'CREATE_TICKET_COMMENT';
export const CREATE_TICKET_COMMENT_SUCCESS = 'CREATE_TICKET_COMMENT_SUCCESS';
export const CREATE_TICKET_COMMENT_FAILURE = 'CREATE_TICKET_COMMENT_FAILURE';
