import { connect } from 'react-redux';
import { CourseLinkAccess } from '../pages/editor/';
import { getInvitations, createLinks } from '../actions/editor';
import { getPharms } from '../actions/slides';
import { openDeleteLinkPrompt, closeDeleteLinkPrompt } from '../component/prompt/actions';
import {bindActionCreators} from 'redux';
import {openAlert} from '../component/alert/actions';


const mapStateToProps = (state) => ({
  pharms: state.pharms,
  invitations: state.invitations,
  editor: state.editor,
});

const mapDispatchToProps = {
  getPharms,
  getInvitations,
  createLinks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseLinkAccess);
