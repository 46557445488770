import React, { Component, Fragment } from 'react';
import { Slider } from '@material-ui/lab';
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { diff2name, theme2name } from '../../utils/translate';
import { Loader } from '../../component';
import ManufacturerInput from '../../component/autocomplete/ManufacturerInput';

const themes = ['otc', 'rx', 'mixed', 'general_medical'];
const diffics = ['easy', 'medium', 'hard'];

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      certificate: null,
      theme: null,
      rewardPoints: 0,
      recommendedNumberOfAttempts: 0,
      difficulty: null,
      availability: { from: null, till: null },
      cover: null,
      manufacturer: {},
      allowTimeOuts: 1,
      isInvalid: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.id !== prevState.id) {
      return {
        ...nextProps.editor.course,
      };
    }
  }

  componentDidMount = () => {
    this.setState({
      ...this.props.editor.course,
    });
    if (this.state.recommendedNumberOfAttempts === 0) {
      this.setState(() => ({ recommendedNumberOfAttempts: 8 }));
    }
    if (this.props.isNew) {
      const today = new Date();
      const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
      this.setState(() => ({ availability: { from: today, till: tomorrow } }));
    }
  }

  validateCourseFields = () => {
    const {
      title,
      description,
      theme,
      difficulty,
      cover,
    } = this.state;

    return (
      title !== ''
      && description !== ''
      && theme
      && difficulty
      && this.isValidDateFields()
      && cover

    );
  }

  isValidDateFields = () => {
    const { availability } = this.state;

    return new Date(availability.till) - new Date(availability.from) > 0;
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  }

  handleChangeCertificate = ({ target: { files } }) => {
    if (files[0] === undefined) return;
    this.handleChange('certificate', files[0]);
  }

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState({
      [name]: checked ? 1 : 0,
    });
  };

  handleCreateCourse = () => this.props.editorActions.createCourse({ ...this.state, rewardPoints: new Number(this.state.rewardPoints) })

  handleUpdateCourse = () => this.props.editorActions.updateCourse(this.state)

  loadPreview = () => this.state.cover.url || ((this.state.cover !== '' && this.state.cover != undefined) ? URL.createObjectURL(this.state.cover) : '')

  highlightInvalidInput = () => {
    this.setState(() => ({ isInvalid: true }));
    setTimeout(() => { this.setState(() => ({ isInvalid: false })); }, 1000);
  }

  render() {
    const { editor: { isRequestingSave }, isNew } = this.props;
    const {
      availability: { from, till },
      title,
      description,
      isInvalid,
      theme,
      recommendedNumberOfAttempts,
      difficulty,
      cover,
    } = this.state;

    return isRequestingSave
      ? <Loader />
      : (
        <>
          <div className="constructor_info__wrapper">
            <div className="infoSetting">
              <TextField
                id="standard-select-currency"
                margin="normal"
                variant="outlined"
                select
                label="Выберите тему"
                name="theme"
                value={theme || ''}
                error={theme ? false : isInvalid}
                onChange={(e) => this.handleChange('theme', e.target.value)}
                InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
              >
                {themes.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {theme2name(d)}
                  </MenuItem>
                ))}
              </TextField>
              <div className="durationInput">
                <TextField
                  fullWidth
                  id="date"
                  label="начало"
                  type="date"
                  name="from"
                  margin="normal"
                  variant="outlined"
                  error={!this.isValidDateFields()}
                  value={new Date(from).toISOString().split('T')[0]}
                  onChange={(e) => this.handleChange('availability', { from: e.target.value, till })}
                  InputLabelProps={{
                    shrink: true,
                    classes: { focused: 'labelFocusedColor' },
                  }}
                />
                <TextField
                  fullWidth
                  id="date"
                  label="окончание"
                  type="date"
                  name="till"
                  margin="normal"
                  variant="outlined"
                  error={!this.isValidDateFields()}
                  value={new Date(till).toISOString().split('T')[0]}
                  onChange={(e) => this.handleChange('availability', { from, till: e.target.value })}
                  InputLabelProps={{
                    shrink: true,
                    classes: { focused: 'labelFocusedColor' },
                  }}
                />
              </div>
              <TextField
                id="standard-select-currency"
                margin="normal"
                variant="outlined"
                select
                label="Выберите сложность"
                name="difficulty"
                value={difficulty || ''}
                error={difficulty ? false : isInvalid}
                onChange={({ target: { value } }) => this.handleChange('difficulty', value)}
                InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
              >
                {diffics.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {diff2name(d)}
                  </MenuItem>
                ))}
              </TextField>
              <FormControl>
                <InputLabel htmlFor="component-simple">Количество баллов за курс</InputLabel>
                <Input name="rewardPoints" value={this.state.rewardPoints} onChange={(e) => this.handleChange('rewardPoints', e.target.value)} />
              </FormControl>
              <ManufacturerInput
                value={this.state.manufacturer && this.state.manufacturer.title}
                handleChange={(value) => this.handleChange('manufacturer', value)}
              />
              {
              isNew
              && (
              <>
                <Typography id="slider-image">
                  {`Рекомендованое кол-во попыток: ${
                    recommendedNumberOfAttempts
                  }`}
                </Typography>
                <Slider
                  classes={{ container: 'slider' }}
                  name="time"
                  onChange={(_, value) => this.handleChange('recommendedNumberOfAttempts', value)}
                  value={recommendedNumberOfAttempts}
                  step={1}
                  min={1}
                  max={15}
                />
                <div className="certificate">
                  <div className="pdfInput__wrapper">
                    <input
                      className="pdfInput"
                      type="file"
                      name="certificate"
                      id="certificateInput"
                      accept="image/*"
                      onChange={this.handleChangeCertificate}
                    />
                    <label className="emptyField" htmlFor="certificateInput">+</label>
                  </div>
                  <Typography>
                    {this.state.certificate === null
                      ? 'Прикрепить изображение для сертификата'
                      : this.state.certificate.name}
                  </Typography>
                </div>

              </>
              )
            }
              <FormControlLabel
                control={(
                  <Checkbox
                    name="allowTimeOuts"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.allowTimeOuts}
                  />
              )}
                label="Отключить таймер при прохождении курса"
                style={{ marginRight: 0 }}
              />
            </div>
            <div className="mainField">
              <div className="courseDescription">
                <h4>Информация о курсе</h4>
                <div className="input__wrapper">
                  <div className="photoInput__wrapper">
                    {
                    cover === '' || cover == undefined
                      ? (
                        <>
                          <input
                            className="photoInput"
                            type="file"
                            name="cover"
                            onChange={(e) => this.handleChange('cover', e.target.files[0])}
                            accept="image/*"
                            id="file"
                          />
                          <label className={`emptyField + ${isInvalid ? 'errorPhotoInput' : ''}`} htmlFor="file">+</label>
                        </>
                      )
                      : (
                        <>
                          <input
                            className="photoInput"
                            type="file"
                            name="cover"
                            onChange={(e) => this.handleChange('cover', e.target.files[0])}
                            accept="image/*"
                            id="file"
                          />
                          <label htmlFor="file">
                            <img htmlFor="file" src={this.loadPreview()} />
                          </label>
                        </>
                      )
                  }
                  </div>
                  <div className="description__wrapper">
                    <FormControl classes={{ root: 'wideInput' }}>
                      <InputLabel htmlFor="component-simple" classes={{ focused: 'focusedTextColor' }}>Название курса</InputLabel>
                      <Input
                        name="title"
                        value={title}
                        error={title ? false : isInvalid}
                        onChange={(e) => this.handleChange('title', e.target.value)}
                        classes={{ focused: 'focusedBottomBorder' }}
                        multiline
                      />
                    </FormControl>
                    <br />
                    <FormControl classes={{ root: 'wideInput' }}>
                      <InputLabel
                        htmlFor="component-simple"
                        classes={{ focused: 'focusedTextColor' }}
                      >
                        Описание
                      </InputLabel>
                      <Input
                        name="description"
                        value={description}
                        error={description ? false : isInvalid}
                        onChange={(e) => this.handleChange('description', e.target.value)}
                        classes={{ focused: 'focusedBottomBorder' }}
                        multiline
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="actionFooter">
                <div className="leftBlock">
                  { isRequestingSave
                    ? <Loader small />
                    : (
                      <Button
                        onClick={() => {
                          this.validateCourseFields()
                            ? isNew
                              ? this.handleCreateCourse()
                              : this.handleUpdateCourse()
                            : this.highlightInvalidInput();
                        }}
                      >
                        СОХРАНИТЬ
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
}

export default Info;
