export const CREATE_ACCESS__REQUEST = 'EDITOR_TYPE__CREATE_ACCESS__REQUEST';
export const CREATE_ACCESS__SUCCESS = 'EDITOR_TYPE__CREATE_ACCESS__SUCCESS';
export const CREATE_ACCESS__FAILURE = 'EDITOR_TYPE__CREATE_ACCESS__FAILURE';
export const GET_USERS__REQUEST = 'EDITOR_TYPE__GET_USERS__REQUEST';
export const GET_USERS__SUCCESS = 'EDITOR_TYPE__GET_USERS__SUCCESS';
export const GET_USERS__FAILURE = 'EDITOR_TYPE__GET_USERS__FAILURE';
export const EDIT_COURSE = 'EDITOR_TYPE__EDIT_COURSE';
export const GET_INVITATIONS__REQUEST = 'EDITOR_TYPE__GET_INVITATIONS__REQUEST';
export const GET_INVITATIONS__SUCCESS = 'EDITOR_TYPE__GET_INVITATIONS__SUCCESS';
export const GET_INVITATIONS__FAILURE = 'EDITOR_TYPE__GET_INVITATIONS__FAILURE';
export const CREATE_LINKS__REQUEST = 'EDITOR_TYPE__CREATE_LINKS__REQUEST';
export const CREATE_LINKS__SUCCESS = 'EDITOR_TYPE__CREATE_LINKS__SUCCESS';
export const CREATE_LINKS__FAILURE = 'EDITOR_TYPE__CREATE_LINKS__FAILURE';
export const GET_COURSE__REQUEST = 'EDITOR_TYPE__GET_COURSE__REQUEST';
export const GET_COURSE__SUCCESS = 'EDITOR_TYPE__GET_COURSE__SUCCESS';
export const GET_COURSE__FAILURE = 'EDITOR_TYPE__GET_COURSE__FAILURE';
export const CHANGE_FIELD = 'EDITOR_TYPE__CHANGE_FIELD';
export const CREATE_COURSE__REQUEST = 'EDITOR_TYPE__CREATE_COURSE__REQUEST';
export const CREATE_COURSE__SUCCESS = 'EDITOR_TYPE__CREATE_COURSE__SUCCESS';
export const CREATE_COURSE__FAILURE = 'EDITOR_TYPE__CREATE_COURSE__FAILURE';
export const UPDATE_COURSE__REQUEST = 'EDITOR_TYPE__UPDATE_COURSE__REQUEST';
export const UPDATE_COURSE__SUCCESS = 'EDITOR_TYPE__UPDATE_COURSE__SUCCESS';
export const UPDATE_COURSE__FAILURE = 'EDITOR_TYPE__UPDATE_COURSE__FAILURE';

export const DELETE_COURSE__REQUEST = 'EDITOR_TYPE__DELETE_COURSE__REQUEST';
export const DELETE_COURSE__SUCCESS = 'EDITOR_TYPE__DELETE_COURSE__SUCCESS';
export const DELETE_COURSE__FAILURE = 'EDITOR_TYPE__DELETE_COURSE__FAILURE';

export const ADD_NEW = 'EDITOR_TYPE__ADD_NEW';
export const INIT_SLIDE = 'EDITOR_TYPE__INIT_SLIDE';
