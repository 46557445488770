import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Results } from '../pages';
import { slidesActions } from '../actions';
import { Loader } from '../component';

class ResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      slidesActions: { getCourses },
      match: { isExact },
    } = this.props;
    isExact && getCourses();
  };

  render() {
    const { courses, isRequesting, match: { isExact } } = this.props;

    return isExact && <>{isRequesting ? <Loader /> : <Results {...this.props} />}</>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
});
const mapStateToProps = (state) => ({
  courses: state.courses.items,
  isRequesting: state.courses.isRequesting,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResultsContainer);
