import React, { Component, Fragment } from 'react';

class FillGaps extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="fillTheGap">
        <h6>3. Заполните пробелы</h6>
        <span className="fillTheGap__body">
          Lorem ipsum dolor sit amet,
          {' '}
          <input />
          {' '}
          adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud ullamco
          {' '}
          <input />
          {' '}
          nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in
          {' '}
          <input />
          {' '}
          velit esse cillum
          dolore eu fugiat nulla pariatur.
        </span>
      </div>
    );
  }
}

export default FillGaps;
