import React, { Component, Fragment } from 'react';
import { Typography, Button, Icon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ResizeObserver from 'resize-observer-polyfill';
import { conViewModes as modes } from '../constants';
import {
  WithAdmin,
  AllCoursesContainer,
  UserCoursesContainer,
} from '../containers';
import './Courses.scss';
import { diff2name, theme2name } from '../utils/translate';
import { ReactComponent as SearchManufacturer } from '../icons/svg/search_manufacturer.svg';
import { ReactComponent as Expand } from '../icons/svg/expand_more.svg';
import {
  Loader, Course, DebounceSearchInput, Pdf,
} from '../component';
import ManufacturerInput from '../component/autocomplete/ManufacturerInput';
import Filter from '../component/filters';

const resizeCourseCard = new ResizeObserver((entries) => {
  const container = document.querySelector('.container');
  const rowHeight = parseInt(
    window.getComputedStyle(container).getPropertyValue('grid-auto-rows'),
  );
  const rowGap = parseInt(
    window.getComputedStyle(container).getPropertyValue('grid-row-gap'),
  );
  for (const entry of entries) {
    const rowSpan = Math.ceil(
      (entry.contentRect.height + rowHeight) / (rowHeight + rowGap),
    );

    if (entry.target.offsetParent !== null) {
      entry.target.offsetParent.style.gridRowEnd = `span ${rowSpan}`;
      const buttonOffset = entry.target.offsetParent.offsetHeight - entry.contentRect.height - 2;
      entry.target.lastElementChild.style.top = `${buttonOffset}px`;
    }
  }
});

function resizeAllCourseCard() {
  const allItems = document.getElementsByClassName('course__content');
  for (let i = 0; i < allItems.length; i++) {
    resizeCourseCard.observe(allItems[i]);
  }
}

const oldCourse = ({
  access,
  count,
  difficulty,
  description,
  maximumTimeToComplete,
  id,
  isFinished,
  onClick,
  onEditClick,
  picture,
  rewardPoints,
  runPage,
  title,
  theme,
  allowTimeOuts,
}) => (
  <div
    className={`course${access ? ' accessed' : ''}${
      runPage ? ' course_started' : ''
    }`}
  >
    <img src={picture} alt={`Обложка курса ${title}`} />
    <div className="topRow">
      <div
        className={`rewardPoints${
          access.isSessionLimitReached ? ' complete' : ''
        }`}
      >
        <div className="symbol">
          {access.isSessionLimitReached ? '✔' : '☆'}
        </div>
        {access.isSessionLimitReached
          ? 'Курс пройден'
          : `${rewardPoints} баллов`}
      </div>
    </div>
    <Typography className="title">{title}</Typography>
    <Typography className="desc">{description}</Typography>
    <Typography className="desc">
      <span>Тематика: </span>
      <br />
      {theme2name(theme)}
    </Typography>
    <Typography className="desc">
      <span>Сложность: </span>
      <br />
      {diff2name(difficulty)}
    </Typography>
    {!onEditClick && access && (
    <Typography className="desc">
      <span>Кол-во попыток: </span>
      {access.remainingAttempts}
      /
      {access.maximumNumberOfAttempts}
    </Typography>
    )}
    {
      allowTimeOuts
        ? (
            <Typography className="count">
              <span>Время для прохождения: </span>
              <br />
              не установлено
            </Typography>
          )
        : (
          <Typography className="count">
            <span>Время для прохождения: </span>
            <br />
            {
              maximumTimeToComplete%60 !== 0
              ? `${Math.floor(maximumTimeToComplete/60)} мин ${maximumTimeToComplete%60} сек`
              : `${Math.floor(maximumTimeToComplete/60)} мин`
            }
          </Typography>
        )
    }
    <Typography className="count">
      <span>Блоков: </span>
      {count}
    </Typography>
    <div className={`buttonRow ${isFinished && 'finished'}`}>
      {!isFinished && !onEditClick && !access.isSessionLimitReached && (
      <Button
        onClick={access ? onClick : null}
        className="startCourseButton"
      >
        Начать
      </Button>
      )}
      {isFinished && (
      <Button
        disabled
        onClick={() => null}
        className="startCourseButton finished"
      >
        Пройден
      </Button>
      )}
      {!sessionStorage.is_anon && !onEditClick && !access && (
      <Button className="startCourseButton">Недоступен</Button>
      )}
      {onEditClick && (
      <Button onClick={() => onEditClick(id)} className="startCourseButton">
        Редактировать
      </Button>
      )}
    </div>
  </div>
);

const accessedFilters = [
  'in_use',
  'not_used',
  'used_with_success',
  'used_with_failure',
];
const allFilters = ['active', 'finished', 'not_started_yet'];
const filter2name = (filter) => {
  switch (filter) {
    case 'in_use':
      return 'Активные';
    case 'not_used':
      return 'Новые';
    case 'used_with_success':
      return 'Пройденные';
    case 'used_with_failure':
      return 'Проваленные';
    case 'active':
      return 'Aктивные';
    case 'finished':
      return 'Завершенные';
    case 'not_started_yet':
      return 'Не начавшиеся';
    default:
      return '';
  }
};

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: modes.EDIT,
      isAccessedVisible: true,
      isAllVisible: true,
      isAllFiltersVisible: false,
      isAccessedFiltersVisible: false,
      accessesFilter: {},
      accessesFilterTitle: '',
      allFilter: {},
    };
  }

  createCourse = () => {
    this.props.slidesActions.createCourse(this.props.history);
  };

  handleCourseClick = (id) => {
    const {
      slidesActions: { openCourse },
      courses: { items },
    } = this.props;
    this.props.history.push(`/run/${id}`);
    openCourse(items[items.map((item) => item.id).indexOf(id)]);
  };

  handleEditCourseClick = (id) => this.props.slidesActions.editCourse(id, this.props.history);

  handleCourseVisibleClick = (coursesType) => this.setState((state) => ({
    [coursesType === 'all' ? 'isAllVisible' : 'isAccessedVisible']: !state[
      coursesType === 'all' ? 'isAllVisible' : 'isAccessedVisible'
    ],
  }));

  handleFiltersVisibleClick = (filtersType) => this.setState((state) => ({
    [filtersType === 'all'
      ? 'isAllFiltersVisible'
      : 'isAccessedFiltersVisible']: !state[
      filtersType === 'all'
        ? 'isAllFiltersVisible'
        : 'isAccessedFiltersVisible'
    ],
  }));

  getAllCourses = () => {
    this.props.slidesActions.getCourses(this.state.allFilter);
  };

  getAccessCourses = () => {
    this.props.coursesActions.getCoursesForUser(this.state.accessesFilter);
  };

  handleChangeAccessFilterTitle = (value) => {
    this.setState({
      accessesFilterTitle: value,
    });
  };

  handleToggleAllFilter = (name, value) => {
    this.setState(
      ({ allFilter }) => {
        if (!value) {
          const oldAllFilter = { ...allFilter };
          delete oldAllFilter[name];

          return {
            allFilter: { ...oldAllFilter },
          };
        }

        return {
          allFilter: { ...allFilter, [name]: value },
        };
      },
      () => this.getAllCourses(),
    );
  };

  handleToggleAccessFilter = (name, value) => {
    this.setState(
      ({ accessesFilter }) => {
        if (!value) {
          const oldFilter = { ...accessesFilter };
          delete oldFilter[name];

          return {
            accessesFilter: { ...oldFilter },
          };
        }

        return {
          accessesFilter: { ...accessesFilter, [name]: value },
        };
      },
      () => this.getAccessCourses(),
    );
  };

  componentDidUpdate() {
    resizeAllCourseCard();
  }

  componentWillUnmount() {
    resizeCourseCard.disconnect();
  }

  render() {
    const {
      filters4accessed,
      isAccessedVisible,
      isAllVisible,
      isAccessedFiltersVisible,
      isAllFiltersVisible,
      accessesFilterTitle,
      accessesFilter,
      allFilter,
    } = this.state;
    return (
      <div className="courses">
        <section>
          <UserCoursesContainer
            filter={filters4accessed ? { status: filters4accessed } : null}
          >
            {({ courses }) => (
              <>
                <h1 className="course-info-header__title">
                  Ваши курсы (
                  {courses.itemsForUser.length}
                  )
                </h1>
                <form className="course-info-header">
                  <div className="course-info-header__searches">
                    <div className="course-info-header__search">
                      <DebounceSearchInput
                        value={accessesFilterTitle}
                        onChange={this.handleChangeAccessFilterTitle}
                        placeholder="Поиск курса"
                        time={1}
                      />
                    </div>
                    <div className="course-info-header__search course-info-header__search_fake" />
                  </div>
                  <div className="course-info-header__tail">
                    <Filter
                      isActive={accessesFilter.status}
                      toggleButton={() => this.handleFiltersVisibleClick('accessed')}
                      value={accessesFilter.status}
                      isOpen={isAccessedFiltersVisible}
                      onClick={(value) => this.handleToggleAccessFilter('status', value)}
                      values={accessedFilters.reduce(
                        (prev, cur) => ({ ...prev, [cur]: filter2name(cur) }),
                        {},
                      )}
                    />
                    <IconButton
                      onClick={() => this.handleCourseVisibleClick('accessed')}
                      className={`course-info-header__expand
                                ${
                                  isAccessedVisible
                                    ? 'course-info-header__expand_rotate'
                                    : ''
                                }`}
                    >
                      <Expand />
                    </IconButton>
                  </div>
                </form>
                {isAccessedVisible && (
                  <div className="container">
                    {courses.isRequestingForUser ? (
                      <Loader />
                    ) : (
                      courses.itemsForUser
                        .filter((_course) => _course.title
                          .toLowerCase()
                          .includes(accessesFilterTitle.toLowerCase()))
                        .map((_course, index) => (
                          <Course
                            withInfo
                            allCourse={_course}
                            user={this.props.meta.user}
                            key={index}
                            certificate={_course.certificate_background}
                            isFinished={
                              _course.courseResults
                              && _course.courseResults.type === 'successful'
                            }
                            access={_course.access}
                            title={_course.title}
                            theme={theme2name(_course.theme)}
                            difficulty={diff2name(_course.difficulty)}
                            status={_course.status}
                            rewardPoints={_course.rewardPoints}
                            recommendedNumberOfAttempts={
                              _course.recommendedNumberOfAttempts
                            }
                            picture={_course.cover.url}
                            description={_course.description}
                            count={_course.slidesAmount}
                            self={_course.self}
                            onClick={() => this.handleCourseClick(
                              _course.access.self.split('/').pop(),
                            )}
                            startButton
                            certificate={_course.certificate}
                          />
                        ))
                    )}
                  </div>
                )}
              </>
            )}
          </UserCoursesContainer>
        </section>
        <section>
          <WithAdmin>
            <AllCoursesContainer>
              {({ courses }) => (
                <>
                  <h1 className="course-info-header__title">
                    Все курсы (
                    {courses.items.length}
                    )
                  </h1>
                  <form className="course-info-header">
                    <div className="course-info-header__searches">
                      <div className="course-info-header__search">
                        <DebounceSearchInput
                          value={allFilter.title}
                          onChange={(value) => this.handleToggleAllFilter('title', value)}
                          placeholder="Поиск курса"
                        />
                      </div>
                      <div className="course-info-header__search">
                        <ManufacturerInput
                          value={
                            allFilter.manufacturer
                            && allFilter.manufacturer.title
                          }
                          icon={<SearchManufacturer />}
                          variant="search"
                          placeholder="Курсы по производителю"
                          handleChange={(value) => this.handleToggleAllFilter(
                            'manufacturer',
                            value && value.self,
                          )}
                          classNameSuggestion="autocomplete-manufacturer__suggestion"
                          classNameSuggestions="autocomplete-manufacturer__suggestions"
                        />
                      </div>
                    </div>
                    <div className="course-info-header__tail">
                      <Filter
                        isActive={allFilter.availabilityStatus}
                        toggleButton={() => this.handleFiltersVisibleClick('all')}
                        value={allFilter.availabilityStatus}
                        isOpen={isAllFiltersVisible}
                        onClick={(value) => this.handleToggleAllFilter(
                          'availabilityStatus',
                          value,
                        )}
                        values={allFilters.reduce(
                          (prev, cur) => ({ ...prev, [cur]: filter2name(cur) }),
                          {},
                        )}
                      />
                      <IconButton
                        onClick={() => this.handleCourseVisibleClick('all')}
                        className={`course-info-header__expand
                                ${
                                  isAllVisible
                                    ? 'course-info-header__expand_rotate'
                                    : ''
                                }`}
                      >
                        <Expand />
                      </IconButton>
                    </div>
                  </form>
                  {isAllVisible && (
                    <div className="container editable">
                      <a
                        className="course addCourse"
                        onClick={this.createCourse}
                      >
                        <div className="cardItems">
                          <Icon className="addIcon">+</Icon>
                          <p className="title">Создать курс</p>
                        </div>
                      </a>
                      {courses.isRequesting ? (
                        <Loader />
                      ) : (
                        courses.items.map((item, index) => (
                          <Course
                            key={index}
                            access={item.access}
                            rewardPoints={item.rewardPoints}
                            theme={theme2name(item.theme)}
                            title={item.title}
                            picture={item.cover.url}
                            description={item.description}
                            theme={item.theme}
                            id={item.id}
                            difficulty={diff2name(item.difficulty)}
                            status={item.status}
                            rewardPoints={item.rewardPoints}
                            recommendedNumberOfAttempts={
                              item.recommendedNumberOfAttempts
                            }
                            count={item.slidesAmount}
                            onClick={() => this.handleCourseClick(item.id)}
                            onEditClick={this.handleEditCourseClick}
                          />
                        ))
                      )}
                    </div>
                  )}
                </>
              )}
            </AllCoursesContainer>
          </WithAdmin>
        </section>
      </div>
    );
  }
}

export { Courses, oldCourse as Course };
