import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { linksActions, getRoot } from '../actions';
import { Loader } from '../component';
import InviteError from '../component/error/InviteError';

class InvitesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      type: '',
    };
  }

  componentDidMount = () => {
    const {
      linksActions: { createAnonInvite, createRegInvite },
      getRoot,
    } = this.props;
    const params = ((obj) => this.props.location.search
      .split('')
      .filter((_, index) => index > 0)
      .join('')
      .split('&')
      .map((str) => {
        const mapStr = str.split('=');
        obj[mapStr[0]] = mapStr[1];
      }) && obj)({});

    if (params.type === 'without_registration') {
      createAnonInvite(params.id);
    }
    if (params.type === 'with_registration') {
      createRegInvite(params.id, this.props.history);
    }
    this.setState(() => ({
      ...params,
    }));
  };

  render() {
    const { meta } = this.props;

    return meta.isRequesting ? (
      <Loader />
    ) : meta.isInviteError ? (
      <InviteError />
    ) : null;
  }
}

const dispatch2props = (dispatch) => ({
  linksActions: bindActionCreators(linksActions, dispatch),
  getRoot: bindActionCreators(getRoot, dispatch),
});
const state2props = (state) => ({
  meta: state.meta,
});

export default connect(state2props, dispatch2props)(InvitesContainer);
