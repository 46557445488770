import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Clear } from '../../static/imgs/delete.svg';

import './style.scss';


class SearchInput extends Component {
    handleChange = ({ target: { value } }) => {
      this.props.onChange(value);
    }

    render() {
      const {
        icon, placeholder, onFocus, value, clearValue,
      } = this.props;

      return (
        <div className="search">
          <div className="searchIcon">
            {icon || <SearchIcon />}
          </div>
          <input
            placeholder={placeholder || 'Поиск'}
            autoComplete="off"
            value={value || ''}
            onFocus={onFocus}
            onChange={this.handleChange}
            className={`inputInput ${clearValue ? 'inputInput_clear' : ''}`}
          />
          {
                  value && clearValue && (
                    <div onClick={clearValue} className="searchIcon searchIcon_clear">
                      <Clear />
                    </div>
                  )
}
        </div>
      );
    }
}

// const container = connect(state2props, dispatch2props)(SearchInput)

export default SearchInput;
