import React from 'react';
import { connect } from 'react-redux';
import Modal from '../modal/Modal';
import { closeAlert } from './actions';
import './style.scss';

function Alert({ text, closeAlert, title }) {
  return (
    <Modal closeModal={closeAlert}>
      <div className="alert">
        <div className="alert__title">{title}</div>
        <p className="alert__text">{text}</p>
        <button onClick={closeAlert} className="alert__btn">
          OK
        </button>
      </div>
    </Modal>
  );
}

export default connect(
  (state) => ({
    text: state.alert.text,
    title: state.alert.title,
  }),
  { closeAlert },
)(Alert);
