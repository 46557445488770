import React, { Component } from 'react';
import Fullscreen from 'react-full-screen';
import '../Question.scss';

const findGallery__helper = () => {
  // console.log(Array.from(document.getElementsByClassName("picture__gallery")))
};

const TIMER_TIME = 5000;
const TICK_TIME = 1000;

class Photo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      imgCount: 0,
      imgInGallery: 0,
      _isPreview: false,
      _selectedPreview: null,
      timerId: null,
      inactionTime: 0,
      slideInfo: {
        backButtonPressesAmount: 0,
        inactivityPhasesAmount: 0,
        galleryItemsViewsReports: props.slide.items.items.map(
          ({ self, id }) => ({ galleryItem: self, id, amount: 0 }),
        ),
      },
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        timerId: setInterval(() => this.timerIncrement(), TICK_TIME),
        imgInGallery: Math.floor(
          document.getElementsByClassName('picture__gallery')[0].offsetWidth
            / 110
            - 1,
        ),
      },
      () => {
        document.onmousemove = this.resetTimer;
        document.onkeypress = this.resetTimer;
        document.onclick = this.resetTimer;
        this.increaseGalleryItemsViewsReports(
          this.props.slide.items.items[this.state.current],
        );
      },
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.current !== this.state.current) {
      if (prevState.current > this.state.current) this.increaseBackButtonPressesAmount();
      this.increaseGalleryItemsViewsReports(
        this.props.slide.items.items[this.state.current],
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  handleImageClick = (index) => this.setState({ current: index });

  changeSlideInfo = (name, value) => {
    const { handleChangeSlideInfo } = this.props;
    this.setState(
      ({ slideInfo }) => ({
        slideInfo: { ...slideInfo, [name]: value },
      }),
      () => handleChangeSlideInfo(this.serializeSlideInfo(this.state.slideInfo)),
    );
  };

  serializeSlideInfo(slideInfo) {
    return {
      ...slideInfo,
      galleryItemsViewsReports: slideInfo.galleryItemsViewsReports.map(
        ({ galleryItem, amount }) => ({ galleryItem, amount }),
      ),
    };
  }

  increaseBackButtonPressesAmount = () => {
    const {
      slideInfo: { backButtonPressesAmount },
    } = this.state;
    this.changeSlideInfo(
      'backButtonPressesAmount',
      backButtonPressesAmount + 1,
    );
  };

  increaseGalleryItemsViewsReports = (item) => {
    const {
      slideInfo: { galleryItemsViewsReports },
    } = this.state;
    this.changeSlideInfo(
      'galleryItemsViewsReports',
      galleryItemsViewsReports.map((galleryItem) => (galleryItem.id === item.id
        ? { ...galleryItem, amount: galleryItem.amount + 1 }
        : galleryItem)),
    );
  };

  increaseInactivityPhasesAmount = () => {
    const {
      timerId,
      slideInfo: { inactivityPhasesAmount },
    } = this.state;
    this.changeSlideInfo('inactivityPhasesAmount', inactivityPhasesAmount + 1);
  };

  timerIncrement = () => {
    this.setState(
      ({ inactionTime }) => ({
        inactionTime: inactionTime + 1,
      }),
      () => this.state.inactionTime === TIMER_TIME / TICK_TIME
        && this.increaseInactivityPhasesAmount(),
    );
  };

  resetTimer = () => {
    this.setState({
      inactionTime: 0,
    });
  };

  handleNextClick = () => {
    const {
      slide: {
        items: { items },
      },
    } = this.props;
    this.setState((state) => ({
      current:
        state.current < items.length - 1 ? state.current + 1 : state.current,
      imgCount:
        state.current + 1 === state.imgInGallery + state.imgCount
        && state.current < items.length - 1
          ? state.imgCount + 1
          : state.imgCount,
    }));
  };

  handlePrevClick = () => this.setState((state) => ({
    current: state.current > 0 ? state.current - 1 : state.current,
    imgCount:
        state.current < state.imgCount + 1 && state.current > 0
          ? state.imgCount - 1
          : state.imgCount,
  }));

  handleSwipeGallery = (isForward) => {
    const images = this.props.slide.items.items.map((item) => ({
      title: item.title,
      url: item.image.url,
    }));
    this.setState((state) => ({
      // сurrent: isForward
      // ? state.current < state.imgCount + 1 ? state.current + 1 : state.current
      // : state.current,
      current: isForward
        ? state.current < state.imgCount + 1
          ? state.imgCount + 1
          : state.current
        : state.current + 1 === state.imgInGallery + state.imgCount
          ? state.current - 1
          : state.current,
      imgCount: isForward
        ? state.imgCount === images.length - state.imgInGallery
          ? state.imgCount
          : state.imgCount + 1
        : state.imgCount === 0
          ? 0
          : state.imgCount - 1,
    }));
  };

  handleRestart = () => {
    this.setState({
      current: 0,
      imgCount: 0,
    });
  };

  handleClosePreview = () => this.setState({ _isPreview: false, _selectedPreview: null });

  handleOpenPreview = (_selected) => this.setState({ _isPreview: true, _selectedPreview: _selected });

  render() {
    const { slide } = this.props;
    const { current, imgCount, imgInGallery } = this.state;
    const images = slide.items.items
      .map((item) => ({ title: item.title, url: item.image.url }));

    return (
      <div className="pictureTest">
        <div className="photoHeader">
          <div
            onClick={this.props.onFullscreenClick}
            className="fullScreen-wrapper"
          >
            <p className="body1">Полноэкранный режим</p>
            <div className="fullScreen" />
          </div>
          <h5>{slide.title}</h5>
        </div>
        <div className="picture__wrapper">
          <div className="actionButtonWrapper">
            <div
              onClick={this.handlePrevClick}
              className="actionButton prevPhoto"
            />
            <div
              onClick={this.handleRestart}
              className="actionButton restart"
            />
          </div>
          <img src={images[current].url} />
          <div
            className="actionButton nextPhoto"
            onClick={this.handleNextClick}
          />
        </div>
        <p className="photoDescription">{slide.description}</p>
        <div className="picture__gallery">
          {imgInGallery <= images.length && (
            <div
              onClick={() => this.handleSwipeGallery(false)}
              className="leftArrow"
            />
          )}
          {images
            .map((image, index) => (index === current ? (
              <div
                style={{ backgroundImage: `url(${image.url})` }}
                className="selected imageGallery"
                key={index}
              />
            ) : (
            // : index === (imgInGallery - 1)
            // ? <div style={
            // { backgroundImage: `linear-gradient(to right, rgba(28,28,28,0) 20%, rgba(28,28,28,0.8) 50%, rgba(28,28,28,1)), url(${image.url})`,
            // marginRight: '-50px' }}
            // className='imageGallery' key={index}></div>
            // : <div style={{ backgroundImage: `url(${image.url})`}} className='imageGallery' onClick={() => this.handleImageClick(index)} key={index}></div>)
              <div
                style={{ backgroundImage: `url(${image.url})` }}
                className="imageGallery"
                onClick={() => this.handleImageClick(index)}
                key={index}
              />
            )))
            .filter(
              (_, index) => index >= imgCount && index < imgCount + imgInGallery,
            )}
          {imgInGallery <= images.length && (
            <div
              onClick={() => this.handleSwipeGallery(true)}
              className="rightArrow"
            />
          )}
        </div>
        {/* <div className='text__wrapper'>
          <span className='body1'>
          {
            images[current].title
          }
          </span>
        </div> */}
      </div>
    );
  }
}

export default Photo;
