
export function setQueryString(filter, isContinueQuery) {
  let query = '';
  if (filter) {
    Object.entries(filter).forEach(([name, value]) => {
      if (
        value !== null
                && value !== ''
                && value !== undefined
      ) {
        if (query.length || isContinueQuery) {
          query += '&';
        } else {
          query += '?';
        }
        query += `${name}=${value}`;
      }
    });
  }

  return query;
}
