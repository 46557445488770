import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import * as Sentry from '@sentry/browser';
import store from './store';
import {
  RootContainer,
  AuthContainer,
  InvitesContainer,
  ResultsContainer,
  ResultContainer,
} from './containers';
import { checkAuth, checkInvite } from './utils';

export const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.GIT_SHA,
    environment: process.env.ENVIRONMENT_NAME,
  });
}

const invite = checkInvite(window.location);
const session = checkAuth();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} forceRefresh>
      {invite ? (
        <Route path="/invites" component={InvitesContainer} />
      ) : session ? (
        <Route path="/" component={RootContainer} />
      ) : (
        <div>
          <Route exact path="/" component={AuthContainer} />
          <Route
            path="/:context(course-results)"
            component={ResultsContainer}
          />
          <Route
            exact
            path="/:context(course-results)/:id"
            component={ResultContainer}
          />
        </div>
      )}
    </Router>
  </Provider>,
  document.getElementById('root'),
);
