import React, { Component } from 'react';
import { LinearProgress } from '@material-ui/core';
import { formatTime } from '../../utils';
import './RunTimer.scss';
import '../../styles/typography.scss';

class RunTimer extends Component {
  normalize = (startTimerValue, remainingTimerValue) => {
    if (remainingTimerValue !== 0) {
      return remainingTimerValue/startTimerValue *  100
    } else {
      return 0
    }
  };
  render() {
    const {
      remainingTimeToCompleteCourse,
      remainingTimeToCompleteSlide,
      timeToCompleteCourse,
      timeToCompleteSlide,
    } = this.props
    return (
      <div className="progressInfo">
        <div className='secondary_text'>Осталось на курс:</div>
        <div className='timer'>{formatTime(remainingTimeToCompleteCourse)}</div>
        <LinearProgress variant="determinate" className='progressBar'
                        value={this.normalize(timeToCompleteCourse, remainingTimeToCompleteCourse)}
        />
        <div className='secondary_text'>Осталось на блок:</div>
        <div className='timer'>{formatTime(remainingTimeToCompleteSlide)}</div>
        <LinearProgress variant="determinate"  className='progressBar'
                        value={this.normalize(timeToCompleteSlide, remainingTimeToCompleteSlide)}
        />
      </div>
    );
  }
}

export default RunTimer;
