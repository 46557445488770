import { manufacturerTypes } from '../types';
import { get } from '../api';
import { setQueryString } from '../utils/api';

export const getManufacturers = (filter) => (dispatch, getState) => {
  dispatch((() => ({ type: manufacturerTypes.GET_MANUFACTURERS_REQUEST }))());
  const query = setQueryString(filter);
  get(`${process.env.BASE_API_URL}/v1/manufacturers${query}`).then((data) => dispatch((() => ({
    type: manufacturerTypes.GET_MANUFACTURERS_SUCCESS,
    payload: { data },
  }))()));
};
