export const SEND_NUMBER__REQUEST = 'AUTH_TYPE__SEND_NUMBER__REQUEST';
export const SEND_NUMBER__SUCCESS = 'AUTH_TYPE__SEND_NUMBER__SUCCESS';
export const SEND_NUMBER__FAILURE = 'AUTH_TYPE__SEND_NUMBER__FAILURE';
export const GET_ROOT_RESOURCE__REQUEST = 'AUTH_TYPE__GET_ROOT_RESOURCE__REQUEST';
export const GET_ROOT_RESOURCE__SUCCESS = 'AUTH_TYPE__GET_ROOT_RESOURCE__SUCCESS';
export const GET_ROOT_RESOURCE__FAILURE = 'AUTH_TYPE__GET_ROOT_RESOURCE__FAILURE';
export const SEND_CODE__REQUEST = 'AUTH_TYPE__SEND_CODE__REQUEST';
export const SEND_CODE__SUCCESS = 'AUTH_TYPE__SEND_CODE__SUCCESS';
export const SEND_CODE__FAILURE = 'AUTH_TYPE__SEND_CODE__FAILURE';
export const GET_USER__SUCCESS = 'AUTH_TYPE__GET_USER_SUCCESS';
export const GET_CURRENT_USER__PROCESS = 'AUTH_TYPE__GET_CURRENT_USER__PROCESS';
export const GET_CURRENT_USER__SUCCESS = 'AUTH_TYPE__GET_CURRENT_USER__SUCCESS';
export const GET_CURRENT_USER__FAILURE = 'AUTH_TYPE__GET_CURRENT_USER__FAILURE';
export const LOGOUT = 'AUTH_TYPE__LOGOUT';
export const SIGN_UP_SUCCESS = 'AUTH_TYPE__SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'AUTH_TYPE__SIGN_UP_FAILURE';
export const NO_USER = 'AUTH_TYPE__NO_USER';
export const SEND_TEL_ON_SIGN_UP__REQUEST = 'AUTH_TYPE__SEND_TEL_ON_SIGN_UP__REQUEST';
export const SEND_TEL_ON_SIGN_UP__SUCCESS = 'AUTH_TYPE__SEND_TEL_ON_SIGN_UP__SUCCESS';
export const SEND_TEL_ON_SIGN_UP__FAILURE = 'AUTH_TYPE__SEND_TEL_ON_SIGN_UP__FAILURE';
export const SEND_CODE_ON_SIGN_UP__REQUEST = 'AUTH_TYPE__SEND_CODE_ON_SIGN_UP__REQUEST';
export const SEND_CODE_ON_SIGN_UP__SUCCESS = 'AUTH_TYPE__SEND_CODE_ON_SIGN_UP__SUCCESS';
export const SEND_CODE_ON_SIGN_UP__FAILURE = 'AUTH_TYPE__SEND_CODE_ON_SIGN_UP__FAILURE';
export const SEND_EMAIL_ON_SIGN_UP__FAILURE = 'AUTH_TYPE__SEND_EMAIL_ON_SIGN_UP__FAILURE';
export const DISABLE_ERROR = 'AUTH_TYPE__DISABLE_ERROR';
