import React, { Component } from 'react';
import { TextField, Typography, withStyles } from '@material-ui/core';

const StyledQuestion = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

class Dialog extends Component {
  constructor(props) {
    super(props);
    const { answers, slide: { questions }, run: { isError } } = props;

    // если оставить без проверки isError то answers не очищается и берутся ответы предыдущих вопросов и записываются в стейт
    // записываение в стейт answers нужно, чтобы при отправке на сервер, если возникла ошибка, то записывать в поля до этого введенные ответы
    // добавлена проверка на isError - если произошла ошибка и answers не пустой массив, то записать в стейт ответы из пропсов
    // по хорошему необходимо не перемаунчивать этот компонент при отправке данных
    // или очищать каждый раз answers
    this.state = {
      answers: answers.length && isError ? answers : questions.map(() => ''),
      isError,
    };
  }

  handleChange = ({ target: { value } }, index) => {
    this.setState(
      ({ answers }) => ({
        answers: answers.map((answer, answerIndex) => (answerIndex === index ? value : answer)),
      }), () => {
        this.onAnswer();
      },
    );
  };

  onAnswer = () => {
    const { onAnswer } = this.props;
    const { answers } = this.state;
    onAnswer({ answers });
  }

  isValidAnswer = (answer) => answer && answer.trim().length;

  render() {
    const { slide } = this.props;
    const { answers, isError } = this.state;

    return (
      <div className="dialog__wrapper">
        <Typography variant="h5">Напишите ответы на вопрос(ы)</Typography>
        <div className="dialog__questions">
          {slide.questions.map((value, index) => (
            <div className="dialog__question" key={index}>
              <StyledQuestion variant="subtitle2">{value}</StyledQuestion>
                <TextField
                  placeholder="Введите свой вариант ответа"
                  value={answers[index] || ''}
                  onChange={(e) => this.handleChange(e, index)}
                  margin="normal"
                  variant="outlined"
                  rows="4"
                  error={isError && !this.isValidAnswer(answers[index])}
                  multiline
                  fullWidth
                />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Dialog;
