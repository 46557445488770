import React, { Component } from 'react';
import YouTube from 'react-youtube';

class Video extends Component {
  constructor(props) {
    super(props);
  }

  onReady(event) {
    event.target.pauseVideo();
  }

  render() {
    const { slide } = this.props;
    const width = document.getElementsByClassName('question__wrapper')[0] ? document.getElementsByClassName('question__wrapper')[0].offsetWidth : 500;
    const height = document.getElementsByClassName('question__wrapper')[0] ? document.getElementsByClassName('question__wrapper')[0].offsetHeight : 500;

    const opts = {
      width,
      height,
      playerVars: {
        autoplay: 0,
        controls: slide.canBeRewound ? 1 : 0,
        disablekb: slide.canBeRewound ? 0 : 1,
      },
    };

    return (
      <div className="videoTest">
        <div className="video__wrapper">
          <YouTube
            videoId={slide.youTubeVideo}
            opts={opts}
            onReady={this._onReady}
          />
        </div>
      </div>
    );
  }
}

export default Video;
