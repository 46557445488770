import React, { Component } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import ReactMde from 'react-mde';

class ImageQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      answers: { items: [] },
    };
  }

    componentDidMount = () => this.setState(() => ({ ...this.props.question }));

    handleChangeTitle = (text) => this.setState(
      () => ({ text }),
      () => {
        this.props.onChange(this.state),
        this.props.validate(this.state)
      });

    handleAddAnswer = (event) => {
      const imageArray = Array.from(event.target.files);
      this.setState(
        (state) => ({
          answers: {
            items: [
              ...imageArray.map((image) => ({
                image,
                isNew: true,
                isCorrect: false,
              })),
              ...state.answers.items,
            ],
          },
        }),
        () => {
          this.props.onChange(this.state),
          this.props.validate(this.state)
        }
      );
    };

    handleChangeAnswer = (indexAnswers, field, value) => {
      this.setState(
        (state) => ({
          answers: {
            items: state.answers.items.map((answer, index) => (indexAnswers === index ? { ...answer, [field]: value } : answer)),
          },
        }),
        () => {
          this.props.onChange(this.state),
          this.props.validate(this.state)
        }
      );
    };

    loadPreview = (image) => (image.url ? image.url : URL.createObjectURL(image));

    handleDeleteAnswer = (indexAnswers) => this.setState(
      () => ({
        answers: {
          items: this.state.answers.items.filter(
            (_, index) => index !== indexAnswers,
          ),
        },
      }),
      () => {
        this.props.onChange(this.state),
        this.props.validate(this.state)
      }
    );

    render() {
      const { answers } = this.state;
      const { indexQuestions, question, isInvalidInput } = this.props;

      return (
        <div className="contentWrapper">
          <div
            key={`questions_${indexQuestions}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className="trashContainer"
              onClick={() => this.props.handleDeleteQuestion(indexQuestions)}
            />
            <Typography className="topMargin" id="slider-image">
              Вопрос
            </Typography>
            <ReactMde
              className={`testText ${!question.text ? isInvalidInput ? 'invalidValue' : '' : null}`}
              onChange={this.handleChangeTitle}
              value={question.text}
              generateMarkdownPreview={(markdown) => Promise.resolve(this.converter.makeHtml(markdown))}
              selectedTab={question.mode}
              placeholder="Текcт вопроса"
            />
            <Typography style={{ marginBottom: '20px' }} id="slider-image">
              Изображения для ответов
            </Typography>
            <div className="photoInput__wrapper">
              <input
                onChange={this.handleAddAnswer}
                className="photoInput"
                type="file"
                accept="image/*"
                id={`file${indexQuestions}`}
                multiple
              />
              <label
                htmlFor={`file${indexQuestions}`}
                className={!this.state.answers.items.length ? isInvalidInput ? 'input_error' : '' : null}
              >
                +
              </label>
            </div>
            <div className="photoInputPreview">
              {answers.items.length > 0
                        && answers.items.map((answer, indexAnswers) => (
                          <div
                            key={indexAnswers}
                            className="question"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div className="previewContainer">
                              <div
                                className="delete"
                                onClick={() => this.handleDeleteAnswer(indexAnswers)}
                              />
                              <Checkbox
                                className="correctPhoto"
                                onChange={({ target: { checked } }) => this.handleChangeAnswer(
                                  indexAnswers,
                                  'isCorrect',
                                  checked,
                                )}
                                checked={answer.isCorrect}
                              />
                              <img
                                className="answerPhoto"
                                key={indexAnswers}
                                src={this.loadPreview(answer.image)}
                              />
                            </div>
                          </div>
                        ))}
            </div>
            {this.state.answers.items.length > 0 ? (
              <Typography className="tipRow">
                Выберите правильный вариант ответа
              </Typography>
            ) : null}
            <div style={{ width: '100%' }} className="photoInfo">
              <Typography>Форматы: jpg, png, tif. Не более 30 mb</Typography>
            </div>
          </div>
        </div>
      );
    }
}

export default ImageQuestion;
