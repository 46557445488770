import React, { Component } from 'react';
import { RunTimerContainer } from '../../containers';
import './Info.scss';
import '../../styles/typography.scss';

class Info extends Component {
  timeToCompleteSlide = () => {
    const currentSlide = this.props.run.currentSession.slide;
    const courseSlides = this.props.run.course__new.slides.items;
    return courseSlides.filter(slide => slide.self === currentSlide)[0].timeToComplete
  }
  render() {
    const {
      run: {
        course__new, currentSession
      },
    } = this.props;
    const { items } = course__new.slides;
    const currentNumber = items.findIndex(({ self }) => self === currentSession.slide) + 1;
    return (
      <div className="testInfo__wrapper">
        <div className="testInfo__content">
          <div className="themeInfo">
            <div className="secondary_text">{course__new.title}</div>
            <div className="body1">{course__new.description}</div>
          </div>
          <div className="statisticInfo disabled">
            <div>
              Блок
              {currentNumber}
              /
              {items.length}
            </div>
          </div>
          {
            course__new.allowTimeOuts
            ? <p>Время для прохождения курса не ограничено</p>
            : currentSession.slide && <RunTimerContainer timeToCompleteSlide={this.timeToCompleteSlide()} {...this.props}/>
          }
        </div>
      </div>
    );
  }
}

export default Info;
