import * as promptTypes from './actionTypes';

const initialState = {
  isOpenDeleteCourse: false,
  isOpenDeleteLink: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case promptTypes.DELETE_COURSE_OPEN:
      return {
        ...state,
        isOpenDeleteCourse: true,
      };
    case promptTypes.DELETE_COURSE_CLOSE:
      return {
        ...state,
        isOpenDeleteCourse: false,
      };
    case promptTypes.DELETE_LINK_OPEN:
      return {
        ...state,
        isOpenDeleteLink: true,
        inviteId: payload.inviteId,
      };

    case promptTypes.DELETE_LINK_CLOSE:
      return {
        ...state,
        isOpenDeleteLink: false,
      };
    default: return state;
  }
};
