export const generateCourseData = (course, withoutCover, withoutCertificate, withoutAttempts) => {
  const serializedCourse = {
    availability: {
      from: new Date(course.availability.from).toISOString().replace(/\.[0-9]+Z$/, '+00:00'),
      till: new Date(course.availability.till).toISOString().replace(/\.[0-9]+Z$/, '+00:00'),
    },

    cover: course.cover ? course.cover.self : null,
    status: 'ready',
    certificate: course.certificate ? course.certificate.self : null,
    certificateBackground: course.certificateBackground ? course.certificateBackground.self : null,
    description: course.description,
    difficulty: course.difficulty,
    rewardPoints: course.rewardPoints,
    allowTimeOuts: +course.allowTimeOuts,
    theme: course.theme,
    title: course.title,
    manufacturer: course.manufacturer && course.manufacturer.self,
  };
  if (withoutCover) { delete serializedCourse.cover; }
  if (withoutCertificate) { delete serializedCourse.certificate; }
  if (course.recommendedNumberOfAttempts) {
    serializedCourse.recommendedNumberOfAttempts = course.recommendedNumberOfAttempts;
  }
  if (withoutAttempts) { delete serializedCourse.recommendedNumberOfAttempts; }

  return serializedCourse;
};
