import { coursesTypes } from '../types';
import { get } from '../api';
import { history } from '..';
import { setQueryString } from '../utils/api';

const calculateStatus4course = (access) => {
  console.log(access);
  if (access.courseResults === false) {
    return 'active';
  }
  if (access.courseResults.type === 'successful') {
    return 'passed';
  }
  if (access.courseResults.type === 'unsuccessful') {
    return 'failed';
  }
  if (access.courseResults.type === 'expired') {
    return 'archive';
  }

  // return 'active'
};

export const getCourseForAnonUser = () => (dispatch) => {
  dispatch((() => ({ type: 'rvsf' }))());
  history.push(`/run/${sessionStorage.access.split('/').pop()}`);
  location.reload();
};

export const getCoursesForUser = (filter) => (dispatch, getState) => {
  dispatch((() => ({ type: coursesTypes.GET_COURSES_FOR_USER__REQUEST }))());
  const query = setQueryString(filter, true);
  get(`${process.env.BASE_API_URL}${getState().meta.user.courseAccesses}${query}`).then((_accesses) => {
    _accesses = _accesses.items;
    dispatch((() => ({ type: coursesTypes.GET_ACCESSES_FOR_USER__SUCCESS, payload: { accesses: _accesses } }))());
    Promise.all(_accesses.map((_access) => get(`${process.env.BASE_API_URL}${_access.course}`))).then((_courses) => {
      Promise.all(_accesses.map((_access) => (_access.courseResults ? get(`${process.env.BASE_API_URL}${_access.courseResults}`) : false))).then((_results) => {
        dispatch((() => ({
          type: coursesTypes.GET_COURSES_FOR_USER__SUCCESS,
          payload: _courses.map((__course, __key) => ({
            ...__course,
            access: _accesses[__key],
            courseResults: _results[__key],
            status: _accesses[__key].status,
          })),
        }))());
      });
    });
  });
};
