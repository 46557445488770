import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Switch from 'react-router/es/Switch';
import {
  CoursesContainer,
  RunContainer,
} from '../containers/mobile';
import { SupportContainer, TicketContainer } from '../containers';
import BlockUserError from '../component/error/BlockUserError';

@withRouter
class Mobile extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route path="/courses" component={CoursesContainer} />
          <Route path="/run/:id" component={RunContainer} />
          <Route exact path="/support" component={SupportContainer} />
          <Route exact path="/support/:ticketId" component={TicketContainer} />
          <Route path="/error" component={BlockUserError} />
          {/*
          <Redirect to='/courses' />

          // TODO: remove warnings about same redirect
          // this.props.location.pathname === '/create' ? null : <Redirect to='/constructor' />
        */}
        </Switch>
      </>
    );
  }
}

export default Mobile;
