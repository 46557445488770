import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Button, Typography } from '@material-ui/core';
import { slideType2name } from '../../utils/translate';
import { Course } from '../Courses';
import { courseSessionStatuses } from './constants';

class FinishRunPage extends Component {
  render() {
    const {
      run: { course__new },
      history,
      location,
    } = this.props;
    const slides = course__new.slides.items;
    const checkRun = Object.keys(course__new).length === 0;
    const count = course__new.slides.items.length || '';
    const isSuccess = course__new.session.status === courseSessionStatuses.SUCCESSFUL;

    return (
      <>
        <div className="stepBar">
          <div className="stepBarPoint">ИНФОРМАЦИЯ</div>
          {slides.map((slide, index) => (
            <div key={index} className="stepBarPoint slideType">
              {slideType2name(slide.type)}
            </div>
          ))}
          <div className="stepBarPoint current">РЕЗУЛЬТАТ</div>
        </div>
        <div className="test__wrapper">
          <div className="course__wrapper">
            <div className="finished__wrapper">
              {checkRun ? (
                <Redirect to="/course" />
              ) : (
                <Course
                  runPage
                  picture={course__new.cover.url}
                  {...course__new}
                  count={count}
                />
              )}
              <div className="content__wrapper">
                <div className="info__wrapper">
                  <div
                    className={isSuccess ? 'successIcon' : 'failedIcon'}
                  />
                  <Typography>
                    {isSuccess ? 'Курс успешно пройден!' : 'Курс не пройден'}
                  </Typography>
                  <div className="statisticIcons">
                    {isSuccess && (
                    <div className="bonusIcon">
                      {course__new.rewardPoints}
                      {' '}
                      БОНУСОВ
                    </div>
                    )}
                  </div>
                </div>
                <div className="actionFooter">
                  {isSuccess && course__new.certificate && (
                  <a target="_blank" href={course__new.certificate.url}>
                    <Button
                      className="toCertificateButton"
                      onClick={() => null}
                    >
                      Скачать сертификат
                    </Button>
                  </a>
                  )}
                  {sessionStorage.is_anon ? (
                    isSuccess ? null : course__new.access.remainingAttempts === 1 ? null : (
                      <Button
                        className="toPageButton"
                        onClick={() => {
                          history.push({ pathname: '/empty' });
                          setTimeout(() => {
                            history.replace({ pathname: location.pathname });
                          });
                        }}
                      >
                        Пройти заново
                      </Button>
                    )
                  ) : (
                    <Button
                      className="toPageButton"
                      onClick={() => this.props.history.push('/courses')}
                    >
                      К странице с курсами
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FinishRunPage;
