import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { history } from '../..';
import './styles.scss';

function TokenError({ text }) {
  const handleGoAuthClick = () => {
    localStorage.clear();
    history.push('/');
    window.location.reload();
  };

  return (
    <div className="fullscreen_centered">
      <Typography>{text}</Typography>
      <Button onClick={handleGoAuthClick}>На страницу авторизации</Button>
    </div>
  );
}

export default TokenError;
