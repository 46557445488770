import { runTypes } from '../types';
import { post, get } from '../api';

let slideTimer = null;

export const startCourse = () => (dispatch, getState) => {
  const state = getState().run;
  dispatch(startSlideTimer());
  dispatch((() => ({
    type: runTypes.START_COURSE,
  }))());
};

export const resetRun = () => ({
  type: runTypes.RESET_RUN,
});

export const calculateTime = () => (dispatch, getState) => {
  const totalTime = getState().run.course.slides.items.reduce((accumulator, current) => accumulator += current.timeToComplete, 0) * 60;
  dispatch((() => ({
    type: runTypes.CALCULATE_TIME,
    payload: { totalTime },
  }))());
};
export const prevSlide = () => (dispatch, getState) => {
  dispatch((() => ({
    type: runTypes.NEXT_SLIDE,
    payload: { current: getState().run.current - 1 },
  }))());
};

export const startSlideTimer = () => (dispatch, getState) => {
  const state = getState().run;
  const slide = state.course.slides.items[state.current];

  clearInterval(slideTimer);
  slideTimer = setInterval(() => dispatch((() => ({
    type: runTypes.UPDATE_SLIDE_TIMER,
    payload: { timer: getState().run.slideTimer - 1 },
  }))()), 1000);
  dispatch((() => ({
    type: runTypes.UPDATE_SLIDE_TIMER,
    payload: { timer: slide.timeToComplete * 60 },
  }))());
};

export const startTimer = () => (dispatch, getState) => setInterval(
  () => dispatch((() => ({
    type: runTypes.UPDATE_TIMER,
    payload: { timer: getState().run.timer - 1 },
  }))()),
  1000,
);
