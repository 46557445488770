import { authTypes } from '../types';
import { requestSms, generateToken } from '../api/auth';
import { post, postNoContent } from '../api';
import { history } from '..';

export const sendPhone = ({ tel }) => (dispatch, getState) => {
  dispatch((() => ({ type: authTypes.SEND_NUMBER__REQUEST }))());
  requestSms({ tel })
    .then((data) => dispatch((() => ({ type: authTypes.SEND_NUMBER__SUCCESS }))()))
    .catch(() => dispatch({ type: authTypes.SEND_NUMBER__FAILURE }))
    .finally(setTimeout(() => dispatch({ type: authTypes.DISABLE_ERROR}), 5000))
};

export const sendCode = ({ tel, code }) => (dispatch) => {
  dispatch((() => ({ type: authTypes.SEND_CODE__REQUEST }))());
  generateToken({ tel, code })
    .then((data) => {
      // localStorage.clear()
      localStorage.setItem('bearer_token', data.accessToken.token);
      localStorage.setItem(
        'user_id',
        data.accessToken.user.split('/').splice(-1)[0],
      );
      history.push('/courses');
      location.reload();
      dispatch((() => ({ type: authTypes.SEND_CODE__SUCCESS }))());
    })
    .catch(() => dispatch({ type: authTypes.SEND_CODE__FAILURE }))
    .finally(setTimeout(() => dispatch({ type: authTypes.DISABLE_ERROR}), 5000))
};

export const logout = () => {
  localStorage.clear();
  history.push('/');
  location.reload();

  return { type: authTypes.LOGOUT };
};

export const signUp = (form) => (dispatch) => {
  dispatch((() => ({ type: authTypes.SEND_NUMBER__REQUEST }))());
  // post(`https://api.dev.pharm-place.ru/v1/account-creation-using-course-invite-requests`, form, true)
  // .then(() => {
  //   localStorage.removeItem('invite_id')
  //   localStorage.setItem('is_signup_success', true)
  //   location.reload()
  //   dispatch((() => ({
  //     type: authTypes.SIGN_UP_SUCCESS
  //   }))())
  // })
  // .catch(() => dispatch((() => ({
  //   type: authTypes.SIGN_UP_FAILURE
  // }))()))
};

export const sendPhoneInSignup = (tel) => (dispatch) => {
  dispatch((() => ({ type: authTypes.SEND_TEL_ON_SIGN_UP__REQUEST }))());
  postNoContent(`${process.env.BASE_API_URL}/v1/request-sms/user-registration`, { phone: `+${tel}` }, true)
    .then(() => dispatch({ type: authTypes.SEND_TEL_ON_SIGN_UP__SUCCESS }))
    .catch(() => dispatch({ type: authTypes.SEND_TEL_ON_SIGN_UP__FAILURE }))
    .finally(setTimeout(() => dispatch({ type: authTypes.DISABLE_ERROR}), 5000))
};

export const sendCodeInSignup = (form) => (dispatch) => {
  dispatch((() => ({ type: authTypes.SEND_CODE_ON_SIGN_UP__REQUEST }))());
  post(`${process.env.BASE_API_URL}/v1/account-creation-requests`, form, true)
    .then((response) => {
      localStorage.setItem('bearer_token', response.accessToken.token);
      localStorage.setItem(
        'user_id',
        response.user.id,
      );
      history.push('/courses');
      location.reload();
      dispatch((() => ({ type: authTypes.SEND_CODE_ON_SIGN_UP__SUCCESS }))());
    })
    .catch((response) => {
      const emailError = response.violations.find(field => field.pointer === '#/email')
      const smsError = response.violations.find(field => field.pointer === '#/smsConfirmationCode')

      if (emailError) {
        dispatch({ type: authTypes.SEND_EMAIL_ON_SIGN_UP__FAILURE })
      }
      if (smsError) {
        dispatch({ type: authTypes.SEND_CODE_ON_SIGN_UP__FAILURE })
      }
    })
    .finally(setTimeout(() => dispatch({ type: authTypes.DISABLE_ERROR}), 5000))
};
