import React, { Component } from 'react';
import { TextField, Typography } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Loader } from '..';

import './style.scss';
import SearchInput from '../search';

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      typingTimeout: null,
      value: props.value || '',
    };
    this.debounceOnChange = debounce(
      () => props.onChange(this.state.value),
      400,
      {
        leading: false,
        trailing: true,
      },
    );
  }

  componentWillUpdate = (nextProps) => {
    if (this.state.value === '' && nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

    handleToggle = () => {
      this.setState(({ isOpened }) => ({
        isOpened: !isOpened,
      }));
    };

    handleOutsideClick = ({ target }) => {
      if (!this.node.contains(target)) {
        this.setState(() => ({
          isOpened: false,
        }));
      }
    };

  handleValueChange = (value) => {
    this.setState({ value }, () => this.debounceOnChange());
  };

    handleClearValue = () => {
      const { onSelect } = this.props;
      this.setState(
        {
          value: '',
          isOpened: false,
        },
        () => onSelect(),
      );
    };

  handleSelectSuggestion = (item) => {
    const { suggestionsKey, onSelect } = this.props;
    this.setState(
      {
        value: item[suggestionsKey],
        isOpened: false,
      },
      () => onSelect(item),
    );
  };

  getHighlightedSuggestion(suggestion) {
    const { value } = this.state;
    const arrSuggestion = suggestion.split('');
    const firstIndex = suggestion.toLowerCase().indexOf(value.toLowerCase());

    return (
      <span>
        {arrSuggestion.slice(0, firstIndex).join('')}
        <b>{arrSuggestion.slice(firstIndex, firstIndex + value.length).join('')}</b>
        {arrSuggestion.slice(firstIndex + value.length).join('')}
      </span>
    );
  }

  render() {
    const { isOpened, value } = this.state;
    const {
      icon,
      time,
      onChange,
      placeholder,
      variant,
      className,
      name,
      isRequesting,
      suggestions,
      classNameSuggestion,
      classNameSuggestions,
      suggestionsKey,
    } = this.props;

    const cutSuggestions = [...suggestions];
    cutSuggestions.length = 5;

    return (
      <div className={`autocomplete-input ${className || ''}`} ref={(node) => (this.node = node)}>
        {variant === 'search' ? (
          <SearchInput
            value={value}
            icon={icon}
            time={time}
            placeholder={placeholder}
            onFocus={this.handleToggle}
            onChange={this.handleValueChange}
            clearValue={this.handleClearValue}
          />
        ) : (
          <TextField
            margin="normal"
            label="Производитель"
            autoComplete="off"
            name={name}
            value={value}
            onChange={({ target: { value } }) => this.handleValueChange(value)}
            onFocus={this.handleToggle}
            // нельзя повесить onBlur т.к. при выборе элемента из списка
            // быстрее сработает onBlur и закроет список, чем onCLick у элемента списка
            // onBlur={() => this.setState({ isOpened: false })}
            InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
            InputProps={{ classes: { input: 'inputSmallPadding' } }}
          />
        )}
        {isOpened && (
          <div className={`suggestions ${isRequesting ? 'requesting' : ''} ${classNameSuggestions || ''}`}>
            {isRequesting ? (
              <Loader small />
            ) : (
              cutSuggestions.map((_item, _key) => (
                <div
                  key={_key}
                  className={`suggestion ${classNameSuggestion || ''}`}
                  onClick={() => this.handleSelectSuggestion(_item)}
                >
                  <Typography>{this.getHighlightedSuggestion(_item[suggestionsKey])}</Typography>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AutocompleteInput;
