import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  diff2name,
  mode2String,
  theme2name,
  type2name,
} from '../../utils/translate';
import { slideTypes } from '../../constants';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  top: draggableStyle.top - 80,
  left: draggableStyle.left - 60,
  opacity: isDragging ? 0.4 : 1,
});

const getListStyle = () => ({
  display: 'flex',
  overflow: 'hidden',
  padding: '15px 10px',
});

@withRouter
class Dnd extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.state = {
      items: this.props.items,
      isNewMenuOpened: false,
      start: 0,
      selected: 0,
    };
  }

  componentDidMount = () => this.setState({ items: this.props.items })

  componentWillUpdate = (nextProps) => {
    if (this.props.items.length !== nextProps.items.length) {
      this.setState({ items: nextProps.items });
    }
  }

  handleNewClick = (e, type) => {
    e.stopPropagation();
    const { items, addSlide } = this.props;
    addSlide(type);
    this.setState({
      start: items.length - 7,
      selected: items.length,
      dragged: null,
      isNewMenuOpened: false,
    });
  };

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId === 'remove') {
      const deleteItem = this.state.items[result.source.index];
      this.setState(
        (state) => ({ items: state.items.filter((_, index) => index !== result.source.index) }),
        // () => this.props.onDragEnd([...this.state.items.map(item => item.id)])
        () => this.props.handleDragDelete(deleteItem),
      );
    } else {
      const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index,
      );
      this.setState(
        () => ({ items }),
        () => this.props.onDragEnd([...this.state.items.map((item) => item.id)], result.destination.index),
      );
    }
  }

  handleOpenNewMenu = () => {
    this.setState({ isNewMenuOpened: true });
  };

  handleCloseNewMenu = () => {
    this.setState({ isNewMenuOpened: false });
  };

  handleSlideClick = (index) => this.setState({
    selected: index,
    start: 4,
  })

  handleNextClick = () => this.setState((state) => ({
    start:
        state.start < this.props.items.length - 8
          ? state.start + 1
          : state.start,
  }));

  handlePrevClick = () => this.setState((state) => ({
    start: state.start > 0 ? state.start - 1 : state.start,
  }));

  render() {
    const { items, start, isNewMenuOpened } = this.state;
    const { location: { pathname } } = this.props;
    // TODO: refactor the way for calculate selected element


    return (
      <div className={`slidesPreview ${this.props.isRequesting ? 'requesting' : 'requesting_out'}`}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div onClick={this.handlePrevClick} className="leftArrow" />
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable key={`id${index}`} draggableId={`id${index}`} index={index}>
                    {(provided, snapshot) => (
                      <Link to={item.path}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index}
                          onClick={() => this.props.onSlideClick(index)}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          className={`slide ${pathname.split('/').pop() === item.id ? 'current ' : ''}${item.type}`}
                        >
                          {!this.props.isRequesting && <span>{`${index + 1} ${type2name(item.type)}`}</span>}
                        </div>
                      </Link>
                    )}
                  </Draggable>
                )).filter((_, index) => index >= start && index <= start + 7)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="addSlide" onClick={this.handleOpenNewMenu}>
            {isNewMenuOpened ? (
              <div className="newSlideMenu">
                {Object.values(slideTypes).map((type, i) => (
                  <Typography
                    onClick={(e) => this.handleNewClick(e, type)}
                    key={i}
                  >
                    {
                      type2name(type.toLowerCase())
                    }
                  </Typography>
                ))}
              </div>
            ) : null}
          </div>
          <div onClick={this.handleNextClick} className="rightArrow" />
          <Droppable droppableId="remove">
            {
              (provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="trashContainer"
                >
                  Переместите сюда для удаления
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default Dnd;
