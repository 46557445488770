import React, { Component, Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import './style.scss';
import { CourseContainer } from '../../containers';
import { Loader, Pdf } from '..';
import { theme2name, diff2name } from '../../utils/translate';

class Course extends Component {
  constructor(props) {
    super(props);
    this.courseDescription = React.createRef();
    this.state = {
      isInfoOpened: false,
    };
  }

  render() {
    const { props } = this;

    return (
      <article className={`course${props.access ? ' accessed' : ''}`}>
        <div className="course__content">
          <img src={props.picture} alt={`Обложка курса ${props.title}`} />
          <Typography
            className={`rewardPoints${props.isFinished ? ' complete' : ''}`}
          >
            <div className="symbol">{props.isFinished ? '✔' : '☆'}</div>
            {props.isFinished ? 'Курс пройден' : `${props.rewardPoints} баллов`}
          </Typography>
          <div className="course__text" ref={this.courseDescription}>
            <h1 className="title">{props.title}</h1>
            <Typography className="desc">{props.description}</Typography>
            {!props.onEditClick && props.access && (
            <Typography className="count">
              <span>Кол-во попыток: </span>
              {props.access.remainingAttempts}
              /
                {props.access.maximumNumberOfAttempts}
            </Typography>
            )}
          </div>
          <div
            className={`buttonRow ${
              props.isFinished
                ? 'finished'
                : props.access.remainingAttempts === 0
                  ? 'failed'
                  : ''
            }`}
          >
            {!props.isFinished
              && !props.onEditClick
              && !props.access.isSessionLimitReached
              && props.access.remainingAttempts > 0 && (
                <Button
                  onClick={props.access ? props.onClick : null}
                  className="startCourseButton"
                >
                  Начать
                </Button>
            )}
            {props.isFinished ? (
              <Pdf
                course={props.allCourse}
                user={props.user}
                className="startCourseButton finished"
                title="Скачать сертификат"
              />
            ) : (
              props.access.remainingAttempts === 0 && (
              <Button
                disabled
                onClick={() => null}
                className="startCourseButton failed"
              >
                Не пройден
              </Button>
              )
            )}
            {!sessionStorage.is_anon && !props.onEditClick && !props.access && (
            <Button className="startCourseButton">Недоступен</Button>
            )}
            {props.onEditClick && (
            <Button
              onClick={() => props.onEditClick(props.id)}
              className="startCourseButton"
            >
              Редактировать
            </Button>
            )}
          </div>
        </div>
      </article>
    );
  }
}

export default Course;
