import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { slidesActions } from '../actions';
import { Audience } from '../pages/editor/';
import { Loader } from '../component';

@withRouter
class AudienceContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { slidesActions: { getUsers } } = this.props;
    // onSlideUpdate(slideId)
    getUsers();
  }

  render() {
    const { users: { isRequesting } } = this.props;

    return isRequesting ? <Loader /> : <Audience {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceContainer);
