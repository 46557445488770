import React, { Component } from 'react';

class DeletedQuestion extends Component {
  render() {
    const { handleRestoreQuestion, question } = this.props;
    const { text } = question;

    return (
      <div className="contentWrapper contentWrapper_delete">
        <span className="question-delete__text">
          Удален вопрос {text}
        </span>
        <span
          className="question-delete__restore"
          onClick={handleRestoreQuestion}
        >
          Восстановить
        </span>
      </div>
    );
  }
}

export default DeletedQuestion;
