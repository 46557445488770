import React, { Component } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';

class CollateQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      matchingPairs: {
        items: [{ leftOption: '', rightOption: '', isNew: true }],
      },
    };
  }

  componentDidMount = () => this.setState(() => ({ ...this.props.question }));

  handleChangeTitle = (text) => this.setState(
    () => ({ text }),
    () => this.props.onChange(this.state),
  );

  handleAddPair = () => this.setState(
    (state) => ({
      matchingPairs: {
        items: [
          ...state.matchingPairs.items,
          {
            leftOption: '',
            rightOption: '',
            isNew: true,
          },
        ],
      },
    }),
    () => { this.props.validate(this.state); },
  );

  handleChangeAnswer = (indexPair, field, value) => {
    this.setState(
      (state) => ({
        matchingPairs: {
          items: state.matchingPairs.items.map((answer, index) => (indexPair === index ? { ...answer, [field]: value } : answer)),
        },
      }),
      () => {
        this.props.onChange(this.state),
        this.props.validate(this.state);
      },
    );
  };

  handleChangeText = (text) => this.setState(
    (state) => ({ text }),
    () => {
      this.props.onChange(this.state),
      this.props.validate(this.state);
    },
  );

  handleDeletePair = (indexPair) => this.setState(
    () => ({
      matchingPairs: {
        items: this.state.matchingPairs.items.filter(
          (_, index) => index !== indexPair,
        ),
      },
    }),
    () => {
      this.props.onChange(this.state),
      this.props.validate(this.state);
    },
  );

  render() {
    const { indexQuestions, isInvalidInput } = this.props;

    return (
      <div className="contentWrapper">
        <div
          key={`questions_${indexQuestions}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="trashContainer"
            onClick={() => this.props.handleDeleteQuestion(indexQuestions)}
          />
          <Typography variant="h6" id="slider-image">
            Вопрос на сопоставление
          </Typography>
          <TextField
            className="text"
            error={!this.state.text ? isInvalidInput : null}
            value={this.state.text}
            onChange={({ target: { value } }) => this.handleChangeText(value)}
          />
          {this.state.matchingPairs.items.map((pair, indexPair) => (
            <div
              key={indexPair}
              className="question"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextField
                className="text"
                error={!pair.leftOption ? isInvalidInput : null}
                label="Значение"
                value={pair.leftOption.text || pair.leftOption}
                onChange={({ target: { value } }) => this.handleChangeAnswer(indexPair, 'leftOption', value)}
                fullWidth
              />
              <Typography style={{ margin: '0 10px' }}>-</Typography>
              <TextField
                className="text"
                error={!pair.rightOption ? isInvalidInput : null}
                label="Соответствие"
                value={pair.rightOption.text || pair.rightOption}
                onChange={({ target: { value } }) => this.handleChangeAnswer(indexPair, 'rightOption', value)}
                fullWidth
              />
              <div
                className="deleteAnswer"
                onClick={() => this.handleDeletePair(indexPair)}
                style={{ width: '50px', cursor: 'pointer' }}
              />
            </div>
          ))}

          <Button onClick={this.handleAddPair}>+ Вариант</Button>
          <Typography className="info">
            Пользователь при прохождении видит перемешанные варианты и ему
            необходимо установить соответствие
          </Typography>
        </div>
      </div>
    );
  }
}

export default CollateQuestion;
