import { slidesTypes, runTypes, sessionTypes } from '../types';

const initialState = {
  currentSession: {},
  isSuccess: false,
  isRun: false,
  isRequesting: false,
  isRequestingSlideSession: false,
  timer: 0,
  slideTimer: 0,
  passedSeconds: 0,
  course: { slides: { items: [] } },
  course__new: { slides: { items: [] }, cover: {}, access: {} },
  isError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sessionTypes.GET_COURSE_SESSION:
    case sessionTypes.CREATE_SESSION__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case sessionTypes.GET_ACCESS__REQUEST: return {
      ...state,
      isRequesting: true,
      course__new: {},
    };
    case sessionTypes.GET_COURSE_SESSION_SUCCESS:
    case sessionTypes.CREATE_SESSION__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        course__new: {
          ...state.course__new,
          session: { ...action.payload.session },
        },
      };
    case sessionTypes.CLOSE_SLIDE_SESSION:
    case sessionTypes.GET_SLIDE_SESSION:
    case sessionTypes.CREATE_SLIDE_SESSION:
      return {
        ...state,
        isRequestingSlideSession: true,
        isError: false,
      };
    case sessionTypes.GET_SLIDE_SESSION_SUCCESS:
    case sessionTypes.CREATE_SLIDE_SESSION_SUCCESS:
      return {
        ...state,
        isRequestingSlideSession: false,
        currentSession: action.payload,
      };
    case sessionTypes.CLOSE_SLIDE_SESSION_SUCCESS:
      return {
        ...state,
        isRequestingSlideSession: false,
        currentSession: '',
      };
    case sessionTypes.CLOSE_SLIDE_SESSION_FAILURE:
      return {
        ...state,
        isRequestingSlideSession: false,
        isError: true,
      };
    case sessionTypes.GET_ACCESS__SUCCESS: return {
      ...state,
      course__new: action.payload.course,
      isRequesting: false,
    };
    case runTypes.UPDATE_SLIDE_TIMER: return {
      ...state,
      slideTimer: action.payload.timer,
    };
    case runTypes.CALCULATE_TIME: return {
      ...state,
      timer: action.payload.totalTime,
    };
    case runTypes.RESET_RUN: return {
      ...state,
      isRun: false,
      timer: 3600,
    };
    case slidesTypes.OPEN_COURSE: return {
      ...state,
      course: action.payload.course,
    };
    case runTypes.START_COURSE: return {
      ...state,
      isRun: true,
    };
    case runTypes.PREV_SLIDE: return {
      ...state,
      isFinished: false,
      current: action.payload.current,
    };
    case runTypes.UPDATE_TIMER: return {
      ...state,
      timer: action.payload.timer,
    };
    default: return state;
  }
};
