import React, { Component } from 'react';
import './CourseLinkAccess.scss';
import { DebounceInput } from 'react-debounce-input';
import {
  Select,
  Typography,
  Button,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Loader } from '../../../component';
import * as linkTypes from '../../../constants/linkTypes';
import {
  formatInviteStatus,
  getInviteStatus,
  copyTextToClipboard,
} from '../../../utils';
import LinksListContainer from '../../../containers/LinksList';


const MIN_SEARCH_LENGTH = 1;

const Pharm = ({ noPharm, enterPharmName, ...props }) => (
  <div
    onClick={props.onClick}
    className={`pharm${props.isCurrent ? ' current' : ''}`}
  >
    {!(noPharm || enterPharmName) && (
      <div className={`pharmacyIcon ${props.type}`} />
    )}
    <div>
      <Typography>{props.title}</Typography>
      {!(noPharm || enterPharmName) && (
      <>
        <span>
          <Typography>
            {props.address
              .split(', ')
              .splice(0, 2)
              .join(', ')}
          </Typography>
        </span>
        <span>
          <Typography>
            {props.address
              .split(', ')
              .filter((_, index) => index > 1)
              .join(', ')}
          </Typography>
        </span>
      </>
      )}
    </div>
  </div>
);

const PharmList = (props) => {
  const {
    current,
    handlePharmFilterChange,
    handleSaveAllAccessesClick,
    handleSaveAllInvitesClick,
    handlePharmClick,
    pharmQuery,
    pharms: { items, isRequesting },
  } = { ...props };

  return (
    <div className="pharms_wrapper">
      <DebounceInput
        className="pharmFinder"
        onChange={handlePharmFilterChange}
        placeholder="&#x1F50D; Введите название аптеки"
        debounceTimeout={400}
      />
      {!isRequesting && items.length < 1 && pharmQuery.length > 0
        ? (<Typography>По заданному названию аптеки не найден</Typography>)
        : null}
      <div className="pharms">
        {isRequesting ? (
          <Loader />
        ) : items.length > 0 ? (
          items.map((institution) => (
            <Pharm
              onClick={() => handlePharmClick(institution)}
              isCurrent={current !== null ? current.id === institution.id : false}
              key={institution.id}
              {...institution}
            />
          ))
        ) : (
          false
        )}
      </div>
      <Button
        variant="contained"
        onClick={handleSaveAllAccessesClick}
        className="button save"
      >
        <i className="material-icons">save</i>
        пройденные ссылки
      </Button>
      <Button
        variant="contained"
        onClick={handleSaveAllInvitesClick}
        className="button save"
      >
        <i className="material-icons">save</i>
        неиспользованные ссылки
      </Button>
    </div>
  );
};

class CourseLinkAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pharmQuery: '',
      current: null,
      linkType: linkTypes.ANON,
      linkCount: 1,
      copiedLink: null,
    };
  }

  handlePharmClick = (institution) => {
    const {
      getInvitations,
    } = this.props;
    this.setState({
      current: institution,
    });
    getInvitations({ link: institution.courseInvites });
  };

  handleCreateLinkClick = () => {
    const { current, linkType, linkCount } = this.state;
    const {
      createLinks,
      editor: {
        course: { id },
      },
    } = this.props;

    createLinks(linkCount, {
      course: `/v1/courses/${id}`,
      title: 'Тестовое описание ссылки',
      type: linkType,
      medicalInstitution: current.self,
    });
  };

  handleCopyLinkClick = (invite, index) => {
    this.setState({ copiedLink: index });
    copyTextToClipboard(
      `${location.host}/invites?id=${invite.id}&type=${invite.type}`,
    );
  };

  handlePharmFilterChange = (event) => {
    this.setState(
      {
        pharmQuery: event.target.value,
      },
      () => {
        this.getPharmsWithFilter(this.state.pharmQuery);
      },
    );
  };

  getPharmsWithFilter = (pharmQuery) => {
    const {
      getPharms,
      pharms: { isRequesting },
    } = this.props;
    if (isRequesting) {
      this.fetchController.abort();
    }
    this.fetchController = new AbortController();
    if (pharmQuery.length >= MIN_SEARCH_LENGTH) {
      getPharms(pharmQuery, {
        fetchOptions: { signal: this.fetchController.signal },
      });
    }
  };

  handleLinkTypeChange = ({ target: { value } }) => this.setState({ linkType: value });

  handleLinkCountChange = ({ target: { value } }) => this.setState({ linkCount: value });

  handleSaveAllAccessesClick = () => {
    const {
      editor: { course },
    } = this.props;

    const accessesLink = document.createElement('a');
    accessesLink.setAttribute('href', course.courseStatisticsByAccessesCsv.url);
    accessesLink.setAttribute(
      'download',
      'Статистика про пройденным ссылкам.csv',
    );
    document.body.appendChild(accessesLink);
    accessesLink.click();
    // getStats(id)
  };

  handleSaveAllInvitesClick = () => {
    const {
      editor: {
        course: { courseStatisticsByInvitesWithoutAccessesCsv },
      },
    } = this.props;

    if (courseStatisticsByInvitesWithoutAccessesCsv) {
      const invitesLink = document.createElement('a');
      invitesLink.setAttribute(
        'href',
        courseStatisticsByInvitesWithoutAccessesCsv.url,
      );
      invitesLink.setAttribute(
        'download',
        'Статистика про неиспользованным ссылкам.csv',
      );
      document.body.appendChild(invitesLink);
      invitesLink.click();
    }
    // getStats(id)
  };

  render() {
    const {
      pharms,
      invitations,
    } = this.props;
    const {
      copiedLink,
      current,
      linkCount,
      linkType,
      pharmQuery,
    } = this.state;

    return (
      <div className="target_wrapper">
        <PharmList
          current={current}
          handlePharmClick={this.handlePharmClick}
          handlePharmFilterChange={this.handlePharmFilterChange}
          handleSaveAllAccessesClick={this.handleSaveAllAccessesClick}
          handleSaveAllInvitesClick={this.handleSaveAllInvitesClick}
          pharms={pharms}
          pharmQuery={pharmQuery}
        />
        <LinksListContainer
          copiedLink={copiedLink}
          current={current}
          handleCopyLinkClick={this.handleCopyLinkClick}
          handleCreateLinkClick={this.handleCreateLinkClick}
          handleLinkCountChange={this.handleLinkCountChange}
          handleLinkTypeChange={this.handleLinkTypeChange}
          invitations={invitations}
          linkCount={linkCount}
          linkType={linkType}
        />
      </div>
    );
  }
}

export default CourseLinkAccess;
