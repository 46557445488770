// Object.prototype.eq = function (obj) {
//   return JSON.stringify(obj) === JSON.stringify(this)
// }
Array.prototype.shuffle = function () {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [this[i], this[j]] = [this[j], this[i]];
  }

  return this;
};


export const shuffleArrayOfObject = (array, objName) => {
  for (let i = array.length - 1; i > 0; i--) {
    const middle = array[i][objName];
    const j = Math.floor(Math.random() * (i + 1));
    array[i][objName] = array[j][objName];
    array[j][objName] = middle;
  }

  return array;
};

export const getArrayWithSwappedElements = (array, currentIndex, targetIndex) => {
  const newRightOptions = [...array];
  [newRightOptions[targetIndex], newRightOptions[currentIndex]] = [
    newRightOptions[currentIndex],
    newRightOptions[targetIndex],
  ];

  return newRightOptions;
};

String.prototype.cut = function (max) {
  return (
    `${this.split('')
      .filter((_, index) => index < max)
      .join('')}...`
  );
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};
export const validatePhone = (phone) => phone.length === 11 && /[0-9]/.test(phone);

export const checkAuth = () => Boolean(
  localStorage.getItem('bearer_token')
      || sessionStorage.getItem('bearer_token'),
);

export const checkInvite = (location) => location.href.indexOf('invites') !== -1;

export const formatQuestions = (questions) => questions.map((question) => ({
  ...question,
  type: question.matchingPairs
    ? 'matching'
    : !question.answers.items[0]
      ? 'text'
      : question.answers.items[0].image
        ? 'image'
        : 'text',
  mode: 'write',
  answers: question.answers,
  matchingPairs: question.matchingPairs,
}));

export const collection2csv = (collection) => `data:text/csv;charset=utf-8,\n${
  collection
    .map((line) => Object.keys(line)
      .map((key) => line[key])
      .join(', '))
    .join('\n')}`;

export const formatTime = seconds => {
  if (seconds > 59) {
     return `${Math.floor(seconds/60)} мин ${seconds%60} сек`
  } else {
    return `${seconds} сек`
  }
};

export const formatInviteStatus = (status) => {
  switch (status) {
    case 'without_access':
      return 'Не приступал';
    case 'process':
      return 'В процессе';
    case 'failed':
      return 'Не пройдено';
    case 'complete':
      return 'Пройдено';
    default:
      return null;
  }
};
export const getInviteStatus = (invite) => {
  if (invite.courseAccess === null) {
    return 'without_access';
  } if (invite.courseAccess.results) {
    if (invite.courseAccess.results.finished === true) {
      return 'complete';
    } if (invite.courseAccess.results.finished === false) {
      return 'failed';
    }
  } else {
    return 'process';
  }
};
export const copyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'absolute';
  textArea.style.top = -1000;
  textArea.style.background = 'transparent';
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Copying text command was ${msg}`);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};

export const beautifySec = (sec) => {
  if (sec === 0) return '0';
  if (sec < 0.5) return '20';
  if (sec > 0.5) return '40';

  return '0';
};
