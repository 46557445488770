import { coursesTypes } from '../types';

const initialState = {
  error: false,
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ERROR':
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    case 'RESET_ERROR':
      return initialState;
    default:
      return state;
  }
};
