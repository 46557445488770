import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';

function ButtonIcon({ icon, onClick, fontSize }) {
  const SvgOfIcon = withStyles({
    root: {
      opacity: '0.4',
      '&:hover': {
        opacity: '0.9',
      },
    },
  })(icon);

  return (
    <Icon onClick={onClick} fontSize={fontSize}>
      <SvgOfIcon fontSize={fontSize} />
    </Icon>
  );
}

export default ButtonIcon;
