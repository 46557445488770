import React, { Component, Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Header, Sidebar } from '../component';
import { Desktop } from '../routes';
import './Root.scss';
import CustomizedSnackbars from '../component/snackbars/snackbars';
import withPolling from '../HOC/withPolling';

@withRouter
class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpened: false,
    };
  }

  toggleSidebar = () => this.setState({ isSidebarOpened: !this.state.isSidebarOpened });

  closeSidebar = () => this.setState({ isSidebarOpened: false })

  handleLogoutClick = () => this.props.authActions.logout()

  render() {
    return (
      <div
        className="root"
      >
        <div id="portalContainer" />
        <CustomizedSnackbars />
        <Header
          user={this.props.user}
          onSidebarClick={this.toggleSidebar}
          onLogoutClick={this.handleLogoutClick}
          isSidebarOpened={this.state.isSidebarOpened}
        />
        <div onClick={this.state.isSidebarOpened ? this.closeSidebar : null} className={`content ${this.state.isSidebarOpened ? 'smooth' : ''}`}>
          <Switch>
            <Desktop />
          </Switch>
        </div>
        {!sessionStorage.is_anon && <Sidebar opened={this.state.isSidebarOpened} />}
      </div>
    );
  }
}

export default withPolling(Root);
