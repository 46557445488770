import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
// import "../Editor.scss";
import {
  Typography,
} from '@material-ui/core';
import { DndList } from '../../component';
import { SlideContainer } from '../../containers';

@withRouter
class Slides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 1,
      start: 0,
      isNewMenuOpened: false,
    };
  }

  handleSlidePreviewClick = (index) => {
    this.setState(() => ({ selected: index, dragged: null }));
  };

  handleDragEnd = (items, destination) => {
    const { slidesActions: { reorderSlides }, editor: { course } } = this.props;
    reorderSlides(items, course.self);
    this.setState({ selected: destination || 1 });
  }

  handleDragDelete = (item, destination) => {
    const { slidesActions: { deleteSlide } } = this.props;
    deleteSlide(item);
    this.setState({ selected: destination || 1 });
  }

  handleSlideUpdate = (id) => this.setState({ selected: this.props.slides.findIndex((item) => item.id === id) })

  render() {
    const { slides, slidesActions, match: { path } } = this.props;
    const { selected, start } = this.state;

    return (
      <div className="editSlides">
        <DndList
          start={start}
          items={slides.map((slide) => ({ ...slide, path: path.split('/')[path.split('/').length - 1] === 'slides' ? `${path}/${slide.id}` : path.replace(':slideId', slide.id) }))}
          addSlide={slidesActions.addSlide}
          selected={selected}
          onDragEnd={this.handleDragEnd}
          handleDragDelete={this.handleDragDelete}
          onSlideClick={this.handleSlidePreviewClick}
          isRequesting={slides.isRequestingReorder}
        />
        {slides.length > 0
          ? path.split('/')[path.split('/').length - 1] === 'slides'
            ? (
              <div className="noSlides">
                <Typography>
                  Выберите блок из списка сверху
                </Typography>
              </div>
            )
            : <SlideContainer {...this.props} onSlideUpdate={this.handleSlideUpdate} />
          : (
            <div className="noSlides">
              <Typography>
                В вашем курсе пока нет блоков. Создайте первый
              </Typography>
            </div>
          )}
      </div>
    );
  }
}

export default Slides;
