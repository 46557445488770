import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import auth from './auth';
import editor from './editor';
import slides from './slides';
import slide from './slide';
import courses from './courses';
import meta from './meta';
import results from './results';
import courseInfo from './editorCourseInfo';
import run from './run';
import error from './error';
import pharms from './pharms';
import timers from './timers';
import invitations from './invitations';
import users from './users';
import stats from './stats';
import slides2save from './slides2save';
import manufacturers from './manufacturers';
import { prompt } from '../component/prompt';
import { alert } from '../component/alert';
import profile from './profile';
import ticket from './ticket.js';
import tickets from './tickets.js';

const rootReducer = combineReducers({
  error,
  auth,
  slide,
  run,
  meta,
  slides,
  courseInfo,
  editor,
  courses,
  results,
  invitations,
  timers,
  pharms,
  stats,
  users,
  slides2save,
  manufacturers,
  prompt,
  alert,
  profile,
  ticket,
  tickets,
  router: routerReducer,
});

export { rootReducer };
