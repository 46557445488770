import React, { Component } from 'react';
import { Avatar } from '@material-ui/core';

import './styles.scss';
import { translateRoles } from '../../constants/roles';

class Profile extends Component {
  render() {
    const {
      item: {
        avatar,
        fullName,
        gender,
        rewardPoints,
        phone,
        email,
        specializations,
        about,
        workPlaces,
        managedMedicalInstitutions,
      },
    } = this.props.profile;

    return (
      <div className="profile__wrapper">
        <div className="row">
          <div className="info__block">
            <Avatar
              src={avatar ? avatar.url : null}
              style={{ width: '60px', height: '60px' }}
            >
              {fullName[0]}
            </Avatar>
            <div className="avatar_field">
              <p className="secondary_text title">Аватар</p>
              <button
                className="main__button"
                style={{ padding: '0 15px' }}
                disabled
              >
                Обновить фотографию
              </button>
            </div>
          </div>
          <div className="info__block gender">
            <p className="secondary_text title">Пол</p>
            <div className="radio_group">
              <input
                type="radio"
                id="choiceGender1"
                disabled
                name="contact"
                value="gender"
                checked={gender === 'female'}
              />
              <label htmlFor="choiceGender1">Женский</label>
              <input
                type="radio"
                id="choiceGender2"
                disabled
                name="contact"
                value="gender"
                checked={gender === 'male'}
              />
              <label htmlFor="choiceGender2">Мужской</label>
            </div>
          </div>
          <div className="info__block balance">
            <p className="secondary_text title">Ваш баланс баллов:</p>
            <p className="secondary_text balls">
              <b style={{ color: '#404040' }}>{rewardPoints}</b>
            </p>
          </div>
        </div>
        <div className="info__block full_width_card contact">
          <p className="secondary_text">Ваше имя</p>
          <p>{fullName}</p>
          <button className="main__button" disabled>
            Изменить
          </button>
          <p className="secondary_text">Мобильный телефон</p>
          <p>{phone}</p>
          <button className="main__button" disabled>
            Изменить
          </button>
          <p className="secondary_text">Адрес электронной почты</p>
          <p>{email}</p>
          <button className="main__button" disabled>
            Изменить
          </button>
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">Ваши специализации</p>
          {specializations.items > 0
            ? specializations.items.map((item) => <p>{item.title}</p>)
            : 'Не указано'}
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">Ваша роль в системе</p>
          <p>
            {this.props.profile.item.roles.map((item) => translateRoles[item]).join(', ')}
          </p>
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">Ваши интересы</p>
          <p>{about === null ? 'Не указано' : about}</p>
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">Место работы и дожность</p>
          {workPlaces.items.length > 0
            ? workPlaces.items.map((a) => (
              <div className="workPlaces__row">
                {<p>{a.medicalInstitution.title}</p>}
                {<p>{a.position.title}</p>}
              </div>
            ))
            : 'Не указано'}
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">
            Вы управляете этими медицинскими учреждениями
          </p>
          <div className="institution">
            {managedMedicalInstitutions.items.length > 0
              ? managedMedicalInstitutions.items.map((item) => (
                <p>{item.title}</p>
              ))
              : 'Не указано'}
          </div>
        </div>
        <button className="main__button save__button" disabled>
          Сохранить
        </button>
      </div>
    );
  }
}

export default Profile;
