import { sessionTypes } from '../types';
import { get, post } from '../api';
import { courseSessionStatuses } from '../pages/run/constants';

export const createSession = (access) => (dispatch, getState) => {
  dispatch((() => ({ type: sessionTypes.CREATE_SESSION__REQUEST }))());
  post(`${process.env.BASE_API_URL}/v1/course-session-start-requests`, {
    courseAccess: access,
  }).then((session) => {
    dispatch(
      (() => ({
        type: sessionTypes.CREATE_SESSION__SUCCESS,
        payload: {
          session: {
            self: session.courseSession,
            status: courseSessionStatuses.UNFINISHED,
          },
        },
      }))(),
    );
  });
};

export const getCourseSession = (session) => (dispatch) => {
  dispatch((() => ({ type: sessionTypes.GET_COURSE_SESSION }))());
  get(`${process.env.BASE_API_URL}${session}`).then((session) => {
    dispatch(
      (() => ({
        type: sessionTypes.GET_COURSE_SESSION_SUCCESS,
        payload: {
          session,
        },
      }))(),
    );
  });
};

export const getSlideSession = (session) => (dispatch) => {
  dispatch((() => ({ type: sessionTypes.GET_SLIDE_SESSION }))());
  get(`${process.env.BASE_API_URL}${session}`).then((session) => {
    dispatch(
      (() => ({
        type: sessionTypes.GET_SLIDE_SESSION_SUCCESS,
        payload: session,
      }))(),
    );
  });
};

export const createSlideSession = (courseSession) => (dispatch) => {
  dispatch((() => ({ type: sessionTypes.CREATE_SLIDE_SESSION }))());
  post(`${process.env.BASE_API_URL}/v1/start-next-slide-session-requests`, {
    courseSession,
  }).then((session) => {
    dispatch(
      (() => ({
        type: sessionTypes.CREATE_SLIDE_SESSION_SUCCESS,
        payload: {
          ...session,
          ...session.slideSession,
        },
      }))(),
    );
  });
};

export const closeSlideSession = (data, options = {}) => (dispatch) => {
  dispatch({ type: sessionTypes.CLOSE_SLIDE_SESSION });
  post(
    `${process.env.BASE_API_URL}/v1/course-slide-session-completions`,
    data,
  ).then(() => {
    dispatch({
      type: sessionTypes.CLOSE_SLIDE_SESSION_SUCCESS,
    });
    if (options.isFinish) {
      dispatch(getCourseSession(options.courseSession));
    }
    if (options.withCreateSlideSession) {
      dispatch(createSlideSession(options.courseSession));
    }
  })
    .catch(() => {
      dispatch({
        type: sessionTypes.CLOSE_SLIDE_SESSION_FAILURE,
      });
    });
};

export const getAccess = (access) => (dispatch, getState) => {
  dispatch((() => ({ type: sessionTypes.GET_ACCESS__REQUEST }))());
  const resourses = getState().meta.resources;
  get(`${resourses.accesses}/${access}`).then((access) => {
    if (access.activeSession) {
      getExistSession(access, resourses).then((course) => {
        dispatch(
          (() => ({
            type: sessionTypes.GET_ACCESS__SUCCESS,
            payload: {
              course,
            },
          }))(),
        );
      });
    } else {
      getCourseAccess(access, resourses).then((course) => {
        dispatch(
          (() => ({
            type: sessionTypes.GET_ACCESS__SUCCESS,
            payload: {
              course,
            },
          }))(),
        );
      });
    }
  });
};

const getExistSession = (access, resourses) => new Promise((resolve) => {
  get(
    `${process.env.BASE_API_URL}${access.activeSession}`,
  ).then((session) => {
    getCourseAccess(access, resourses).then((course) => {
      resolve({
        ...course,
        access: {
          ...access,
        },
        session,
      });
    });
  });
});

const getCourseAccess = (access, resourses) => new Promise((resolve) => {
  get(`${resourses.courses}/${access.course.split('/').pop()}`).then(
    (course) => {
      get(`${process.env.BASE_API_URL}${course.slides}`).then((slides) => {
        resolve({
          ...course,
          slides,
          access,
          session: null,
        });
      });
    },
  );
});
