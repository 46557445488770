import { ticketsTypes } from '../types';
import { get, post, postFile } from '../api';
import {
  OPEN,
  CLOSED,
  IN_WORK,
  NEEDS_ADDITIONAL_INFO,
} from '../constants/tickets';
import { rolesConstants } from '../constants';

export const getTickets = (options = {}) => (dispatch, getState) => {
  if (!options.isHiddenLoader) dispatch({ type: ticketsTypes.GET_TICKETS__REQUEST });
  const { meta: { user: { roles = [], self } } } = getState();
  const isSupportAgent = roles.includes(rolesConstants.roles.ROLE_SUPPORT_AGENT);
  get(
    isSupportAgent
      ? `${process.env.BASE_API_URL}/v1/support-tickets`
      : `${process.env.BASE_API_URL}/v1/support-tickets?author=${self}`,
  ).then((tickets) => {
    dispatch(
      (() => ({
        type: ticketsTypes.GET_TICKETS__SUCCESS,
        payload: { items: tickets.items },
      }))(),
    );
  });
};

export const createTicket = (ticket) => (dispatch) => {
  dispatch((() => ({ type: ticketsTypes.CREATE_TICKET__REQUEST }))());
  post(
    `${process.env.BASE_API_URL}/v1/support-ticket-creation-requests`,
    ticket,
  ).then(() => {
    dispatch((() => ({ type: ticketsTypes.CREATE_TICKET__SUCCESS }))());
    dispatch(getTickets());
  });
};

export const sendMessageForTicket = (
  { ticket, text },
  attachments,
) => (dispatch) => {
  dispatch((() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT }))());
  if (attachments.length) {
    Promise.all(
      attachments.map((attachment) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, {
        file: attachment,
        type: 'comment_attachment',
      })),
    ).then((files) => {
      createComment({
        text,
        type: 'support_ticket',
        attachments: {
          items: files.map(({ self }) => self),
        },
        supportTicket: ticket,
      }).then((_responce) => {
        dispatch(
          (() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT_SUCCESS }))(),
        );
        dispatch(getTicket(ticket.split('/').pop()));
      });
    });
  } else {
    createComment({
      text,
      type: 'support_ticket',
      supportTicket: ticket,
    }).then((_responce) => {
      dispatch(
        (() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT_SUCCESS }))(),
      );
      dispatch(getTicket(ticket.split('/').pop()));
    });
  }
};

export const getTicket = (ticketId, options = {}) => (dispatch) => {
  if (!options.isHiddenLoader) dispatch({ type: ticketsTypes.GET_TICKET__REQUEST });
  get(`${process.env.BASE_API_URL}/v1/support-tickets/${ticketId}`).then(
    (ticket) => {
      dispatch({
        type: ticketsTypes.GET_TICKET__SUCCESS,
        payload: {
          ticket: {
            ...ticket,
            comments: {
              ...ticket.comments,
              items: ticket.comments.items.reverse(),
            },
          },
        },
      });
    },
  );
};

export const changeTicketStatus = (status, message, ticket) => (dispatch) => {
  dispatch((() => ({ type: ticketsTypes.CHANGE_TICKET_STATUS__REQUEST }))());
  const status2path = (_status) => {
    switch (_status) {
      case OPEN:
        return '/v1/support-ticket-reopen-requests';
      case CLOSED:
        return '/v1/support-ticket-close-requests';
      case NEEDS_ADDITIONAL_INFO:
        return '/v1/support-ticket-request-additional-info-requests';
      case IN_WORK:
        return '/v1/support-ticket-in-work-requests';
      default:
        return '';
    }
  };
  post(`${process.env.BASE_API_URL}${status2path(status)}`, {
    supportTicket: ticket,
    message,
  }).then((_response) => {
    dispatch(
      (() => ({
        type: ticketsTypes.CHANGE_TICKET_STATUS__SUCCESS,
        payload: {},
      }))(),
    );
    dispatch(getTicket(_response.supportTicket.split('/').pop()));
  });
};

export const createComment = (data) => post(`${process.env.BASE_API_URL}/v1/comments`, data);
