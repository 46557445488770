import * as promptTypes from './actionTypes';

export const openDeleteCoursePrompt = () => (dispatch, getState) => {
  dispatch((() => ({ type: promptTypes.DELETE_COURSE_OPEN }))());
};

export const closeDeleteCoursePrompt = () => (dispatch, getState) => {
  dispatch((() => ({ type: promptTypes.DELETE_COURSE_CLOSE }))());
};

export const openDeleteLinkPrompt = (inviteId) => (dispatch, getState) => {
  dispatch((() => ({ type: promptTypes.DELETE_LINK_OPEN, payload: { inviteId: inviteId } }))());
};

export const closeDeleteLinkPrompt = () => (dispatch, getState) => {
  dispatch((() => ({ type: promptTypes.DELETE_LINK_CLOSE }))());
};
