import React from 'react';
import { Button, Typography } from '@material-ui/core';
import './style.scss';

function Filter({
  isOpen, values, value, onClick, toggleButton, isActive,
}) {
  return (
    <div className={`filters ${isOpen ? 'opened' : ''} ${isActive ? 'active' : ''}`}>
      <Button onClick={toggleButton}>
        <i />
        Фильтры
      </Button>
      <div className="list">
        {Object.keys(values).map((_filter, _index) => (
          <div
            key={_index}
            className={value === _filter ? 'selected' : ''}
            onClick={value === _filter ? () => onClick() : () => onClick(_filter)}
          >
            <div className={_filter}>&nbsp;</div>
            <Typography>{values[_filter]}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Filter;
