import React from 'react';
import { createPortal } from 'react-dom';
import './style.scss';

function Modal({ closeModal, children }) {
  return createPortal(
    <div>
      <div className="modal" onClick={closeModal} />
      <section className="modal__main">{children}</section>
    </div>,
    document.querySelector('#modal-dom'),
  );
}

export default Modal;
