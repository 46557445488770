import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { slidesActions, saveSlidesActions } from '../actions';
import { Slide } from '../pages';

class SlideContainer extends Component {

  componentDidMount = () => {
    const { slidesActions: { getSlide, getSlideFromModel }, match: { params: { slideId } } } = this.props;
    if (slideId) {
      if (slideId.indexOf('new') === -1) {
        getSlide(slideId);
      } else {
        getSlideFromModel(slideId);
      }
    }
  }

  render() {
    const {
      slide, slidesActions, editor, editorActions, saveSlidesActions,
    } = this.props;

    return (
      <Slide
        slide={slide}
        slidesActions={slidesActions}
        saveSlidesActions={saveSlidesActions}
        editor={editor}
        editorActions={editorActions}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  slide: state.slide,
});

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
  saveSlidesActions: bindActionCreators(saveSlidesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SlideContainer);
