import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';


class Text extends Component {
  constructor(props) {
    super(props);
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
    });
  }

  render() {
    return (
      <div className="textTest">
        <div className="text__wrapper">
          <Typography variant="h5">{this.props.slide.title}</Typography>
          <ReactMde
            selectedTab="preview"
            value={this.props.slide.text}
            generateMarkdownPreview={(markdown) => Promise.resolve(this.converter.makeHtml(markdown))}
          />
        </div>
      </div>
    );
  }
}

export default Text;
