export const GET_COURSES__REQUEST = 'COURSE_TYPE__GET_COURSES__REQUEST';
export const GET_COURSES__SUCCESS = 'COURSE_TYPE__GET_COURSES__SUCCESS';
export const GET_COURSES__FAILURE = 'COURSE_TYPE__GET_COURSES__FAILURE';
export const GET_COURSES_FOR_USER__REQUEST = 'COURSE_TYPE__GET_COURSES_FOR_USER__REQUEST';
export const GET_COURSES_FOR_USER__SUCCESS = 'COURSE_TYPE__GET_COURSES_FOR_USER__SUCCESS';

export const GET_ACCESSES_FOR_USER__REQUEST = 'COURSE_TYPE__GET_ACCESSES_FOR_USER__REQUEST';
export const GET_ACCESSES_FOR_USER__SUCCESS = 'COURSE_TYPE__GET_ACCESSES_FOR_USER__SUCCESS';
export const GET_ACCESSES_FOR_USER__FAILURE = 'COURSE_TYPE__GET_ACCESSES_FOR_USER__FAILURE';

export const CREATE_COURSE__META = 'COURSE_TYPE__CREATE_COURSE__META';
export const CREATE_COURSE__REQUEST = 'COURSE_TYPE__CREATE_COURSE__REQUEST';
export const CREATE_COURSE__SUCCESS = 'COURSE_TYPE__CREATE_COURSE__SUCCESS';
export const CREATE_COURSE__FAILURE = 'COURSE_TYPE__CREATE_COURSE__FAILURE';
