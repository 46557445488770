import * as alertTypes from './actionTypes';

const initialState = {
  isOpen: false,
  text: '',
  title: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case alertTypes.ALERT_OPEN:
      return {
        ...state,
        isOpen: true,
        text: payload.text,
        title: payload.title,
      };
    case alertTypes.ALERT_CLOSE:
      return {
        ...state,
        isOpen: false,
        text: '',
        title: '',
      };
    default: return state;
  }
};
