import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editorActions } from '../actions';

class CourseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { editorActions: { getCourse } } = this.props;
    getCourse(this.props.course);
  }

  render() {
    const { editor: { isRequesting, course } } = this.props;

    return this.props.children({ course, isRequesting });
  }
}

const mapDispatchToProps = (dispatch) => ({
  editorActions: bindActionCreators(editorActions, dispatch),
});
const mapStateToProps = (state) => ({
  editor: state.editor,
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContainer);
