import React, { Component } from 'react';
import { Slider } from '@material-ui/lab';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
} from '@material-ui/core';
import YouTube from 'react-youtube';
import { beautifySec } from '../../../utils';
import './Tuning.scss';

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeToComplete: props.slide.itemToComplete || 180,
      // pauses: this.props.slide.item.pauses ||  2,
      description: props.slide.item.description || '',
      title: props.slide.item.title || '',
      canBeRewound: props.slide.item.canBeRewound || false,
    };
  }

  validate = () => {
    const { title, description, youTubeVideo } = this.state;

    const valid = (
      title !== ''
      && description !== ''
      && youTubeVideo !== ''
    );

    this.props.setValidSlide(valid);
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.slide.item,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    (this.state !== prevState && this.props.index === prevProps.index)
      && this.props.onEdit({ ...this.state, type: 'youtube_video' }, this.props.index);
  }

  handleSliderChange = (name, value) => {
    this.setState({ [name]: value }, this.validate);
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value }, this.validate);

  handleChangeCheckbox = (e) => this.setState({ [e.target.name]: e.target.checked }, this.validate);

    onReady(event) {
    // access to player in all event handlers via event.target
      event.target.pauseVideo();
    }

    render() {
      const {
        canBeRewound, youTubeVideo, timeToComplete, title, description,
      } = this.state;
      const {isInvalidInput} = this.props;
      const opts = {
        width: '580',
        height: '420',
        playerVars: {
          autoplay: 0,
          rel: 0,
        },
      };

      return (
        <div className="slideContent">
          <div className="slidesSidebar">
            <Typography id="slider-image">ID видео</Typography>
            <TextField
              className="text"
              name="youTubeVideo"
              value={youTubeVideo}
              error={!youTubeVideo ? isInvalidInput : null}
              onChange={this.handleChange}
            />
            <Typography id="slider-image">
              {`Времени на вопрос: ${Math.floor(
                this.state.timeToComplete / 60,
              )} мин ${beautifySec(
                this.state.timeToComplete / 60
              - Math.floor(this.state.timeToComplete / 60),
              )} сек`}
            </Typography>
            <Slider
              classes={{ container: 'slider' }}
              name="timeToComplete"
              onChange={(_, value) => this.handleSliderChange('timeToComplete', value)}
              value={timeToComplete}
              step={20}
              min={20}
              max={3000}
            />
            {/* <Typography id="slider-image">{`Количество пауз в видео: ${
            this.state.pauses
          }`}</Typography>
          <Slider
            classes={{ container: "slider" }}
            name={"pauses"}
            disabled
            onChange={(_, value) => this.handleSliderChange("pauses", value)}
            value={this.state.pauses}
            step={1}
            min={1}
            max={5}
          /> */}
            <FormControlLabel
              control={<Checkbox onChange={this.handleChangeCheckbox} />}
              label="Возможность пропустить блок"
            />
            <FormControlLabel
              name="canBeRewound"
              control={<Checkbox onChange={this.handleChangeCheckbox} checked={canBeRewound} />}
              label="Возможность перемотки"
            />
          </div>
          <div className="mainField">
            <div className="contentWrapper" style={{ padding: '10px' }}>
              <div className="videoDescription" style={{ padding: '10px', width: '50%' }}>
                <TextField
                  className="text"
                  name="title"
                  value={title}
                  error={!title ? isInvalidInput : null}
                  fullWidth
                  label="Название блока"
                  onChange={this.handleChange}
                />
                <TextField
                  className="description"
                  value={description}
                  error={!description ? isInvalidInput : null}
                  name="description"
                  fullWidth
                  multiline
                  label="Описание блока"
                  onChange={this.handleChange}
                />
              </div>
              <YouTube
                videoId={youTubeVideo}
                opts={opts}
                onReady={this._onReady}
              />
            </div>
          </div>
        </div>
      );
    }
}

export default Video;
