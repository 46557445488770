export const translateRoles = {
  ROLE_DEVELOPER: 'Разработчик',
  ROLE_MANAGER: 'Менеджер',
  ROLE_MEDICAL_REPRESENTATIVE: 'Медицинский представитель',
  ROLE_TARGET_AUDIENCE: 'Целевая аудитория',
  ROLE_SUPPORT_AGENT: 'Агент поддержки',
};

export const roles = {
  ROLE_DEVELOPER: 'ROLE_DEVELOPER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_MEDICAL_REPRESENTATIVE: 'ROLE_MEDICAL_REPRESENTATIVE',
  ROLE_TARGET_AUDIENCE: 'ROLE_TARGET_AUDIENCE',
  ROLE_SUPPORT_AGENT: 'ROLE_SUPPORT_AGENT',
};
