import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ButtonIcon from '../../button/ButtonIcon';
import { getArrayWithSwappedElements } from '../../../utils';
import DndVerticalList from '../../dnd/DndVerticalList';

class Pairs extends Component {
  componentDidMount() {
    const {
      answer: { leftOptions, rightOptions },
    } = this.props;
    this.onAnswer(leftOptions, rightOptions);
  }

  handleUp = (index) => {
    const {
      answer: { leftOptions, rightOptions },
    } = this.props;
    if (index === 0) {
      return;
    }
    this.onAnswer(
      leftOptions,
      getArrayWithSwappedElements(rightOptions, index, index - 1),
    );
  };

  handleDown = (index) => {
    const {
      answer: { leftOptions, rightOptions },
    } = this.props;
    if (index === rightOptions.length - 1) {
      return;
    }
    this.onAnswer(
      leftOptions,
      getArrayWithSwappedElements(rightOptions, index, index + 1),
    );
  };

  onAnswer = (leftOptions, rightOptions) => {
    const { onAnswer, question } = this.props;
    onAnswer({
      question: question.self,
      type: 'matching',
      leftOptions,
      rightOptions,
    });
  };

  handleChangeOrder = (items) => {
    const {
      answer: { leftOptions, rightOptions },
    } = this.props;
    this.onAnswer(
      leftOptions,
      items.map(({ id }) => rightOptions.find((options) => id === options.id))
    );
  };

  render() {
    const {
      index: questionIndex,
      question,
      answer: { leftOptions, rightOptions },
    } = this.props;

    return (
      <div className="pairs" style={{userSelect: 'none'}}>
        <h6>
          {questionIndex}
          .
          {question.text}
        </h6>
        <span className="subtitle1">
          Переместите ячейку в правом столбце, так чтобы она соответствовала ячейке в левом столбце
        </span>
            <DndVerticalList
              items={rightOptions.map(({ text, id }, optionIndex) => ({
                id,
                wrapperComponent: (children) => (
                  <div key={id} className="option__wrapper">
                    <div className="option__item">
                      <Typography>{leftOptions[optionIndex].text}</Typography>
                    </div>
                    {children}
                  </div>
                ),
                component: (
                    <div className="option__item option__item_pointer">
                      <Typography>{text}</Typography>
                      <div>
                        <ButtonIcon
                          onClick={() => this.handleUp(optionIndex)}
                          fontSize="large"
                          icon={ExpandLess}
                        />
                        <ButtonIcon
                          onClick={() => this.handleDown(optionIndex)}
                          fontSize="large"
                          icon={ExpandMore}
                        />
                      </div>
                    </div>
                ),
              }))}
              onChange={this.handleChangeOrder}
            />
      </div>
    );
  }
}

export default Pairs;
