import {
  Tabs, Tab, Typography, Toolbar,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

// styles
import './Toolbar.scss';


class SupportTableToolbar extends React.Component {
  render() {
    const {
      handleOpenModal,
      onNewTicketClick,
      withFilter,
    } = this.props;

    return (
      <Toolbar className={`toolbar ${withFilter ? 'withFilter' : null}`}>
        <button className="button" onClick={onNewTicketClick}>
          <AddIcon />
          <Typography style={{ width: 170 }} variant="body1" id="tableTitle" onClick={handleOpenModal}>
            Новое обращение
          </Typography>
        </button>
      </Toolbar>
    );
  }
}

export default SupportTableToolbar;
