import { editorTypes } from '../types';
import {
  get, post, postFile, patch, deleteAPI,
} from '../api';
import { generateCourseData } from '../utils/data4api';
import { history } from '..';
import * as promptTypes from '../component/prompt/actionTypes';
import * as alertTypes from '../component/alert/actionTypes';

const url = process.env.BASE_API_URL;

export const getInvitations = ({ link }) => (dispatch) => {
  dispatch((() => ({ type: editorTypes.GET_INVITATIONS__REQUEST }))());
  get(process.env.BASE_API_URL + link).then((data) => dispatch(
    (() => ({
      type: editorTypes.GET_INVITATIONS__SUCCESS,
      payload: {
        items: data.items.filter((item) => (
          location.pathname.split('/').reverse()[1]
              === item.course.split('/')[item.course.split('/').length - 1]
        )),
      },
    }))(),
  ));
};

export const createLinks = (count, data) => (dispatch) => {
  dispatch((() => ({ type: editorTypes.CREATE_LINKS__REQUEST }))());
  Promise.all(
    Array.from({ length: count }, () => post(`${process.env.BASE_API_URL}/v1/course-invites`, data)),
  ).then((data) => {
    dispatch(
      getInvitations({
        link: `/v1/course-invites?medicalInstitution=${data[0].medicalInstitution}`,
      }),
    );
  });
};

export const getCourse = (course) => (dispatch, getState) => {
  dispatch((() => ({ type: editorTypes.GET_COURSE__REQUEST }))());
  const { resources } = getState().meta;

  return get(
    `${resources.courses || `${process.env.BASE_API_URL}/v1/courses`}/${course}`,
  )
    .then((course) => {
      get(`${process.env.BASE_API_URL}${course.slides}`).then((slides) => {
        get(`${process.env.BASE_API_URL}${course.accesses}`).then((accesses) => {
          dispatch(
            (() => ({
              type: editorTypes.GET_COURSE__SUCCESS,
              payload: {
                course: {
                  ...course,
                  slides,
                  accesses,
                },
              },
            }))(),
          );
        });
      });
    })
    .catch(() => dispatch((() => ({ type: editorTypes.GET_COURSE__FAILURE }))()));
};

export const changeField = (target, value) => ({
  type: editorTypes.CHANGE_FIELD,
  payload: { target, value },
});

export const createCourse = (course) => (dispatch, getState) => {
  dispatch((() => ({ type: editorTypes.CREATE_COURSE__REQUEST }))());
  const _static = [
    { file: course.cover, type: 'course_cover' },
    { file: course.certificate, type: 'course_certificate_background' },
  ].filter((__static) => __static.file && !__static.file.self);

  Promise.all(
    _static.map((__static) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, __static)),
  ).then((response) => {
    const keys = ((files, obj) => files.map((file) => (obj[file.type] = file.self)) && obj)(response, {});
    post(
      `${process.env.BASE_API_URL}/v1/courses`,
      generateCourseData(
        {
          ...course,
          cover: { self: keys.course_cover },
          certificateBackground: { self: keys.course_certificate_background },
        },
        !keys.hasOwnProperty('course_cover'),
        !keys.hasOwnProperty('course_certificate_background'),
      ),
    )
      .then((response) => {
        history.push(`/edit/${response.id}/info`);
        dispatch((() => ({ type: editorTypes.CREATE_COURSE__SUCCESS }))());
        dispatch((() => getCourse(response.id))());
      })
      .catch((e) => {
        dispatch((() => ({ type: editorTypes.CREATE_COURSE__FAILURE }))());
      });
  });
};

export const updateCourse = (course) => (dispatch, getState) => {
  dispatch((() => ({ type: editorTypes.UPDATE_COURSE__REQUEST }))());
  const _static = [
    { file: course.cover, type: 'course_cover' },
    { file: course.certificate, type: 'course_certificate' },
  ].filter((__static) => __static.file && !__static.file.self);
  if (_static.length === 0) {
    const withoutAttempts = ((_course, _modified) => Object.keys(_course).map((key) => {
      if (key !== 'recommendedNumberOfAttempts') {
        _modified[key] = _course.key;
      }
    }) && _modified)(course, {});
    patch(
      `${process.env.BASE_API_URL}${course.self}`,
      generateCourseData(
        course,
        true,
        true,
        true, /* TODO: remove cover switcher */
      ),
    ).then((response) => {
      dispatch(
        (() => ({
          type: editorTypes.UPDATE_COURSE__SUCCESS,
          payload: { data: course },
        }))(),
      );
    });
  } else {
    Promise.all(
      _static.map((__static) => postFile(`${process.env.BASE_API_URL}/v1/static-files`, __static)),
    ).then((responce) => {
      const keys = ((files, obj) => files.map((file) => (obj[file.type] = file.self)) && obj)(responce, {});
      const withoutAttempts = ((_course, _modified) => Object.keys(_course).map((key) => {
        if (key !== 'recommendedNumberOfAttempts') {
          _modified[key] = _course.key;
        }
      }) && _modified)(course, {});
      patch(
        `${process.env.BASE_API_URL}${course.self}`,
        generateCourseData(
          {
            ...course,
            cover: { self: keys.course_cover },
            certificate: { self: keys.course_certificate },
          },
          !keys.hasOwnProperty('course_cover'),
          !keys.hasOwnProperty('course_certificate', true),
        ),
      ).then(() => dispatch(
        (() => ({
          type: editorTypes.UPDATE_COURSE__SUCCESS,
          payload: { data: course },
        }))(),
      ));
    });
  }
};

export const deleteCourse = (course) => (dispatch, getState) => {
  dispatch((() => ({ type: editorTypes.DELETE_COURSE__REQUEST }))());
  const { resources } = getState().meta;
  deleteAPI(
    `${resources.courses || `${process.env.BASE_API_URL}/v1/courses`}/${course}`,
  )
    .then((res) => {
      dispatch(
        (() => ({
          type: editorTypes.DELETE_COURSE__SUCCESS,
          payload: {
            course,
          },
        }))(),
      );
      dispatch((() => ({ type: promptTypes.DELETE_COURSE_CLOSE }))());
      history.push('/courses');
    })
    .catch((res) => {
      dispatch((() => ({ type: editorTypes.DELETE_COURSE__FAILURE }))());
      dispatch((() => ({ type: promptTypes.DELETE_COURSE_CLOSE }))());
      if (res.status === 403) {
        dispatch(
          (() => ({
            type: alertTypes.ALERT_OPEN,
            payload: {
              text: 'Не удалось удалить курс. Курс уже используется.',
              title: 'Ошибка!',
            },
          }))(),
        );
      }
    });
};

export const getStats = (course) => (dispatch) => {
  const results2name = (results) => {
    if (!results) return 'Не начал';
    switch (results.type) {
      case 'successful':
        return 'Пройден';
      case 'expired':
        return 'Провален по истечению времени';
      case 'unsuccessful':
        return 'Не пройден';
      case 'unfinished':
        return 'В процессе';
      default:
        return '---';
    }
  };

  dispatch((() => ({ type: 'get_stats' }))());
  get(`${url}/v1/course-statistics/${course}`).then((stats) => {
    const serialized = stats.courseAccessStatistics.map((line) => ({
      ignored: line.shouldResultsBeIgnored,
      link: line.invite
        ? `http://training.stage.pharm-place.ru/invites?id=${line.invite.id}&type=${line.invite.type}`
        : '---',
      pharmacyGroup: line.pharmacyGroup
        ? line.pharmacyGroup.company.name
        : '---',
      pharmacy: line.medicalInstitution ? line.medicalInstitution.title : '---',
      itn: line.pharmacyGroup ? line.pharmacyGroup.company.itn : '---',
      address: line.medicalInstitution
        ? line.medicalInstitution.address
        : '---',
      name: line.user ? line.user.fullName : '---',
      specialize: line.user
        ? line.user.specializations.items
          .map((item) => item.specialization.title)
          .join(', ')
        : '---',
      startedAt: line.access ? line.access.startedAt : '---',
      finishedAt: line.results ? line.results.completedAt || '---' : '---',
      result: results2name(line.results),
    }));

    const withHeaders = [
      [
        'Не учитывается',
        'Ссылка',
        'Сеть',
        'Клиника/Аптека',
        'ИНН',
        'Адрес',
        'ФИО',
        'Специализация',
        'Дата начала',
        'Дата завершения',
        'Результат',
      ],
      ...serialized.map((invite) => Object.values(invite)),
    ];

    const encoded = `data:text/csv;charset=utf-8,\n${
      encodeURI(
        withHeaders
          .map((line) => line.map((item) => `"${item}"`).join(','))
          .join('\n'),
      )}`;

    const link = document.createElement('a');
    link.setAttribute('href', encoded);
    link.setAttribute('download', 'links.csv');
    document.body.appendChild(link);
    link.click();
  });
};

export const getInvites = (invites) => (dispatch) => {
  dispatch((() => ({ type: 'fasrer' }))());
  Promise.all(invites.map((link) => get(`${url}${link}`))).then((fullInvites) => {
    const invitesWithoutAccess = fullInvites.filter(
      (invite) => !invite.courseAccess,
    );
    const invitesAnon = fullInvites.filter(
      (invite) => invite.courseAccess && invite.courseAccess.type === 'anonymous',
    );
    const invitesReg = fullInvites.filter(
      (invite) => invite.courseAccess && invite.courseAccess.type !== 'anonymous',
    );
    Promise.all(
      invitesReg.map((invite) => get(`${url}${invite.courseAccess.user}`)),
    ).then((users) => {
      const invitesRegWithUsers = invitesReg.map((invite) => ({
        ...invite,
        user: users.find((user) => user.self === invite.courseAccess.user),
      }));
      const invitesWithAccess = fullInvites.filter(
        (invite) => invite.courseAccess,
      );
      Promise.all(
        invitesWithAccess.map((invite) => get(`${url}${invite.courseAccess.sessions}`)),
      ).then((sessions) => {
        const invitesWithUsers = [
          ...invitesAnon,
          ...invitesRegWithUsers,
          ...invitesWithoutAccess,
        ];
        const serializedInvites = invitesWithUsers.map((invite) => ({
          link: `http://training.stage.pharm-place.ru/invites?id=${invite.id}&type=${invite.type}`,
          pharm: invite.medicalInstitution
            ? invite.medicalInstitution.title
            : null,
          pharmAddress: invite.medicalInstitution
            ? invite.medicalInstitution.address
            : null,
          user: invite.user ? invite.user.fullName : null,
          status: invite.courseAccess
            ? invite.courseAccess.courseResults
              ? invite.courseAccess.courseResults.type === 'successful'
                ? 'Пройден'
                : invite.courseAccess.courseResults.type === 'expired'
                  ? 'Провален по иcтечению времени'
                  : invite.courseAccess.courseResults.type === 'unsuccessful'
                    ? 'Провален'
                    : 'В процессе'
              : 'В процессе'
            : 'Не начал',
          startedAt: invite.courseAccess ? invite.courseAccess.startedAt : null,
          finishedAt:
            invite.courseAccess && invite.courseAccess.courseResults
              ? invite.courseAccess.courseResults.completedAt
              : null,
        }));
        // const withHeaders = [...serializedInvites.map(invite => Object.values(invite))]
        const withHeaders = [
          Object.keys(serializedInvites[0]),
          ...serializedInvites.map((invite) => Object.values(invite)),
        ];

        const encoded = `data:text/csv;charset=utf-8,\n${
          encodeURI(
            withHeaders
              .map((line) => line
                .map((item) => (item === null ? '"--"' : `"${item}"`))
                .join(','))
              .join('\n'),
          )}`;

        const link = document.createElement('a');
        link.setAttribute('href', encoded);
        link.setAttribute('download', 'links.csv');
        document.body.appendChild(link);
        link.click();
      });
    });
  });
};
