import React, { Component, Fragment } from 'react';
import { Button } from '@material-ui/core';
import { correctAnswerAmountValues } from '../../../constants/questions';
import Pairs from './Pairs';
import Checkboxes from './Checkboxes';
import Radios from './Radios';

class Test extends Component {
  constructor(props) {
    super(props);
    const {
      slide: { questionsToSolve, questions },
    } = props;
    const shuffled = questions.items
      .shuffle()
      .filter((_, index) => index < questionsToSolve);
    this.state = {
      current: 0,
      questions: shuffled,
      answers: shuffled.map(
        ({ self, leftOptions = {}, rightOptions = {} }) => ({
          question: self,
          answers: [],
          leftOptions: leftOptions.items,
          rightOptions: rightOptions.items,
        }),
      ),
      isSeeAll: questionsToSolve === 1,
      isPreview: false,
      selectedPair: 0,
      selectedPreview: null,
    };
  }

  componentDidMount = () => {
    this.props.onNextQuestionClick({ isSeeAll: this.state.isSeeAll });
  };

  handleAnswerSelect = ({ question, answer, type }, isOnlyOne) => {
    this.saveAnswer(
      this.state.answers.map((stateAnswer) => {
        if (stateAnswer.question === question) {
          return {
            ...stateAnswer,
            type,
            answers: isOnlyOne
              ? [answer]
              : stateAnswer.answers.indexOf(answer) === -1
                ? [...stateAnswer.answers, answer]
                : stateAnswer.answers.filter((__answer) => __answer !== answer),
          };
        }

        return stateAnswer;
      }),
    );
  };

  handleAnswerMatch = ({
    leftOptions, rightOptions, type, question,
  }) => {
    this.saveAnswer(
      this.state.answers.map((answer) => {
        if (answer.question === question) {
          return {
            ...answer,
            type,
            leftOptions,
            rightOptions,
          };
        }

        return answer;
      }),
    );
  };

  saveAnswer = (answers) => {
    this.setState(
      () => ({ answers }),
      () => this.props.onAnswer({
        answers: this.state.answers,
        isSeeAll: this.state.isSeeAll,
      }),
    );
  };

  handlePrevClick = () => this.setState((state) => ({ current: state.current - 1 }));

  handleNextClick = () => this.setState(
    (state) => ({
      current: state.current + 1,
      isSeeAll:
          state.isSeeAll || state.current + 1 === state.questions.length - 1,
    }),
    () => this.props.onNextQuestionClick({ isSeeAll: this.state.isSeeAll }),
  );

  handlePreviewOpen = (_selected) => this.setState({ isPreview: true, selectedPreview: _selected });

  handlePreviewClose = (_selected) => this.setState({ isPreview: false, selectedPreview: null });

  render() {
    const { current, questions, answers } = this.state;

    return (
      <>
        {this.state.isPreview && (
          <div className="preview_wrapper" onClick={this.handlePreviewClose}>
            <img src={this.state.selectedPreview} />
          </div>
        )}
        {questions.length !== 0
          && (questions[current].type === 'matching' ? (
            <Pairs
              answer={answers[current]}
              onAnswer={this.handleAnswerMatch}
              index={current + 1}
              question={questions[current]}
            />
          ) : questions[current].correctAnswersAmount
            === correctAnswerAmountValues.ONE ? (
              <Radios
                onImageClick={this.handlePreviewOpen}
                index={current + 1}
                onAnswer={this.handleAnswerSelect}
                answer={answers[current]}
                question={questions[current]}
              />
            ) : (
              <Checkboxes
                onImageClick={this.handlePreviewOpen}
                index={current + 1}
                onAnswer={this.handleAnswerSelect}
                answer={answers[current]}
                question={questions[current]}
              />
            ))}

        <div className="actionFooter">
          {current > 0 ? (
            <Button onClick={this.handlePrevClick}>Назад</Button>
          ) : (
            <div />
          )}
          {current < questions.length - 1 ? (
            <Button className="nextTestQuestion" onClick={this.handleNextClick}>
              Следующий вопрос
            </Button>
          ) : (
            <div />
          )}
        </div>
      </>
    );
  }
}

export default Test;
