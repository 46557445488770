import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';

import Switch from 'react-router/es/Switch';
import {
  CoursesContainer,
  EditorContainer,
  RunContainer,
  ResultsContainer,
  ResultContainer,
  SupportContainer,
  TicketContainer,
} from '../containers';
import ProfileContainer from '../pages/Profile/ProfileContainer';
import BlockUserError from '../component/error/BlockUserError';

@withRouter
class Desktop extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route path="/create" component={EditorContainer} />
          <Route path="/edit/:courseId" component={EditorContainer} />
          <Route path="/courses" component={CoursesContainer} />
          <Route path="/profile" component={ProfileContainer} />
          <Route exact path="/support" component={SupportContainer} />
          <Route exact path="/support/:ticketId" component={TicketContainer} />
          <Route
            exact
            path="/:context(course-results)/:id"
            component={ResultContainer}
          />
          <Route
            path="/:context(course-results)"
            component={ResultsContainer}
          />

          <Route path="/run/:id" component={RunContainer} />
          <Route path="/error" component={BlockUserError} />
          <Redirect to="/courses" />
        </Switch>
      </>
    );
  }
}

export default Desktop;
