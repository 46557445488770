import { resultsTypes } from '../types';

const initialState = {
  results: [],
  isRequesting: true,
  accessesLength: 0,
  succsessUnsuccsess: 0,
  notInProgress: 0,
  courseStart: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case resultsTypes.GET_RESULTS__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case resultsTypes.GET_RESULTS__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        results: action.results,
        accessesLength: action.accessesLength,
        succsessUnsuccsess: action.succsessUnsuccsess,
        notInProgress: action.notInProgress,
        courseTitle: action.courseTitle,
        courseInvite: action.courseInvite,
      };

    default:
      return state;
  }
};
