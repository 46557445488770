import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import SupportTableToolbar from '../component/toolbar/SupportTableToolbar';
import './Support.scss';
import '../component/comments/Comment.scss';
import { tickets as ticketsConstants } from '../constants';
import ModalMaterial from '../component/modal/ModalMaterial';
import Loader from '../component/loader/index';
import List from '../component/list';

@withRouter
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTicket: {
        title: '',
        body: '',
      },
    };
  }

  componentDidMount = () => {
    const { getTickets } = this.props;
    getTickets();
  }

  handleTicketClick = (ticket) => {
    const { history } = this.props;
    history.push(`/support/${ticket.id}`);
  }

  handleNewTicketClick = () => this.setState({ isNewModalOpened: true })

  handleCloseNewModal = () => this.setState({ isNewModalOpened: false })

  handleCreateTicketClick = () => {
    const { createTicket } = this.props;
    this.setState({
      isNewModalOpened: false,
    }, () => {
      const { newTicket } = this.state;
      createTicket(newTicket);
    });
  }

  render() {
    const { tickets, meta: { user: { roles } } } = this.props;
    const { isNewModalOpened, title, body } = this.state;

    return tickets.isRequesting ? <Loader /> : (
      <Paper className="" elevation={0}>
        <SupportTableToolbar onNewTicketClick={this.handleNewTicketClick} />
        <div className="content__wrapper">
          <ModalMaterial open={isNewModalOpened} onClose={this.handleCloseNewModal}>
            <div className="modal-tickets">
              <div className="close" onClick={this.handleCloseNewModal} />
              <Typography>Новое обращение</Typography>
              <input
                value={title}
                onChange={({ target: { value } }) => this.setState(
                  (state) => ({ newTicket: { ...state.newTicket, title: value } }),
                )}
                placeholder="Тема обращения"
              />
              <br />
              <textarea
                value={body}
                onChange={({ target: { value } }) => this.setState(
                  (state) => ({ newTicket: { ...state.newTicket, body: value } }),
                )}
                placeholder="Опишите проблему"
              />
              <br />
              <button
                type="submit"
                className={tickets.isRequestingCreation ? 'loading' : ''}
                onClick={this.handleCreateTicketClick}
              >
                Создать
              </button>
            </div>
          </ModalMaterial>
          <List
            keys={['theme', 'status', 'date', 'comments']}
            headers={['Тема запроса', 'Статус', 'Дата создания обращения', 'Комментарии']}
            onItemClick={this.handleTicketClick}
            items={tickets.items.map((ticket, ticketIndex) => ({
              theme: ticket.title,
              status: ticketsConstants.translateStatus(ticket.status),
              date: new Date(ticket.createdAt).toLocaleDateString('ru-RU', {
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
              }),
              comments: ticket.comments.items.length,
              index: ticketIndex,
              id: ticket.id,
            }))}
          />
        </div>
      </Paper>
    );
  }
}

export default Support;
