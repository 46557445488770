import React from 'react';
import {
  Card, CardActions, CardContent, Typography, withStyles,
} from '@material-ui/core';
import { rolesConstants, tickets } from '../../constants';
import {
  CLOSED,
  IN_WORK,
  NEEDS_ADDITIONAL_INFO,
  OPEN,
} from '../../constants/tickets';
import { ReactComponent as ClosedIcon } from '../../icons/svg/closed_status.svg';
import { ReactComponent as InWorkIcon } from '../../icons/svg/in_work_status.svg';
import { ReactComponent as OpenIcon } from '../../icons/svg/open_status.svg';
import { ReactComponent as NeedsAdditionalInfoIcon } from '../../icons/svg/needs_additional_info_status.svg';

const TypographyName = withStyles({
  root: {
    color: '#263238',
    fontSize: '1.1em',

  },
})(Typography);

const TypographySecond = withStyles({
  root: {
    color: '#2632389c',
    opacity: 54,
    fontSize: '0.8em',
    fontWeight: '400',
  },
})(Typography);

const TicketComment = ({ comment, user, onImageClick }) => {
  const getChangeStatusMessage = (status, author, message) => {
    let messageTail = null;
    let icon = null;
    switch (status) {
      case CLOSED:
        icon = <ClosedIcon />;
        messageTail = (
          <>
            <b>закрыл(-а)</b>
            {' '}
            обращение
          </>
        );
        break;
      case IN_WORK:
        icon = <InWorkIcon />;
        messageTail = (
          <>
            изменил(-a) статус:
            <b>
              "
              {message}
              "
            </b>
          </>
        );
        break;
      case OPEN:
        icon = <OpenIcon />;
        messageTail = (
          <>
            изменил(-a) статус:
            <b>
              "
              {message}
              "
            </b>
          </>
        );
        break;
      case NEEDS_ADDITIONAL_INFO:
        icon = <NeedsAdditionalInfoIcon />;
        messageTail = (
          <>
            изменил(-a) статус:
            {' '}
            <b>
              "
              {message}
              "
            </b>
          </>
        );
        break;
    }

    return (
      <>
        <div className="status-card__icon">{icon}</div>
        <Typography variant="body2">
          {author}
          {' '}
          {messageTail}
        </Typography>
      </>
    );
  };

  const {
    changedToStatus, postedAt, author, text, attachments,
  } = comment;
  const { fullName, avatar, roles } = author;
  const statusChangeMessage = tickets.translateChangedStatus(changedToStatus);
  const images = attachments.items.filter(({ mimeType }) => mimeType.includes('image'));
  const files = attachments.items.filter(
    ({ mimeType }) => !mimeType.includes('image'),
  );

  return (
    <>
      {
        <TypographySecond
          style={{ textAlign: 'center', marginTop: '5px' }}
          variant="body2"
        >
          {new Date(postedAt).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </TypographySecond>
      }
      {statusChangeMessage && (
      <div className="card__status status-card__change">
        {getChangeStatusMessage(
          changedToStatus,
          fullName,
          statusChangeMessage,
        )}
      </div>
      )}
      <Card className={`card ${user.self === author.self ? 'cardAnswer' : ''}`}>
        <CardContent>
          <div className="personInfo">
            <div
              className="avatar"
              style={{
                backgroundImage: avatar
                  ? `url(${avatar.url})`
                  : null,
              }}
            />
            <div className="text__block">
              <TypographyName variant="body1">{fullName}</TypographyName>
              {roles.find(
                (role) => role === rolesConstants.roles.ROLE_SUPPORT_AGENT,
              ) ? (
                <TypographySecond variant="body2">
                  Сотрудник поддержки
                </TypographySecond>
                ) : null}
            </div>
          </div>
          <Typography variant="body1" className="message">
            {text}
          </Typography>
          <div
            className={`attachments__list attachments__list_${
              attachments.items.length
            }`}
          >
            {images.map((image) => (
              <img
                key={image.id}
                onClick={() => onImageClick(image.url)}
                src={image.url}
                className="attachments__item"
              />
            ))}
          </div>
          {files.map((file) => (
            <Typography variant="body1" className="attachment">
              Вложение:
              {' '}
              <a target="_blank" href={file.url}>
                скачать
              </a>
            </Typography>
          ))}
        </CardContent>
        <CardActions style={{ justifyContent: 'space-between' }}>
          <TypographySecond variant="body2">
            {new Date(postedAt).toLocaleTimeString('ru-RU')}
          </TypographySecond>
        </CardActions>
      </Card>
    </>
  );
};

export default TicketComment;
