import { slidesTypes } from '../types';

const initialState = {
  items: [],
  isRequesting: false,
  isError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case slidesTypes.GET_PHARMS__REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case slidesTypes.GET_PHARMS__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: action.payload.items,
      };
    case slidesTypes.GET_PHARMS__FAILURE:
      return {
        ...state,
        isRequesting: false,
      };
    default:
      return state;
  }
};
