import React, {Component, Fragment, useEffect} from 'react';
import { conViewModes as modes } from '../constants';
import './Editor.scss';
import { mode2String } from '../utils/translate';
import { Info, Slides } from './editor/';
import { AudienceContainer, CourseLinkAccessContainer } from '../containers';
import {
  Switch, Route, Link, IndexRedirect,
} from 'react-router-dom';
import { withRouter } from 'react-router';
import DeleteButton from '../component/button/DeleteButton';
import DeleteCoursePrompt from '../component/prompt/DeleteCoursePrompt';

@withRouter
class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: modes.INFO,
    };
  }

  handleModeClick = (mode) => this.setState({ mode: modes[mode] });

  handleNextClick = () => this.setState({ mode: modes.EDIT });

  handleOpenModal = () => this.setState({ isOpenModal: true });

  handleCloseModal = () => this.setState({ isOpenModal: false });

  getUrl = (mode) => {
    switch (mode) {
      case modes.EDIT:
        return '/slides';
      case modes.INFO:
        return '/info';
      case modes.TARGET:
        return '/target';
      case modes.AUDIENCE:
        return '/audience';
      default:
        break;
    }
  };

  render() {
    const {
      isNew,
      isOpenDeleteCoursePrompt,
      promptActions,
      match,
      location: { pathname },
    } = this.props;
    const { isOpenModal } = this.state;
    const tabNames = {
      INFO: 'info',
      EDIT: 'slides',
      TARGET: 'target',
      AUDIENCE: 'audience',
    };
    const { courseId } = match.params;

    return (
      <div className="constructor__wrapper">
        <div className="constructor__header">
          <div className="modes">
            {Object.keys(modes).map((mode, key) => (
              <Link
                key={key}
                to={`${match.path.replace(':courseId', courseId)}${this.getUrl(
                  `VIEW_MODE__${mode}`,
                )}`}
              >
                <div
                  className={`mode
                    ${
                      pathname.indexOf(tabNames[mode]) > 0 ? 'current' : '' // TODO: refactor method 4 generate classname
                    } ${isNew && key > 0 ? 'disable' : ''}
                     ${key === 0 ? 'mode_first' : ''}`}
                >
                  {mode2String(mode)}
                </div>
              </Link>
            ))}
          </div>
          {courseId && (
          <div className="constructor__delete">
            <DeleteButton
              text="Удалить курс"
              onClick={promptActions.openDeleteCoursePrompt}
            />
          </div>
          )}
        </div>
        <div className="constructor">
          <Switch>
            <Route
              exact
              path={`${match.path.replace(':courseId', courseId)}/info`}
              component={() => (
                <Info {...this.props} onNextClick={this.handleNextClick} />
              )}
            />
            <Route
              path={`${match.path.replace(
                ':courseId',
                courseId,
              )}/slides/:slideId`}
              component={() => <Slides {...this.props} />}
            />
            <Route
              path={`${match.path.replace(':courseId', courseId)}/slides`}
              component={() => <Slides {...this.props} />}
            />
            <Route
              path={`${match.path.replace(':courseId', courseId)}/audience`}
              component={() => <AudienceContainer {...this.props} />}
            />
            <Route
              path={`${match.path.replace(':courseId', courseId)}/target`}
              component={CourseLinkAccessContainer}
            />
          </Switch>
        </div>
        {isOpenDeleteCoursePrompt && (
        <DeleteCoursePrompt
          closeModal={promptActions.closeDeleteCoursePrompt}
          courseId={courseId}
        />
        )}
      </div>
    );
  }
}

export default Editor;
