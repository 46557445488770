import React, { Component, Fragment } from 'react';
import {
  Typography, Button, Checkbox, FormControl,
  InputLabel, Input, TextField, Switch,
} from '@material-ui/core';
import { Loader } from '../component';
import './CourseUsers.scss';

const getStatusString = (status) => (status
  ? (
    <>
      Пройдено
      <br />
      {' '}
      56%, 150 бонусов
    </>
  )
  : (
    <>
      Непройдено
      <br />
      {' '}
      20%, 0 бонусов
    </>
  ));
const getDateOfCreatedAtString = () => (
  <>
    Назначен 10.10.2018 в 19:30
    <br />
    10.10.2018 19:30 - 15.10.2018 19:30
  </>
);

const testData = [
  {
    isChecked: true,
    title: 'Иванов Иван Иванович',
    status: true,
    createdAt: getDateOfCreatedAtString(),
    city: 'Moscow',
    specialization: 'therapist',
  },
  {
    isChecked: false,
    title: 'Иванов Василий Иванович',
    status: false,
    createdAt: getDateOfCreatedAtString(),
    city: 'Tver',
    specialization: 'surgeon',
  },
  {
    isChecked: false,
    title: 'Васиьев Петр Сергеевич',
    status: false,
    createdAt: getDateOfCreatedAtString(),
    city: 'Pskov',
    specialization: 'ophthalmologist',
  },
];
const User = ({ ...props }) => (
  <div className="audienceListItem">
    <Checkbox
      classes={{ root: 'primaryColor' }}
      onChange={() => props.onSelect(props.id)}
    />
    <div className="userItems userTitle">{props.fullName}</div>
    <div className="userItems userTitle">{props.email}</div>
  </div>
);

class CourseUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      city: '',
      specialization: '',
      switchFilter: false,
    };
  }

  componentDidMount = () => {
    const { slidesActions: { getUsers, getPharms } } = this.props;
    getUsers();
  }

  handleChangeCity = (e) => {
    this.setState({ city: e.target.value.toLowerCase() });
  }

  handleChangeSpecialization = (e) => {
    this.setState({ specialization: e.target.value.toLowerCase() });
  }

  switchHandleFilter = (e) => {
    !!e;
  }

  handleUserSelect = (id) => {
    const { selected } = this.state;
    if (selected.indexOf(id) < 0) {
      this.setState({ selected: [...selected, id] });
    } else {
      this.setState({ selected: selected.filter((_id) => _id !== id) });
    }
  }

  handleAccessCourse = () => {
    const { slidesActions: { createAccess }, editor: { courseId } } = this.props;
    const { selected } = this.state;

    createAccess({ courseId, users: selected });
  }

  render() {
    const { editor: { isRequestingUsers, users } } = this.props;

    return isRequestingUsers ? <Loader /> : (
      <div className="users">
        <div className="container">
          <div className="filterMenu">
            <TextField
              id="standard-select-currency"
              margin="normal"
              variant="outlined"
              select
              label="По региону"
              name="region"
              value="Регион"
              disabled
              InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
              InputProps={{ classes: { input: 'inputSmallPadding' } }}
            />
            <FormControl>
              <InputLabel
                htmlFor="component-simple"
                classes={{ focused: 'focusedTextColor' }}
              >
                Город
              </InputLabel>
              <Input
                name="cityFilter"
                classes={{ focused: 'focusedBottomBorder' }}
                onChange={(e) => this.handleChangeCity(e, true)}
              />
            </FormControl>
            <Button>
              <span className="plusSpan">+</span>
              {' '}
              Критерий
            </Button>
            <TextField
              id="standard-select-currency"
              margin="normal"
              variant="outlined"
              select
              label="По специализации"
              name="specialization"
              value="Специализация"
              disabled
              InputLabelProps={{ classes: { focused: 'labelFocusedColor' } }}
              InputProps={{ classes: { input: 'inputSmallPadding' } }}
            />
            <FormControl>
              <InputLabel
                htmlFor="component-simple"
                classes={{ focused: 'focusedTextColor' }}

              >
                Специальность
              </InputLabel>
              <Input
                name="specializationFilter"
                classes={{ focused: 'focusedBottomBorder' }}
                onChange={(e) => this.handleChangeSpecialization(e, true)}
              />
            </FormControl>
          </div>
          <div className="audienceMenu">
            <Typography className="audience-header">Пользователи</Typography>
            <div className="audienceList">
              {
                users
                // .filter(item =>
                //   item.city.toLowerCase().indexOf(this.state.city) > -1 &&
                //   item.specialization.toLowerCase().indexOf(this.state.specialization) > -1)
                  .map((user, index) => <User key={index} {...user} onSelect={this.handleUserSelect} />)
              }
            </div>
            <div className="actionFooter">
              <div className="switchRow">
                {/* <Switch
                  classes={{switchBase: 'fixHeight', checked: 'switchCheckedColor', bar: 'switchCheckedColor bar'}}
                  onChange={(_, e) => this.switchHandleFilter(e)}
                />
              <p>Только выбранные</p> */}
              </div>
              <Button className="applyCourse" onClick={this.handleAccessCourse}>Назначить курс</Button>
            </div>
          </div>
          {/*

          <DataProvider data={['pharms']}>
          {
            (data) => {
              console.log(data)
              return users.map((user, index) => <User onSelect={this.handleUserSelect} user={user} key={index} />)
            }
            }
            </DataProvider>

        */}
        </div>
      </div>
    );
  }
}

export default CourseUsers;
