import moment from 'moment';
import { courseStatuses } from './index';

export const optionsGraph = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          // max: 0,
          stepSize: 5,
        },
        scaleLabel: {
          display: true,
          labelString: 'Количество прохождений',
          fontColor: 'black',
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          min: 0,
        },
      },
    ],
  },
};

export const options = {
  selectableRows: 'none',
  downloadOptions: { filename: 'statistics.csv', separator: ';' },
  textLabels: {
    body: {
      noMatch: 'Извините, совпадений не найдено',
      toolTip: 'Сортировка',
    },
    pagination: {
      next: 'Следующая страница',
      previous: 'Предыдущая страница',
      rowsPerPage: 'Строк на странице:',
      displayRows: 'из',
    },
    toolbar: {
      search: 'Поиск',
      downloadCsv: 'Скачать CSV',
      print: 'Печать',
      viewColumns: 'Колонки',
      filterTable: 'Фильтры',
    },
    filter: {
      all: 'Все',
      title: 'Фильтры',
      reset: 'Сброс',
    },
    viewColumns: {
      title: 'Показать колонки',
      titleAria: 'Скрыть/показать колонки',
    },
    selectedRows: {
      text: 'Строк быбрано',
      delete: 'Удалить',
      deleteAria: 'Удалить выбранные строки',
    },
  },
};

export const columnsStat = [
  {
    name: 'medicalInstitutionTitle',
    label: 'Клиника/Аптека',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (value !== '---' ? value : ' отсутствует '),
    },
  },
  {
    name: 'medicalInstitutionAddress',
    label: 'Адрес',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (value !== '---' ? value : ' отсутствует '),
    },
  },
  {
    name: 'startedAt',
    label: 'Дата начала',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return '---';
        const localTime = new Date(value);

        return (
          moment(localTime)
          // .add(3, "hours")
            .format('DD-MM-YYYY HH:mm:ss')
        );
      },
    },
  },
  {
    name: 'completedAt',
    label: 'Дата завершения курса',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return '---';
        const formatDate = moment(value, "YYYY-MM-DD'T'HH:mm:ssSSS")
          .add(3, 'hours')
          .format('DD-MMMM-YYYY HH:mm:ss');

        const localTime = new Date(formatDate);

        return moment(localTime).format('DD-MM-YYYY HH:mm:ss');
      },
    },
  },
  {
    name: 'status',
    label: 'Тип результатов курса',
    options: {
      filter: true,
      sort: false,
      customBodyRender: courseStatuses.translateCourseStatisticStatuses,
    },
  },
];
