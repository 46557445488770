import { get } from '../api';
import { profileTypes } from '../types';


export const getProfile = () => (dispatch) => {
  dispatch((() => ({ type: profileTypes.GET_PROFILE__REQUEST }))());
  get(`${process.env.BASE_API_URL}/v1/users/${localStorage.getItem('user_id')}`).then((_user) => {
    get(`${process.env.BASE_API_URL}${_user.managedMedicalInstitutions}`).then((_managedMedicalInstitutions) => {
      get(`${process.env.BASE_API_URL}${_user.employment}`).then((_employments) => {
        dispatch((() => ({
          type: profileTypes.GET_PROFILE__SUCCESS,
          payload: {
            user: {
              ..._user,
              managedMedicalInstitutions: _managedMedicalInstitutions,
              workPlaces: _employments,
            },
          },
        }))());
      });
    });
  });
};
