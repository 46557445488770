import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { slidesActions, getCurrentUser } from '../actions';

class AllCoursesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { slidesActions: { getCourses } } = this.props;
    getCourses();
  }

  render() {
    return this.props.children({ courses: this.props.courses });
  }
}

const mapDispatchToProps = (dispatch) => ({
  slidesActions: bindActionCreators(slidesActions, dispatch),
});
const mapStateToProps = (state) => ({
  courses: state.courses,
});

export default connect(mapStateToProps, mapDispatchToProps)(AllCoursesContainer);
