import { coursesTypes, editorTypes, constructorTypes } from '../types';

const initialState = {
  isRequesting: false,
  isRequestingUsers: false,
  isRequestingSave: false,
  isRequestingDelete: false,
  course: {
    theme: null,
    availability: { from: new Date(), till: new Date() },
    difficulty: null,
    rewardPoints: 0,
    recommendedNumberOfAttempts: 0,
    certificate: null,
    cover: null,
    title: '',
    description: '',
    accesses: { items: [] },
    invites: { items: [] },
    statistics: { courseAccessStatistics: [] },
  },
  users: [], // TODO: replace in another reducer
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case editorTypes.CHANGE_FIELD: return {
      ...state,
      course: {
        ...state.course,
        [payload.target]: payload.value,
      },
    };
    case editorTypes.UPDATE_COURSE__REQUEST:
    case editorTypes.CREATE_COURSE__REQUEST: return {
      ...state,
      isRequestingSave: true,
    };
    case editorTypes.GET_COURSE__REQUEST:
    case editorTypes.CREATE_ACCESS__REQUEST:
    case coursesTypes.CREATE_COURSE__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case editorTypes.GET_COURSE__SUCCESS: return {
      ...state,
      isRequesting: false,
      course: {
        ...payload.course,
      },
    };
    case coursesTypes.CREATE_COURSE__META: return {
      ...state,
      isNew: true,
      isAudience: false,
      course: { ...initialState.course },
    };
    case editorTypes.UPDATE_COURSE__SUCCESS:
      return {
        ...state,
        isRequestingSave: false,
        course: { ...payload.data },
      };
    case editorTypes.CREATE_COURSE__SUCCESS: return {
      ...state,
      isRequestingSave: false,
    };
    case editorTypes.DELETE_COURSE__REQUEST:
      return {
        ...state,
        isRequestingDelete: true,
      };
    case editorTypes.DELETE_COURSE__FAILURE:
    case editorTypes.DELETE_COURSE__SUCCESS:
      return {
        ...state,
        isRequestingDelete: false,
      };
    case coursesTypes.CREATE_COURSE__SUCCESS: return {
      ...state,
      isRequesting: false,
      isNew: false,
    };
    case editorTypes.CREATE_ACCESS__SUCCESS: return {
      ...state,
      isRequesting: false,
    };
    case constructorTypes.POST_NEW_COURSE_SUCCESS: return {
      ...state,
      courseId: action.payload.courseId,
      isAudience: true,
    };
    default: return state;
  }
};
