export const correctAnswerAmountValues = {
  ONE: 'one',
  ZERO: 'zero',
  TWO_OR_MORE: 'two_or_more',
};

export const questionType = {
  MATCHING: 'matching',
  SELECT: 'select',
};
