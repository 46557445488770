import React, { Fragment, Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import * as authSteps from '../constants/authSteps';
import { MaskedInput } from '../component';
import { validateEmail, validatePhone } from '../utils';

import './Auth.scss';
import '../styles/typography.scss';

const modes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
};

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedA: false,
      checkedB: false,
      tel: '',
      code: '',
      email: '',
      name: '',
      mode: modes.SIGN_IN,
      isPhoneValid: false,
      isSuccessfullySignup: false,
      isSuccessfullyCheckNumberOnSignup: false,
      isSignupFormValid: false,
      agreement: false,
    };
  }

  componentDidMount() {
    if (localStorage.is_signup_success) {
      this.setState({
        mode: modes.SIGN_IN,
        isSuccessfullySignup: true,
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target });
  };

  handleCheckboxChange = () => {
    this.setState({ agreement: !this.state.agreement });
  };

  handleChangeField = ({ target: { name, value } }) => this.setState({
    [name]: value,
  });

  handleSendPhone = () => {
    const {
      authActions: { sendPhone },
    } = this.props;
    const { tel } = this.state;
    sendPhone({ tel: `+${tel}` });
  };

  handleSendCode = () => {
    const {
      authActions: { sendCode },
    } = this.props;
    const { tel, code } = this.state;
    sendCode({ tel: `+${tel}`, code });
  };

  handleRegClick = () => {
    const { authActions: { signUp } } = this.props;
    signUp({
      email: this.state.email !== '' ? this.state.email : null,
      fullName: this.state.name,
      gender: this.state.gender,
      phone: `+${this.state.tel}`,
    });
  }

  handleModeChange = ({ target: { value } }) => this.setState({ mode: value })

  handleSendPhoneInSignUp = () => {
    this.props.authActions.sendPhoneInSignup(this.state.tel);
  }

  handleSendCodeInSignUp = () => {
    this.props.authActions.sendCodeInSignup({
      email: this.state.email,
      fullName: this.state.name,
      phone: `+${this.state.tel}`,
      smsConfirmationCode: this.state.code,
    });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.auth.isSuccessfullySignup) {
      this.setState({ mode: modes.SIGN_IN });
    }
  }

  validateSignupForm4phone = () => (
      this.state.name.replace(' ', '').length > 0
      && validatePhone(this.state.tel)
      && this.state.agreement
      && (this.state.email !== '' ? validateEmail(this.state.email) : true)
  )

  validateSignupForm4code = () => (this.state.name.replace(' ', '').length > 0
    && (this.state.email !== '' ? validateEmail(this.state.email) : true)
    && validatePhone(this.state.tel)
    && this.state.agreement
    && this.state.code.length === 4)

  render() {
    const {
      auth: {
        step, step_signup, isError, isRequesting,
      },
    } = this.props;
    const { tel, code, isSuccessfullySignup } = this.state;

    return (
      <div
        className="auth"
        onKeyDown={(event) => {
          event.key === 'Enter'
            ? step === authSteps.SEND_PHONE
              ? tel
                ? this.handleSendPhone()
                : null
              : tel && code.length > 0
                ? this.handleSendCode()
                : null
            : null;
        }}
      >
        <Card className={`card ${this.state.mode === modes.SIGN_IN ? 'sign_in' : 'sign_up'} ${step === authSteps.SEND_CODE ? 'with_code' : ''}`}>
          <RadioGroup
            aria-label="Gender"
            name="gender1"
            row
            style={{ justifyContent: 'space-around' }}
            value={this.state.mode}
            onChange={this.handleModeChange}
            className="mode_select"
          >
            <FormControlLabel style={{ marginRight: '7px' }} value={modes.SIGN_IN} control={<Radio />} label="Вход" />
            <FormControlLabel style={{ marginRight: '7px' }} value={modes.SIGN_UP} control={<Radio />} label="Регистрация" />
          </RadioGroup>
          {
              this.state.mode === modes.SIGN_IN
                ? (
                  <>
                    {
                      isError
                      && (
                        <div className="info">
                          <Typography>
                            {this.props.auth.errorMessage}
                          </Typography>
                        </div>
                      )
                    }
                    <CardContent className="cardContent">
                      <Typography gutterBottom>Вход</Typography>
                      <MaskedInput
                        autoComplete="tel"
                        onChange={this.handleChangeField}
                        id="phone-number"
                        label="Номер телефона"
                        className={`textField ${!tel ? 'not_valid' : null}`}
                        name="tel"
                        value={this.state.tel}
                        validate
                        inputProps={{
                          inputmode: 'tel',
                          type: 'tel',
                        }}
                      />
                      {step === authSteps.SEND_CODE && (
                      <TextField
                        id="password"
                        label="Введите код из смс"
                        className="textField"
                        value={code}
                        name="code"
                        onChange={this.handleChangeField}
                        margin="normal"
                        variant="outlined"
                        autoComplete="one-time-code"
                        inputProps={{
                          inputmode: 'numeric',
                        }}
                      />
                      )}
                    </CardContent>
                    <CardActions className="cardAction">
                      <Button
                        size="small"
                        onClick={
                      step === authSteps.SEND_PHONE
                        ? tel
                          ? this.handleSendPhone
                          : null
                        : tel && code.length > 0
                          ? this.handleSendCode
                          : null
                    }
                        className={`authButton ${isRequesting ? 'requesting' : null} ${
                          isError ? 'error' : null
                        } ${
                          step === authSteps.SEND_PHONE
                            ? !tel
                              ? 'not_valid'
                              : null
                            : !(tel && code.length > 0)
                              ? 'not_valid'
                              : null
                        }`}
                      >
                        Войти в систему
                      </Button>
                    </CardActions>
                  </>
                )
                : (
                  <>
                    {
                      isError
                      && (
                      <div className="info singUp">
                        <Typography>
                          {this.props.auth.errorMessage}
                        </Typography>
                      </div>
                      )
                    }
                    <CardContent className="cardContent">
                      <TextField
                        id="fullName"
                        label="ФИО"
                        className="textField"
                        value={this.state.name}
                        name="name"
                        onChange={this.handleChangeField}
                        margin="normal"
                        variant="outlined"
                        disabled={step_signup === authSteps.SIGN_UP_SEND_CODE}
                        autoComplete="name"
                      />
                      <Typography className="input-info">Поле email не является обязательным</Typography>
                      <TextField
                        onChange={this.handleChangeField}
                        value={this.state.email}
                        id="email"
                        label="Email"
                        className={`textField ${!tel ? 'not_valid' : null}`}
                        name="email"
                        margin="normal"
                        validate
                        disabled={step_signup === authSteps.SIGN_UP_SEND_CODE}
                        variant="outlined"
                        autoComplete="email"
                        type="email"
                        inputProps={{
                          inputmode: 'email',
                        }}
                      />
                      <MaskedInput
                        onChange={this.handleChangeField}
                        id="phone-number"
                        label="Номер телефона"
                        value={this.state.tel}
                        className={`textField ${!tel ? 'not_valid' : null}`}
                        name="tel"
                        validate
                        disabled={step_signup === authSteps.SIGN_UP_SEND_CODE}
                        inputProps={{
                          inputmode: 'tel',
                          type: 'tel',
                        }}
                        autoComplete="tel"
                      />
                      {
                  step_signup === authSteps.SIGN_UP_SEND_CODE
                  && (
                    <TextField
                      onChange={this.handleChangeField}
                      value={this.state.sms}
                      id="code"
                      label="Введите код из смс"
                      className={`textField ${!tel ? 'not_valid' : null}`}
                      name="code"
                      margin="normal"
                      validate
                      variant="outlined"
                      autoComplete="one-time-code"
                      inputProps={{
                        inputmode: 'numeric',
                      }}
                    />
                  )
                }
                      <div className="agreement">
                        <Checkbox value={this.state.agreement} onChange={this.handleCheckboxChange} />
                        <Typography variant="body2">
                          Я соглашаюсь на обработку персональных данных в соответствии с
                          <a target="_blank" href="https://storage.yandexcloud.net/dpg-documents/terms-of-use.html">Условиями использования сайта</a>
                          ,
                          <a target="_blank" href="https://storage.yandexcloud.net/dpg-documents/privacy-policy.html">Политикой обработки персональных данных</a>
                          {' '}
                          и на получение сообщений в процессе работы с сайтом.
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions className="cardAction">
                      <Button
                        size="small"
                        onClick={
                    step_signup === authSteps.SIGN_UP_SEND_PHONE
                      ? this.validateSignupForm4phone() ? this.handleSendPhoneInSignUp : () => null
                      : this.validateSignupForm4code() ? this.handleSendCodeInSignUp : () => null
                  }
                        className={`authButton ${isRequesting ? 'requesting' : null} ${
                          isError ? 'error' : ''
                        } ${
                          step_signup === authSteps.SIGN_UP_SEND_PHONE
                            ? this.validateSignupForm4phone() ? '' : 'not_valid'
                            : this.validateSignupForm4code() ? '' : 'not_valid'
                        }`}
                      >
                        {
                    step_signup === authSteps.SIGN_UP_SEND_PHONE
                      ? 'Зарегистрироваться'
                      : 'Подтвердить телефон'
                  }
                      </Button>
                    </CardActions>
                  </>
                )
            }
        </Card>
        { isSuccessfullySignup
          && (
          <div className="authInfo">
            <Typography>Вы успешно зарегистировались!</Typography>
            <Typography>Для прохождения курса войдите под своим номером телефона</Typography>
          </div>
          )}
      </div>
    );
  }
}

export default Auth;
