import { editorTypes, linksTypes } from '../types';
import {
  post, get, getWithoutToken, deleteAPI,
} from '../api';
import { history } from '..';
import * as promptTypes from '../component/prompt/actionTypes';
import * as alertTypes from '../component/alert/actionTypes';
import {getInvitations} from './editor';

export const createAnonInvite = (inviteId) => (dispatch) => {
  dispatch((() => ({ type: linksTypes.GET_INVITE__REQUEST }))());
  post(
    `${process.env.BASE_API_URL}/v1/anonymous-course-invite-claim-requests`,
    {
      courseInvite: `/v1/course-invites/${inviteId}`,
    },
    true,
  )
    .then((data) => getWithoutToken(`${process.env.BASE_API_URL}${data.courseAccess}`).then(
      (courseData) => {
        getWithoutToken(
          `${process.env.BASE_API_URL}${courseData.accessToken}`,
        ).then((accessData) => {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.setItem('access', data.courseAccess);
          sessionStorage.setItem('bearer_token', accessData.token);
          sessionStorage.setItem('is_anon', true);
          sessionStorage.setItem(
            'course_id',
            courseData.course.split('/').pop(),
          );
          history.push('/courses');
          window.location.reload();
        });
      },
    ))
    .catch(() => dispatch(
      (() => ({
        type: linksTypes.GET_INVITE__FAILURE,
      }))(),
    ));
};

export const createRegInvite = (inviteId) => (dispatch) => {
  dispatch(
    (() => ({
      type: linksTypes.SET_INVITE_ID,
      payload: { inviteId },
    }))(),
  );
  localStorage.setItem('invite_id', inviteId);
  window.location.replace('/');
};

export const deleteLink = (inviteId, current) => (dispatch) => {
  deleteAPI(
    `${process.env.BASE_API_URL}/v1/course-invites/${inviteId}`,
  )
    .then(() => {
      dispatch({ type: promptTypes.DELETE_LINK_CLOSE });
      dispatch(getInvitations({ link: current.courseInvites }));
    })
    .catch((res) => {
      dispatch({ type: promptTypes.DELETE_LINK_CLOSE });
      if (res.status === 403) {
        dispatch({
          type: alertTypes.ALERT_OPEN,
          payload: {
            text: 'Не удалось удалить ссылку. Данная ссылка уже используется.',
            title: 'Ошибка!',
          },
        });
      }
    });
};
