import React, { Component, Fragment } from 'react';

class SelectPhoto extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="selectingPhoto">
        <h6>4. Отметьте все фотографии, где изображены настоящие лекарства</h6>
        <div className="photosGallery">
          <img src="https://koloro.ua/media/upload/images/blog/Dizajn%20upakovki%20lekarstvennyh%20sredstv.-vidy-lekarstveennyh-upakovok/13.jpg" />
          <img src="https://www.designland.ru/upload/information_system_11/6/2/2/item_622/information_items_622.jpg" />
          <img src="http://viko-pro.ru/images/ekonom/farm/pharm_6.jpg" />
          <img src="http://viko-pro.ru/images/ekonom/farm/pharm_5.jpg" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK2XfcyICd_FtNkRDjp6u42YqFfjFErGnSMva9H3penTyVZuZ0" />
          <img src="http://www.первостольник.рф/wp-content/uploads/2011/04/lidevin-pachka_blister.jpg" />
          <img src="https://static.medportal.ru/pic/news/2012/07/10/drugbuy/pic001.jpg" />
          <img src="http://aedus-design.ru/webimages/ruimage3620.png" />
        </div>
      </div>
    );
  }
}

export default SelectPhoto;
