import { statsTypes } from '../types';

const initialState = {
  data: {
    stats: [],
  },
  isRequesting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case statsTypes.GET_STATS__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case statsTypes.GET_STATS__SUCCESS: return {
      ...state,
      isRequesting: false,
      data: { ...action.payload },
    };
    default:
      return state;
  }
};
