import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authActions } from '../actions';
import { Auth, Registration } from '../pages';

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Auth {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  logs: state.logs,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthContainer);
