import React, { Component, Fragment } from 'react';
import { Slider } from '@material-ui/lab';
import {
  Checkbox, FormControlLabel, Typography, TextField, Button,
} from '@material-ui/core';
import './Tuning.scss';
import { beautifySec } from '../../../utils';

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      timeToComplete: 180,
      timePerOne: 2,
    };
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.slide.item,
    });
  }

  handleSliderChange = (name, value) => {
    this.setState(
      { [name]: value },
      () => {
        this.props.onEdit({...this.state, type: 'poll'}, this.props.index),
        this.validate();
      },
    );
  }

  handleChangeQuestion = ({ target: { value } }, index) => {
    this.setState(
      ({ questions }) => ({
        questions: questions.map((_q, _index) => (_index === index ? value : _q)),
      }), () => {
        this.props.onEdit({ ...this.state, type: 'poll' }, this.props.index),
        this.validate();
      },
    );
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value,
    }, () => {
      this.props.onEdit({ ...this.state, type: 'poll' }, this.props.index),
      this.validate();
    });
  }

  handleAddQuestion = () => this.setState(
    ({ questions }) => ({
      questions: [
        ...questions, '',
      ],
    }),
    () => {
      this.props.onEdit({...this.state, type: 'poll'}, this.props.index),
      this.validate();
    }
  )

  validate = () => {
    const { title, questions } = this.state;

    const valid = (
      title !== ''
      && questions.length !== 0
      && questions.every(question => question !== '')
    );

    this.props.setValidSlide(valid);
  }

  render() {
    const { title, questions } = this.state;
    const { isInvalidInput } = this.props;

    return (
      <div className="slideContent">
        <div className="slidesSidebar">
          <Typography id="slider-image">
            {`Времени на вопрос: ${Math.floor(
              this.state.timeToComplete / 60,
            )} мин ${beautifySec(
              this.state.timeToComplete / 60
                - Math.floor(this.state.timeToComplete / 60),
            )} сек`}
          </Typography>
          <Slider
            classes={{ container: 'slider' }}
            name="timeToComplete"
            onChange={(_, value) => this.handleSliderChange('timeToComplete', value)}
            value={this.state.timeToComplete}
            step={20}
            min={20}
            max={1000}
          />
        </div>
        <div className="mainField">
          <div className="contentWrapper">
            <Typography id="slider-image" variant="h6">Название блока</Typography>
            <TextField
              value={title}
              error={!title ? isInvalidInput : null}
              name="title"
              onChange={this.handleChange}
              fullWidth
            />
            {
                  questions.map((question, indexQuestions) => (
                    <div className="dialog__question">
                      {/* <div onClick={() => this.handleDeleteQuestion(indexQuestions)} className='deleteQuestion'> */}
                      {/* </div> */}
                      <Typography style={{ marginBottom: '10px' }} id="slider-image" variant="h6">Текст</Typography>
                      <TextField
                        multiline
                        fullWidth
                        value={question}
                        error={!question ? isInvalidInput : null}
                        name="description"
                        onChange={(e) => this.handleChangeQuestion(e, indexQuestions)}
                      />

                    </div>
                  ))
}
            <Button
              className={!questions.length ? isInvalidInput ? 'button_error' : '' : null}
              onClick={this.handleAddQuestion}
            >
              + Добавить вопрос
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
