import React, { Component, Fragment } from 'react';
import { Button } from '@material-ui/core';
import {
  Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, Font,
} from '@react-pdf/renderer';
import Logo from '../../static/imgs/logo.png';
import Roboto from '../../static/fonts/Roboto-Regular.ttf';

Font.register({ family: 'Roboto', src: Roboto });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  image: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  logo: {
    zIndex: 1,
    position: 'absolute',
    fontFamily: 'Roboto',
    top: 20,
    left: 20,
    width: '150px',
  },
  section: {
    fontFamily: 'Roboto',
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
  header: {
    fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 500,
    color: '#00796B',
    fontSize: 50,
    textAlign: 'center',
    marginTop: '40%',
    marginBottom: 30,
  },
  regular: {
    fontFamily: 'Roboto',
    fontSize: 16,
    textAlign: 'center',
  },
  fullName: {
    fontSize: 18,
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 15,
  },
  signatureRegular: {
    fontFamily: 'Roboto',
    fontSize: 16,
    marginLeft: 400,
  },
  signatureBold: {
    fontFamily: 'Roboto',
    marginTop: 5,
    marginLeft: 400,
    fontWeight: 'bold',
  },
  decorLine: {
    width: 50,
    height: 5,
    marginLeft: 400,
    fontFamily: 'Roboto',
    marginTop: 80,
    marginBottom: 7,
    backgroundColor: '#D62538',
  },
  verticalGap: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Roboto',
    marginTop: 15,
  },
});

class CreateCertificate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { course, user } = this.props;

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image
              style={styles.image}
              src={course.certificateBackground ? course.certificateBackground.url : null}
            />
            <Image
              style={styles.logo}
              src={Logo}
            />
            <Text style={styles.header}>Сертификат</Text>
            <Text style={styles.regular}>Данный сертификат удостоверяет, что специалист</Text>
            {
            sessionStorage.is_anon === 'true'
              ? null
              : <Text style={styles.fullName}>{user.fullName}</Text>
          }

            <Text style={styles.regular}>{false ? 'сотрудник компании' : ''}</Text>
            <Text style={styles.regular}>{false ? 'address' : ''}</Text>
            <Text style={styles.verticalGap}>успешно прослушал(ла) курс:</Text>
            <Text style={styles.regular}>{course.title}</Text>
            <Text style={styles.regular}>
              c {new Date(course.availability.from).toLocaleDateString('ru-RU')}
              {' '}
              по {new Date(course.availability.till).toLocaleDateString('ru-RU')}
            </Text>

            <Text style={styles.decorLine}>date of issue</Text>
            <Text style={styles.signatureRegular}>
              Дата выдачи:
              {new Date(course.access.startedAt).toLocaleDateString('ru-RU')}
            </Text>
          </View>
        </Page>
      </Document>
    );
  }
}

class Pdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <PDFDownloadLink document={<CreateCertificate user={this.props.user} course={this.props.course} />} fileName="Сертификат">
        <Button onClick={() => null} className={this.props.className}>{this.props.title}</Button>
      </PDFDownloadLink>
    );
  }
}

export default Pdf;
